import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { statsFilterSideState } from '#state/gui'

const factors = ['all', 'long', 'short' ];

export function StatsFilterSide() {
  const [statsFilter, setStatsFilter] = useRecoilState(statsFilterSideState);

  useEffect(() => {
    localforage.setItem('statsFilterSideState', statsFilter);
  }, [statsFilter]);

  const handleFactorChange = (event) => {
    setStatsFilter(event.target.value);
  };

  return (
    <Select
      size='small'
      value={statsFilter}
      onChange={handleFactorChange}
    >
      {factors.map((symbol) => (
        <MenuItem value={symbol} key={symbol}>
          {symbol}
        </MenuItem>
      ))}
    </Select>
  );
}