
import React, { useEffect, useRef, useState } from 'react';
import { LineStyle } from 'lightweight-charts';
import { EMA, Extremums, Pivot, ATR } from '@debut/indicators';
import { convertCandles, intervalToMinutes } from '#src/utils'
import { TrendLine } from './plugins/TrendLine';
import { VertLine } from './plugins/VertLine';
import { kmeans } from 'ml-kmeans';
import dbscan from '@cdxoo/dbscan'
// ***********************************************
// view config
// ***********************************************
// filter 2
const similarLevelPercent = 0.002
// 
const simpleStatsEmaLength = 10
const waveDurationMult = 7 * 24 * 60 * 60 // 1 week in seconds
// 
const fiboHL = [0, 1]
const fiboCorrectionLevels = [0.236, 0.382, 0.5, 0.618, 0.786] // 0.236, 0.786, 1.618, 2, 2.618, 4.236]
const fiboUpExtension = [1.618, -0.236, -0.382, -0.5, -0.618, -0.786, -1, -1.236, -1.618]
const fiboDownExtension = [-0.618, 1.236, 1.382, 1.5, 1.618, 1.786, 2, 2.618]

// ***********************************************
// Calculate Waves
// ***********************************************

export function getWaves(lows, highs) {
  const levels = []
  const waves = []

  if (!lows.length || !highs.length) {
    return { levels, waves }
  }

  lows.sort((a, b) => a.startTime - b.startTime)
  highs.sort((a, b) => a.startTime - b.startTime)


  let lowIdx = 0
  let highIdx = 0
  let hhIndex = highIdx
  let llIndex = lowIdx

  let side = null
  if (lows[lowIdx].startTime <= highs[highIdx].startTime) {
    side = "low"
  } else {
    side = "high"
  }

  // console.log('getWaves initial side', side)

  while (lowIdx < lows.length && highIdx < highs.length) {
    if (side === "low") {
      if (lowIdx + 1 < lows.length) {
        let nextLowTime = lows[lowIdx + 1].startTime
        // find lowest low between current high (include it) and next low
        // update llIndex & nextLowTime if we found lower low
        const nextHighTime = highs[highIdx].startTime
        let llPrice = lows[lowIdx].level
        // console.log('check', side, lowIdx, llIndex, lows[lowIdx])
        for (let i = lowIdx + 1; i < lows.length - 1; i++) {
          if (lows[i].startTime > nextHighTime) {
            // console.log('next low in future', side, i, lows[i])
            lowIdx = i - 1
            break
          }
          // console.log('have another', side, i, lowIdx, llIndex, lows[i])
          if (lows[i].level < llPrice) {
            // lowIdx = i
            llIndex = i
            llPrice = lows[i].level
            nextLowTime = lows[i + 1].startTime
            // console.log('selected better', side, lowIdx, llIndex, lows[lowIdx])
          }
        }
        // console.log('selected', side, lowIdx, llIndex, lows[llIndex])
        // console.log('next', side, lowIdx + 1, lows[lowIdx + 1])
        nextLowTime = lows[lowIdx + 1].startTime
        // find highest high between current high (include it) and next low
        hhIndex = highIdx
        let hhPrice = highs[highIdx].level
        while (highIdx + 1 < highs.length && highs[highIdx + 1].startTime < nextLowTime) {
          highIdx++
          if (highs[highIdx].level > hhPrice) {
            hhIndex = highIdx
            hhPrice = highs[highIdx].level
          }
          // console.log('while high', highIdx, hhIndex, highs[highIdx])
        }
        highs[hhIndex]._type = "high"
        levels.push(highs[hhIndex])
        // console.log('add high', hhIndex, highs[hhIndex])
        const delta = highs[hhIndex].level - lows[llIndex].level
        const deltaPercent = delta / lows[llIndex].level
        const wave = {
          direction: "up",
          low: lows[llIndex].level,
          high: highs[hhIndex].level,
          delta: delta,
          deltaPercent,
          start: lows[llIndex].startTime,
          end: highs[hhIndex].startTime,
          lowLevelIdx: llIndex,
          highLevelIdx: hhIndex,
          // lowLevel: lows[llIndex],
          // highLevel: highs[hhIndex],
        }
        waves.push(wave)
      }
      side = "high"
      lowIdx++
    } else if (side === "high") {
      if (highIdx + 1 < highs.length) {
        let nextHighTime = highs[highIdx + 1].startTime
        // find higest high between current high (include it) and next low
        // update hhIndex & nextHighTime if we found higher high
        const nextLowTime = lows[lowIdx].startTime
        let hhPrice = highs[highIdx].level
        // console.log('check', side, highIdx, hhIndex, highs[highIdx])

        // use >= && <= to include current high and low (hotfix
        for (let i = highIdx + 1; i < highs.length - 1; i++) {
          if (highs[i].startTime >= nextLowTime) {
            // console.log('next high in future', side, i, highs[i])
            highIdx = i - 1
            break
          }
          // console.log('have another', side, i, highIdx, hhIndex, highs[i])
          if (highs[i].level > hhPrice) {
            // highIdx = i
            hhIndex = i
            hhPrice = highs[i].level
            nextHighTime = highs[i + 1].startTime
            // console.log('selected better', side, highIdx, hhIndex, highs[highIdx])
          }
        }
        // console.log('selected', side, highIdx, hhIndex, highs[hhIndex])
        // console.log('next', side, highIdx + 1, highs[highIdx + 1])
        nextHighTime = highs[highIdx + 1].startTime

        llIndex = lowIdx
        let llPrice = lows[lowIdx].level
        while (lowIdx + 1 < lows.length && lows[lowIdx + 1].startTime <= nextHighTime) {
          lowIdx++
          if (lows[lowIdx].level < llPrice) {
            llIndex = lowIdx
            llPrice = lows[lowIdx].level
          }
          // console.log('while low', lowIdx, llIndex, lows[lowIdx])
        }
        lows[llIndex]._type = "low"
        levels.push(lows[llIndex])
        // console.log('add low', llIndex, lows[llIndex])
        const delta = highs[hhIndex].level - lows[llIndex].level
        const deltaPercent = delta / highs[hhIndex].level
        const wave = {
          direction: "down",
          low: lows[llIndex].level,
          high: highs[hhIndex].level,
          delta,
          deltaPercent,
          start: highs[hhIndex].startTime,
          end: lows[llIndex].startTime,
          lowLevelIdx: llIndex,
          highLevelIdx: hhIndex,
          // lowLevel: lows[llIndex],
          // highLevel: highs[hhIndex],
        }
        waves.push(wave)
      }
      side = "low"
      highIdx++
    }
  }

  // console.log('getWaves', side, 'low', llIndex, lowIdx, lows.length, 'high', hhIndex, highIdx, highs.length)

  // Проверка на оставшиеся элементы после основного цикла
  if (lowIdx < lows.length || highIdx < highs.length) {
    if (side === "low" && lowIdx < lows.length) {
      // Выбираем самый низкий low из оставшихся
      const remainingLows = lows.slice(lowIdx);
      const lowestLow = remainingLows.reduce((min, low, idx) => {
        return low.level < min.level ? { level: low.level, idx: lowIdx + idx } : min;
      }, { level: remainingLows[0].level, idx: lowIdx });

      if (highIdx > 0) {
        const lastHighIdx = hhIndex;
        const delta = highs[lastHighIdx].level - lowestLow.level;
        const deltaPercent = delta / lowestLow.level;
        waves.push({
          direction: "down",
          low: lowestLow.level,
          high: highs[lastHighIdx].level,
          delta,
          deltaPercent,
          start: highs[lastHighIdx].startTime,
          end: lows[lowestLow.idx].startTime,
          lowLevelIdx: lowestLow.idx,
          highLevelIdx: lastHighIdx
        });
        lows[lowestLow.idx]._type = "low";
        levels.push(lows[lowestLow.idx]);
        // console.log('add low', lowestLow.idx, lows[lowestLow.idx])
      }
    } else if (side === "high" && highIdx < highs.length) {
      // Выбираем самый высокий high из оставшихся
      const remainingHighs = highs.slice(highIdx);
      const highestHigh = remainingHighs.reduce((max, high, idx) => {
        return high.level > max.level ? { level: high.level, idx: highIdx + idx } : max;
      }, { level: remainingHighs[0].level, idx: highIdx });

      if (lowIdx > 0) {
        const lastLowIdx = llIndex;
        const delta = highestHigh.level - lows[lastLowIdx].level;
        const deltaPercent = delta / highestHigh.level;
        waves.push({
          direction: "up",
          low: lows[lastLowIdx].level,
          high: highestHigh.level,
          delta,
          deltaPercent,
          start: lows[lastLowIdx].startTime,
          end: highs[highestHigh.idx].startTime,
          lowLevelIdx: lastLowIdx,
          highLevelIdx: highestHigh.idx
        });
        highs[highestHigh.idx]._type = "high";
        levels.push(highs[highestHigh.idx]);
        // console.log('add high', highestHigh.idx, highs[highestHigh.idx])
      }
    }
  }

  markWaves(waves)
  return { levels, waves }
}

function markWaves(waves) {
  for (let i = 1; i < waves.length; i++) {
    const prevWave = waves[i - 1]
    const wave = waves[i]

    if (prevWave.direction === 'up' && wave.direction === 'down') {
      if (wave.low > prevWave.low) {
        wave.type = 'pullback'
      } else {
        wave.type = 'move'
      }
    } else if (prevWave.direction === 'down' && wave.direction === 'up') {
      if (wave.high < prevWave.high) {
        wave.type = 'pullback'
      } else {
        wave.type = 'move'
      }
    }
  }
}

function isSubWaveOfAny(masterId, waves) {
  if (!waves) return false;
  return waves.some(wave => {
    return wave.idx === masterId || (wave.corrections && isSubWaveOfAny(masterId, wave.corrections)) || (wave.waves && isSubWaveOfAny(masterId, wave.waves));
  });
}

function findSubWaves(masterIdx, masterId, waves, prefix = '') {
  let subWaves = [];
  const masterWave = waves[masterIdx];
  for (let i = masterIdx + 1; i < waves.length; i++) {
    const wave = waves[i];
    if (wave.low < masterWave.low || wave.high > masterWave.high) {
      break;
    }
    if (wave.low >= masterWave.low && wave.high <= masterWave.high) {
      const idx = prefix ? `${prefix}:${i}` : `${i}`;
      const isSubwave = isSubWaveOfAny(idx, subWaves);
      if (!isSubwave) {
        const subSubWaves = findSubWaves(i, idx, waves, prefix);
        const correctionWave = {
          ...wave,
          idx,
          masterId,
        };
        if (subSubWaves && subSubWaves.length) {
          if (correctionWave.corrections) {
            // console.log('createWaveHierarchy already has corrections', correctionWave.idx, correctionWave.corrections, subSubWaves)
            correctionWave.corrections.push(...subSubWaves)
          } else {
            correctionWave.corrections = subSubWaves
          }

          const correctionDetails = analyzeCorrections(correctionWave.corrections);
          const low = correctionWave.direction === 'up' ? correctionDetails.low : correctionWave.low
          const high = correctionWave.direction === 'up' ? correctionWave.high : correctionDetails.high
          const delta = high - low
          const deltaPercent = correctionWave.direction === 'up' ? delta / low : delta / high
          correctionWave.correction = {
            start: correctionWave.corrections[0].start,
            end: correctionDetails.end,
            low,
            high,
            delta,
            deltaPercent,
            ratioParent: delta / correctionWave.delta,
          };
        }
        subWaves.push(correctionWave);
      }
    }
  }
  return subWaves;
}

export function createWaveHierarchy(waves, prefix = '') {
  let hierarchy = [];
  for (let i = 0; i < waves.length; i++) {
    const idx = prefix ? `${prefix}:${i}` : `${i}`;
    const isSubwave = isSubWaveOfAny(idx, hierarchy);
    if (!isSubwave) {
      const wave = waves[i];
      const subWaves = findSubWaves(i, idx, waves, prefix);
      const sub = subWaves.length ? subWaves : wave.corrections || wave.waves;
      const w = {
        ...wave,
        idx,
      };
      if (sub && sub.length) {
        if (w.corrections) {
          // find new unique corrections & join if already have
          const wstarts = w.corrections.map(w => w.start)
          const newSubs = sub.filter(s => !wstarts.includes(s.start))
          if (newSubs.length) {
            w.corrections.push(...newSubs)
          }
        }
        else {
          w.corrections = sub
        }
      }
      hierarchy.push(w);
    }
  }

  for (let i = 0; i < hierarchy.length; i++) {
    const wave = hierarchy[i];
    if (wave.corrections) {
      const compresedCorrections = compressWaveCascades(wave.corrections, 10)
      if (compresedCorrections.length) {
        wave.corrections = compresedCorrections[compresedCorrections.length - 1].hierarchy

        const correctionDetails = analyzeCorrections(wave.corrections);
        const low = wave.direction === 'up' ? correctionDetails.low : wave.low
        const high = wave.direction === 'up' ? wave.high : correctionDetails.high
        const delta = high - low
        const deltaPercent = wave.direction === 'up' ? delta / low : delta / high
        wave.correction = {
          start: wave.corrections[0].start,
          end: correctionDetails.end,
          low,
          high,
          delta,
          deltaPercent,
          ratioParent: delta / wave.delta,
        };
      }
    }
  }

  return hierarchy;
}

export function analyzeCorrections(corrections) {
  let high = -Infinity;
  let low = Infinity;
  let start = corrections[0].start;
  let end = corrections[0].end;

  corrections.forEach(correction => {
    if (correction.high > high) high = correction.high;
    if (correction.low < low) low = correction.low;
    if (correction.end > end) end = correction.end;
    // Рекурсивно обновляем `end`, если есть вложенные коррекции
    if (correction.corrections && correction.corrections.length) {
      const nestedCorrection = analyzeCorrections(correction.corrections);
      if (nestedCorrection.end > end) end = nestedCorrection.end;
      if (nestedCorrection.high > high) high = nestedCorrection.high;
      if (nestedCorrection.low < low) low = nestedCorrection.low;
    }
  });

  return { start, end, high, low };
}

function getWaveCascades(wavesHierarchy, prefix = '') {
  const waveCascades = [];
  let currentCascade = [];
  let currentDirection = null;

  for (let i = 0; i < wavesHierarchy.length; i++) {
    if (!currentCascade.length) {
      currentCascade.push(wavesHierarchy[i]);
      currentDirection = wavesHierarchy[i].direction;
      continue;
    }

    const isNewDirection = currentDirection !== wavesHierarchy[i].direction;

    if (isNewDirection) {
      addCascade(waveCascades, currentCascade, currentDirection, i, prefix);
      currentCascade = [wavesHierarchy[i]];
      currentDirection = wavesHierarchy[i].direction;
    } else {
      currentCascade.push(wavesHierarchy[i]);
    }
  }

  if (currentCascade.length) {
    addCascade(waveCascades, currentCascade, currentDirection, wavesHierarchy.length, prefix, true);
  }

  markWaves(waveCascades);
  return waveCascades;
}

function addCascade(waveCascades, currentCascade, currentDirection, index, prefix) {
  if (currentCascade.length > 1) {
    const high = currentDirection === "up" ? currentCascade[currentCascade.length - 1].high : currentCascade[0].high;
    const low = currentDirection === "up" ? currentCascade[0].low : currentCascade[currentCascade.length - 1].low;
    const delta = high - low;
    const deltaPercent = currentDirection === "up" ? delta / low : delta / high;
    const idx = prefix ? `${prefix}:${index}` : `${index}`;
    const cas = {
      direction: currentDirection,
      idx,
      high,
      low,
      delta,
      deltaPercent,
      start: currentCascade[0].start,
      end: currentCascade[currentCascade.length - 1].end,
      segments: currentCascade
    }

    if (currentCascade[currentCascade.length - 1].corrections) {
      cas.corrections = currentCascade[currentCascade.length - 1].corrections
      cas.correction = currentCascade[currentCascade.length - 1].correction
    }

    waveCascades.push(cas);
  } else if (currentCascade.length === 1) {
    // Directly add the single wave object instead of wrapping it into another layer
    waveCascades.push(currentCascade[0]);
  }
}

export function compressWaveCascades(waveHierarchy, maxDepth = 10) {
  const cascadeStages = []

  let cascadeLength = Infinity
  let cascadesHierarchyLength = Infinity
  let currentWaveHierarchy = waveHierarchy

  for (let i = 0; cascadeLength > 1 && cascadesHierarchyLength > 1; i++) {
    if (i > maxDepth) {
      // console.warn('compressWaveCascades max depth reached', i)
      break
    }
    const waveCascades = getWaveCascades(currentWaveHierarchy, `X${i}`)
    // console.log('waveCascades', i, waveCascades)
    const waveCascadesHierarchy = createWaveHierarchy(waveCascades, `S${i}`)
    // console.log('waveCascadesHierarchy', i, waveCascadesHierarchy)
    currentWaveHierarchy = waveCascadesHierarchy

    // for (let j = 0; j < waveCascadesHierarchy.length; j++) {
    //   const wave = waveCascadesHierarchy[j]
    //   if (wave.corrections && wave.corrections.length) {
    //     const compresedCorrections = compressWaveCascades(wave.corrections, maxDepth)
    //     // wave.corrections = compresedCorrections[compresedCorrections.length - 1].hierarchy
    //     wave.compresedCorrections3 = compresedCorrections[compresedCorrections.length - 1].hierarchy
    //   }
    // }

    if (cascadeStages.length) {
      const lastCascade = cascadeStages[cascadeStages.length - 1]
      const { cascades, hierarchy } = lastCascade

      const isSameCascades = cascades.length === waveCascades.length
      const isSameHierarchy = hierarchy.length === waveCascadesHierarchy.length
      const noMoreCascades = waveCascades.length === hierarchy.length

      if (isSameCascades || isSameHierarchy || noMoreCascades) {
        // console.log('compressWaveCascades noMoreCascades', i, isSameCascades, isSameHierarchy, noMoreCascades)
        break
      } else {
        cascadeLength = waveCascades.length
        cascadesHierarchyLength = waveCascadesHierarchy.length
        const stage = { cascades: waveCascades, hierarchy: waveCascadesHierarchy }
        cascadeStages.push(stage)
      }
    } else {
      cascadeLength = waveCascades.length
      cascadesHierarchyLength = waveCascadesHierarchy.length
      const stage = { cascades: waveCascades, hierarchy: waveCascadesHierarchy }
      cascadeStages.push(stage)
    }
  }

  return cascadeStages
}

// ***********************************************
// draw
// ***********************************************

// waves
// ***********************************************

export function drawLevelsLine(chart, levels, endTime, color = 'white', lineStyle = LineStyle.Solid) {
  const seriesData = [];
  for (let i = 0; i < levels.length; i++) {
    const level = levels[i];
    const point = { time: level.startTime, value: level.level }
    seriesData.push(point);
  }
  seriesData.sort((a, b) => a.time - b.time)

  // fix for charts same time limitation
  for (let i = 0; i < seriesData.length - 1; i++) {
    if (i > 0 && seriesData[i - 1].time === seriesData[i].time) {
      seriesData[i].time++
    }
  }
  // console.log('levelSeriesData', levelSeriesData)

  // Отрисовываем уровни на графике
  const levelSeries = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  try {
    levelSeries.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawLevelsLine', error)
  }

  return levelSeries
}

export function drawWaves(chart, waves, endTime, color = 'white', lineStyle = LineStyle.Solid, showIdx = false) {
  const series = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  const seriesData = []
  for (let i = 0; i < waves.length; i++) {
    const wave = waves[i];

    if (wave.direction === 'up') {
      seriesData.push({ time: wave.start, value: wave.low });
      // seriesData.push({ time: wave.end - 1, value: wave.high });
    } else {
      seriesData.push({ time: wave.start, value: wave.high });
      // seriesData.push({ time: wave.end - 1, value: wave.low });
    }

    if (!wave.type || wave.type === 'move') {
      const delta = wave.high - wave.low
      if (wave.direction === 'up') {
        const deltaPercent = (delta / wave.low) * 100
        const percent = deltaPercent < 100
          ? deltaPercent.toPrecision(2)
          : deltaPercent.toPrecision(3)
        const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
        const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
        const label = `${lableIdx}${percent}%${labelCluster}`
        const point1 = { time: wave.start, price: wave.low };
        const point2 = { time: wave.end, price: wave.high, label };
        const trend = new TrendLine(chart, series, point1, point2, {
          lineColor: 'rgb(0, 255, 0)',
          width: 2,
          showLabels: true,
          labelBackgroundColor: "rgba(255, 255, 255, 0)",
          labelTextColor: "rgb(0, 255, 0)"
        });
        series.attachPrimitive(trend);
      } else {
        const deltaPercent = (delta / wave.high) * 100
        const percent = deltaPercent < 100
          ? deltaPercent.toPrecision(2)
          : deltaPercent.toPrecision(3)
        const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
        const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
        const label = `${lableIdx}${percent}%${labelCluster}`
        const point1 = { time: wave.start, price: wave.high };
        const point2 = { time: wave.end, price: wave.low, label };
        const trend = new TrendLine(chart, series, point1, point2, {
          lineColor: 'rgb(255, 0, 0)',
          width: 2,
          showLabels: true,
          labelBackgroundColor: "rgba(255, 255, 255, 0)",
          labelTextColor: "rgb(255, 0, 0)"
        });
        series.attachPrimitive(trend);
      }
    } else {
      const delta = wave.high - wave.low
      const deltaPercent = (delta / wave.high) * 100
      const percent = deltaPercent < 100
        ? deltaPercent.toPrecision(2)
        : deltaPercent.toPrecision(3)

      if (wave.direction === 'up') {
        const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
        const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''

        const label = `${lableIdx}${percent}%${labelCluster}`
        const point1 = { time: wave.start, price: wave.low };
        const point2 = { time: wave.end, price: wave.high, label };
        const trend = new TrendLine(chart, series, point1, point2, {
          lineColor: 'rgb(150, 220, 0)',
          width: 1,
          showLabels: true,
          labelBackgroundColor: "rgba(255, 255, 255, 0)",
          labelTextColor: "rgb(150, 220, 0)"
        });
        series.attachPrimitive(trend);
      } else {
        const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
        const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
        const label = `${lableIdx}${percent}%${labelCluster}`

        const point1 = { time: wave.start, price: wave.high };
        const point2 = { time: wave.end, price: wave.low, label };
        const trend = new TrendLine(chart, series, point1, point2, {
          lineColor: 'rgb(220, 150, 0)',
          width: 1,
          showLabels: true,
          labelBackgroundColor: "rgba(255, 255, 255, 0)",
          labelTextColor: "rgb(220, 150, 0)"
        });
        series.attachPrimitive(trend);
      }
    }

  }

  const lastWave = waves[waves.length - 1]
  if (lastWave.direction === 'up') {
    seriesData.push({ time: lastWave.end, value: lastWave.high });
    // seriesData.push({ time: wave.end - 1, value: wave.high });
  } else {
    seriesData.push({ time: lastWave.end, value: lastWave.low });
    // seriesData.push({ time: wave.end - 1, value: wave.low });
  }

  seriesData.sort((a, b) => a.time - b.time)
  // fix for charts same time limitation
  for (let i = 0; i < seriesData.length - 1; i++) {
    if (i > 0 && seriesData[i - 1].time === seriesData[i].time) {
      seriesData[i].time++
    }
  }
  // console.log('seriesData', seriesData)


  try {
    series.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawWaves', error)
  }

  return series
}

export function drawPredictedWaves(chart, waves, endTime, color = 'white', lineStyle = LineStyle.Solid, showIdx = false) {
  const series = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  const seriesData = []
  for (let i = 0; i < waves.length; i++) {
    const wave = waves[i];

    if (wave.direction === 'up') {
      seriesData.push({ time: wave.start, value: wave.low });
      // seriesData.push({ time: wave.end - 1, value: wave.high });
    } else {
      seriesData.push({ time: wave.start, value: wave.high });
      // seriesData.push({ time: wave.end - 1, value: wave.low });
    }

    if (wave.direction === 'up') {
      const label = `${wave.type}`
      const point1 = { time: wave.start, price: wave.low };
      const point2 = { time: wave.end, price: wave.high, label };
      const trend = new TrendLine(chart, series, point1, point2, {
        lineColor: 'rgb(150, 255, 150)',
        width: 3,
        showLabels: true,
        labelBackgroundColor: "rgba(255, 255, 255, 0)",
        labelTextColor: "rgb(150, 255, 150)"
      });
      series.attachPrimitive(trend);
    } else {
      const label = `${wave.type}`
      const point1 = { time: wave.start, price: wave.high };
      const point2 = { time: wave.end, price: wave.low, label };
      const trend = new TrendLine(chart, series, point1, point2, {
        lineColor: 'rgb(255, 150, 150)',
        width: 3,
        showLabels: true,
        labelBackgroundColor: "rgba(255, 255, 255, 0)",
        labelTextColor: "rgb(255, 150, 150)"
      });
      series.attachPrimitive(trend);
    }

  }

  const lastWave = waves[waves.length - 1]
  if (lastWave.direction === 'up') {
    seriesData.push({ time: lastWave.end, value: lastWave.high });
    // seriesData.push({ time: wave.end - 1, value: wave.high });
  } else {
    seriesData.push({ time: lastWave.end, value: lastWave.low });
    // seriesData.push({ time: wave.end - 1, value: wave.low });
  }

  seriesData.sort((a, b) => a.time - b.time)
  // fix for charts same time limitation
  for (let i = 0; i < seriesData.length - 1; i++) {
    if (i > 0 && seriesData[i - 1].time === seriesData[i].time) {
      // const diff = seriesData[i].time - seriesData[i - 1].time
      // seriesData[i].time += diff + 1
      seriesData[i].time++
    }
  }
  // console.log('seriesData', seriesData)


  try {
    series.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawWaves', error)
  }

  return series
}


export function drawPredictedWavesLine(chart, waves, endTime, color = 'white', lineStyle = LineStyle.Solid, showIdx = false) {
  const series = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  const seriesData = []
  for (let i = 0; i < waves.length; i++) {
    const wave = waves[i];

    if (wave.direction === 'up') {
      // seriesData.push({ time: wave.start, value: wave.high });
      seriesData.push({ time: wave.start, value: wave.low });
      seriesData.push({ time: wave.end - 1, value: wave.high });
    } else {
      // seriesData.push({ time: wave.start, value: wave.low });
      seriesData.push({ time: wave.start, value: wave.high });
      seriesData.push({ time: wave.end - 1, value: wave.low });
    }
  }

  const lastWave = waves[waves.length - 1]
  if (lastWave.direction === 'up') {
    seriesData.push({ time: lastWave.end, value: lastWave.low });
    // seriesData.push({ time: wave.end - 1, value: wave.high });
  } else {
    seriesData.push({ time: lastWave.end, value: lastWave.high });
    // seriesData.push({ time: wave.end - 1, value: wave.low });
  }

  seriesData.sort((a, b) => a.time - b.time)
  // fix for charts same time limitation
  for (let i = 0; i < seriesData.length - 1; i++) {
    if (i > 0 && seriesData[i - 1].time === seriesData[i].time) {
      seriesData[i].time++
    }
  }
  // console.log('seriesData', seriesData)


  try {
    series.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawWavesLine', error)
  }

  return series
}

export function drawWavesHierarchy(chart, waves, nesting, endTime, color = 'white', maxNestingToShowLabels = 0, showIdx = false, showCorrectionBox = false) {
  const allSeries = [];

  function drawWave(wave, nesting, lablePrefix = '') {
    if (wave.drawn) return
    wave.drawn = true

    let lineStyle = LineStyle.Solid
    let width = 3
    if (nesting === 1) {
      lineStyle = LineStyle.Dashed
      width = 2
    } else if (nesting > 1) {
      lineStyle = LineStyle.Dotted
      width = 1
    }


    const series = chart.addLineSeries({
      color,
      lineWidth: 1,
      lineStyle,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    const seriesData = [];
    const opacityInit = 1 - Math.log(1 + nesting) / Math.log(10)
    const opacity = (Math.max(0.4, opacityInit)).toFixed(2)

    let delta = wave.high - wave.low;
    let deltaPercent = 0
    if (wave.direction === 'up') {
      seriesData.push({ time: wave.start, value: wave.low })
      deltaPercent = (delta / wave.low) * 100
    } else {
      seriesData.push({ time: wave.start, value: wave.high })
      // delta = wave.low - wave.high
      deltaPercent = (delta / wave.high) * 100
    }


    const showLabels = nesting < maxNestingToShowLabels
    const prefixLetter = getLatinLetterByIndex(nesting)
    const prefix = maxNestingToShowLabels > 1 ? `${prefixLetter} ` : ''
    const percent = deltaPercent < 100
      ? deltaPercent.toPrecision(2)
      : deltaPercent.toPrecision(3)

    const lableIdx = showIdx ? `#${wave.idx} ` : ''
    const label = `${lableIdx}${lablePrefix} ${prefix}${percent}%`;
    let point1, point2, trendLineColor;

    if (wave.direction === 'up') {
      point1 = { time: wave.start, price: wave.low };
      point2 = { time: wave.end, price: wave.high, label };
      trendLineColor = `rgba(0, 255, 0, ${opacity})`;
    } else {
      point1 = { time: wave.start, price: wave.high };
      point2 = { time: wave.end, price: wave.low, label };
      trendLineColor = `rgba(255, 0, 0, ${opacity})`;
    }

    const trend = new TrendLine(chart, series, point1, point2, {
      lineColor: trendLineColor,
      width,
      showLabels,
      labelBackgroundColor: `rgba(0, 0, 0, ${opacity})`,
      labelTextColor: trendLineColor
    });
    series.attachPrimitive(trend);

    seriesData.sort((a, b) => a.time - b.time);
    series.setData(seriesData);
    allSeries.push(series);

    // Рекурсивно рисуем подволны коррекции
    if (wave.corrections && wave.corrections.length > 0) {
      wave.corrections.forEach(subWave => {
        drawWave(subWave, nesting + 1, 'Pullback');
      });

      if (showCorrectionBox && nesting <= maxNestingToShowLabels) {
        const boxSeries = drawCorrectionBox(chart, wave, nesting + 1)
        allSeries.push(...boxSeries);
      }
    }

    // Рекурсивно рисуем подволны каскадов
    const segmentsNesting = nesting + 1
    if (wave.segments && wave.segments.length > 0) {
      wave.segments.forEach(subWave => {
        drawWave(subWave, segmentsNesting + 1, 'Wave');
      });
    }
  }

  waves.forEach(wave => drawWave(wave, nesting));

  return allSeries;
}

function drawCorrectionBox(chart, wave, nesting, color = 'transparent') {
  let width = 3
  if (nesting > 2) {
    width = 2
  } else if (nesting > 3) {
    width = 1
  }

  const seriesHigh = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
  });
  const seriesLow = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  const seriesHighData = [];
  const seriesLowData = [];

  const opacityInit = 1 - Math.log(1 + nesting) / Math.log(10)
  const opacity = (Math.max(0.5, opacityInit)).toFixed(2)
  const trendLineColor = `rgba(200, 200, 255, ${opacity})`;

  const correction = wave.correction

  const hPoint1 = { time: correction.start, price: correction.high }
  const hPoint2 = { time: correction.end, price: correction.high }
  seriesHighData.push({ time: correction.start, value: correction.high })
  seriesHighData.push({ time: correction.end, value: correction.high })

  const lPoint1 = { time: correction.start, price: correction.low }
  const lPoint2 = { time: correction.end, price: correction.low }
  seriesLowData.push({ time: correction.start, value: correction.low })
  seriesLowData.push({ time: correction.end, value: correction.low })

  const trendHigh = new TrendLine(chart, seriesHigh, hPoint1, hPoint2, {
    lineColor: trendLineColor,
    width,
    showLabels: false,
  });
  seriesHigh.attachPrimitive(trendHigh);

  const trendLow = new TrendLine(chart, seriesHigh, lPoint1, lPoint2, {
    lineColor: trendLineColor,
    width,
    showLabels: false,
  });
  seriesHigh.attachPrimitive(trendLow);

  try {
    seriesHigh.setData(seriesHighData)
  } catch (error) {
    console.log('seriesHigh.setData drawCorrectionBox', wave, error)
  }
  try {
    seriesLow.setData(seriesLowData)
  } catch (error) {
    console.log('seriesLow.setData drawCorrectionBox', wave, error)
  }
  return [seriesHigh, seriesLow]
}

// wave fibo retracement
// ***********************************************

export function drawWavesFibo(currentChart, masterWave, nesting, intervalMinutes, maxWaveFiboNesting = 0, endTime) {
  const series = [];
  if (nesting >= maxWaveFiboNesting || !masterWave) {
    return series;
  }

  function drawWaveAndSubwaves(wave, nestLevel, showSegments, showCorrections, prefix = '') {
    if (nestLevel >= maxWaveFiboNesting) return
    // most important fibos drawn last in order to be on top

    if (showCorrections && wave.corrections && wave.corrections.length) {
      const lastCorrections = wave.corrections.slice(-2)
      const postfix = getLatinLetterByIndex(nestLevel)
      const correctionPrefix = prefix ? `${prefix}-Z:${postfix}` : `Z:${postfix}`;
      lastCorrections.forEach((subWave, i) => {
        drawWaveAndSubwaves(subWave, nestLevel + 1, false, true, correctionPrefix);
      });
    }

    if (showSegments && wave.segments && wave.segments.length) {
      const postfix = getLatinLetterByIndex(nestLevel)
      const segmentPrefix = prefix ? `${prefix}-S:${postfix}` : `S:${postfix}`;
      const lastSegment = wave.segments[wave.segments.length - 1];
      drawWaveAndSubwaves(lastSegment, nestLevel + 1, false, false, segmentPrefix);
    }

    const s = drawWaveFibo(currentChart, wave, nestLevel, intervalMinutes, maxWaveFiboNesting, prefix, endTime);
    series.push(s);
  }

  drawWaveAndSubwaves(masterWave, nesting, true, true);
  return series;
}

function drawWaveFibo(currentChart, masterWave, nesting, intervalMinutes, maxWaveFiboNesting, prefix = '', endTime) {
  // const rightShift = (maxWaveFiboNesting - nesting) * 60 * intervalMinutes * 5
  const rightShift = (nesting) * 60 * intervalMinutes * 20

  const wave = masterWave
  const { direction, high, delta, start, end, correction } = wave
  const corEnd = correction ? correction.end : end

  const ext = direction === 'up' ? fiboUpExtension : fiboDownExtension
  const fiboLevels = [...fiboCorrectionLevels, ...fiboHL, ...ext]
  const postfix = getLatinLetterByIndex(nesting)
  //const label = prefix ? `${prefix}:${postfix}` : postfix
  const label = prefix ? prefix : postfix

  const fixedEnd = corEnd + rightShift >= endTime ? endTime : corEnd + rightShift

  const levels = []
  for (let j = 0; j < fiboLevels.length; j++) {
    const mult = fiboLevels[j]
    const level = { level: high - delta * mult, fibo: mult, start, end: fixedEnd }
    levels.push(level)
    // console.log('cascadeStages fibo', prefix, level)
  }

  const series = drawFiboLines(currentChart, levels, high, start, fixedEnd, label)
  return series
}

function drawFiboLines(chart, levels, price, startTime, endTime, prefix) {
  const series = chart.addLineSeries({
    color: 'transparent',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  const seriesData = [];
  seriesData.push({ time: startTime, value: price });
  seriesData.push({ time: endTime, value: price });

  for (let i = 0; i < levels.length; i++) {
    const level = levels[i];
    // seriesData.push({ time: level.start, value: level.level });


    let alpha = 0.33
    let color = `rgba(125, 253, 258, ${alpha})`
    if (level.fibo === 0.5) {
      alpha = 1
      color = `rgba(255, 100, 0, ${alpha})`
    } else if (level.fibo === 0.618 || level.fibo === 0.382) {
      alpha = 0.75
      color = `rgba(238, 238, 76, ${alpha})`
    } else if (level.fibo === 0.786 || level.fibo === 0.236) {
      alpha = 0.66
      color = `rgba(230, 50, 250, ${alpha})`
    }


    const label = `${prefix} ${level.fibo}` // ${level.level.toPrecision(4)}
    const point1 = { time: level.start, price: level.level };
    const point2 = { time: level.end, price: level.level, label };

    const trend = new TrendLine(chart, series, point1, point2, {
      lineColor: color,
      width: 1,
      showLabels: true,
      labelBackgroundColor: `rgba(0, 0, 0, ${alpha})`,
      labelTextColor: color,
    });
    series.attachPrimitive(trend);
  }

  // for (let i = 0; i < levels.length; i++) {
  //   const level = levels[i];
  //   seriesData.push({ time: level.end, value: level.level });
  // }
  // seriesData.push({ time: endTime + levels.length * 3, value: 0 });

  seriesData.sort((a, b) => a.time - b.time)
  try {
    series.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawFiboLines', seriesData, error)
  }

  return series

}



export function getWavesTrendLines(waves, endTime) {
  if (waves.length < 3) return { low: null, high: null };

  const w1 = waves[waves.length - 3];
  const w2 = waves[waves.length - 1];

  // Различие в расчёте времени для highs и lows в зависимости от направления первой волны
  const totalTimeHighs = w1.direction === 'up' ? (w2.end - w1.end) : (w2.start - w1.start);
  const totalTimeLows = w1.direction === 'up' ? (w2.start - w1.start) : (w2.end - w1.end);

  // Вычисление наклона и конечных точек
  const slopeHigh = (w2.high - w1.high) / totalTimeHighs;
  const slopeLow = (w2.low - w1.low) / totalTimeLows;

  const highsEndPrice = w1.direction === 'up'
    ? w2.high + slopeHigh * (endTime - w2.end)
    : w2.high + slopeHigh * (endTime - w2.start);

  const lowsEndPrice = w1.direction === 'up'
    ? w2.low + slopeLow * (endTime - w2.start)
    : w2.low + slopeLow * (endTime - w2.end);

  return {
    high: {
      start: {
        time: w1.direction === 'up' ? w1.end : w1.start,
        price: w1.high
      },
      end: {
        time: endTime,
        price: highsEndPrice
      }
    },
    low: {
      start: {
        time: w1.direction === 'up' ? w1.start : w1.end,
        price: w1.low
      },
      end: {
        time: endTime,
        price: lowsEndPrice
      }
    }
  };
}

export function drawWavesTrendLines(chart, trendLinesData) {
  const seriesOptions = {
    color: 'transparent',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastTimeVisible: false,
    priceLineVisible: false,
  };

  const series = {
    high: chart.addLineSeries(seriesOptions),
    low: chart.addLineSeries(seriesOptions),
  };

  const colors = {
    high: 'rgba(50, 100, 255, 1)',
    low: 'rgba(50, 100, 255, 1)',
  };

  function createTrendLine(data, color) {
    const startPoint = { time: data.start.time, price: data.start.price };
    const endPoint = { time: data.end.time, price: data.end.price };

    const trendLine = new TrendLine(chart, series[color], startPoint, endPoint, {
      lineColor: colors[color],
      width: 2,
      showLabels: true,
      labelBackgroundColor: "rgba(255, 255, 255, 0)",
      labelTextColor: colors[color],
    });

    series[color].attachPrimitive(trendLine);
  }

  if (trendLinesData.high) {
    try {
      const data = trendLinesData.high;
      series.high.setData([{ time: data.start.time, value: data.start.price }, { time: data.end.time, value: data.end.price }]);
      createTrendLine(data, 'high');
    } catch (error) {
      console.log('Error setting data for trend line (high)', error);
    }
  }

  if (trendLinesData.low) {
    try {
      const data = trendLinesData.low;
      series.low.setData([{ time: data.start.time, value: data.start.price }, { time: data.end.time, value: data.end.price }]);
      createTrendLine(data, 'low');
    } catch (error) {
      console.log('Error setting data for trend line (low)', error);
    }
  }

  return [series.high, series.low];
}


// levels
// ***********************************************

export function drawKeyLevels(chart, keyLevels, endTime) {
  const { highLevels, lowLevels } = keyLevels

  const highSeriesData = [];
  for (let i = 0; i < highLevels.length; i++) {
    const level = highLevels[i];
    highSeriesData.push({ time: level.startTime, value: level.level });
    if (i < (highLevels.length - 1)) {
      highSeriesData.push({
        time: highLevels[i + 1].startTime - 1,
        value: level.level
      });

    } else {
      highSeriesData.push({
        time: endTime + 1,
        value: level.level
      });
    }
  }

  const lowSeriesData = [];
  for (let i = 0; i < lowLevels.length; i++) {
    const level = lowLevels[i];
    lowSeriesData.push({ time: level.startTime, value: level.level });
    if (i < (lowLevels.length - 1)) {
      lowSeriesData.push({
        time: lowLevels[i + 1].startTime - 1,
        value: level.level
      });
    } else {
      lowSeriesData.push({
        time: endTime + 1,
        value: level.level
      });
    }
  }
  // console.log('highSeriesData', highSeriesData)
  // console.log('lowSeriesData', lowSeriesData)

  // Отрисовываем высокие уровни на графике
  const highSeries = chart.addLineSeries({
    color: 'red',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
  });
  highSeriesData.sort((a, b) => a.time - b.time)
  try {
    highSeries.setData(highSeriesData);
  } catch (error) {
    console.log('series.setData drawKeyLevels', error)
  }


  // Отрисовываем низкие уровни на графике
  const lowSeries = chart.addLineSeries({
    color: 'blue',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
  });
  lowSeriesData.sort((a, b) => a.time - b.time)
  try {
    lowSeries.setData(lowSeriesData);
  } catch (error) {
    console.log('series.setData drawKeyLevels', error)
  }

}

export function drawImportantLevels(chart, importantLevels, endTime, latestClose, alphaMult = 1, LEVEL_HIDE_DISTANCE) {
  if (!chart) return []
  if (!importantLevels.length) return []
  const data = []
  const allSeries = []

  try {
    const levelSeries = chart.addLineSeries({
      color: '#FFFFFF00',
      lineWidth: 1,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    allSeries.push(levelSeries)

    importantLevels.forEach((level) => {
      if (level.level * (1 + LEVEL_HIDE_DISTANCE) < latestClose
        || level.level * (1 - LEVEL_HIDE_DISTANCE) > latestClose) {
        return null
      }

      const alpha0 = level.unbeat ? 1 : 0.5
      const mainColor = getImportantLevelColor(level, alpha0)

      const priceLine = {
        price: level.level,
        color: mainColor,
        lineWidth: 1,
        lineStyle: LineStyle.Dashed,
        lastValueVisible: false,
        priceLineVisible: false,
        axisLabelVisible: !!level.unbeat,
        title: `${level.type} ${level.touches || 1} ${level.mergedLevels ? level.mergedLevels.length : 1}`,
      };
      data.push({ time: level.startTime, value: latestClose });

      levelSeries.createPriceLine(priceLine);

      // ***********************************************
      // Lower and upper bound Base Line
      // ***********************************************
      const series = chart.addLineSeries({
        color: '#FFFFFF00',
        lineWidth: 1,
        lastValueVisible: false,
        priceLineVisible: false,
      });
      allSeries.push(series)

      const alpha1 = level.unbeat ? 0.33 : 0.1
      const alpha2 = level.unbeat ? 0.66 : 0.33
      const lightColor = getImportantLevelColor(level, alpha1 * alphaMult)
      const darkColor = getImportantLevelColor(level, alpha2 * alphaMult)

      // Main level
      const mainPriceLine = {
        price: level.level,
        color: mainColor,
        lineWidth: 2,
        lineStyle: LineStyle.Dashed,
        // axisLabelVisible: true,
        // title: 'Level ' + level.level.toFixed(2),
      };
      series.createPriceLine(mainPriceLine);

      // ***********************************************
      // Lower and upper bound lines
      // ***********************************************

      const opts = {
        baseValue: {
          type: 'price', price: level.level
        },
        lastValueVisible: false,
        priceLineVisible: false,
        lineStyle: LineStyle.Dotted,
        lineWidth: 1,
        // top colors
        topLineColor: darkColor,
        topFillColor1: darkColor,
        topFillColor2: lightColor,
        // bottom colors
        bottomLineColor: darkColor,
        bottomFillColor1: lightColor,
        bottomFillColor2: darkColor,
      }

      const boundSeriesUP = chart.addBaselineSeries(opts);
      const upperBound = level.upperBound //(level.level + level.atr) || level.upperBound
      const lowerBound = level.lowerBound //(level.level - level.atr) || level.lowerBound


      try {
        boundSeriesUP.setData([
          { time: level.startTime, value: upperBound },
          { time: endTime > level.startTime ? endTime : endTime + 61, value: upperBound }
        ]);
      } catch (error) {
        console.log('series.setData boundSeriesUP', error)
      }
      allSeries.push(boundSeriesUP)

      const boundSeriesDown = chart.addBaselineSeries(opts);

      try {
        boundSeriesDown.setData([
          { time: level.startTime, value: lowerBound },
          { time: endTime > level.startTime ? endTime : endTime + 61, value: lowerBound }
        ]);
      } catch (error) {
        console.log('series.setData boundSeriesDown', error)
      }
      allSeries.push(boundSeriesDown)
    });

    data.push({ time: endTime + 61, value: importantLevels[0].level }); // bugfix point
    data.sort((a, b) => a.time - b.time)

    try {
      levelSeries.setData(data);
    } catch (error) {
      console.log('series.setData drawImportantLevels', error)
    }

  } catch (e) {
    console.error('drawImportantLevels', e)
  }
  return allSeries
}

export function getImportantLevelColor(level, alpha = 1) {
  if (level.type === 'resistance') {
    return `rgba(255,0,0,${alpha})`; // Red for high levels
  } else if (level.type === 'support') {
    return `rgba(0,255,0,${alpha})`; // Green for low levels
  } else if (level.type === 'fibo') {
    return `rgba(255,255,255,${alpha})`; // white for fibo levels
  } else {
    return `rgba(255,0,255,${alpha})`; // Blue for double levels
  }
}

// fibo

export function drawFiboLevels(chart, fiboLevels, endTime) {
  if (!chart) return []
  const data = []
  const allSeries = []

  try {
    const levelSeries = chart.addLineSeries({
      color: '#FFFFFF00',
      lineWidth: 1,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    allSeries.push(levelSeries)

    fiboLevels.forEach((level, i) => {
      const alpha0 = 0.66 //level.mergedLevels ? Math.min(1, 0.5 * level.mergedLevels.length) : 0.3
      const mainColor = getImportantLevelColor(level, alpha0)

      const priceLine = {
        price: level.level,
        color: mainColor,
        lineWidth: 1,
        lineStyle: LineStyle.SparseDotted,
        lastValueVisible: false,
        priceLineVisible: false,
        axisLabelVisible: false, //!!level.unbeat,
        title: `${level.type} ${level.mult}`, //${level.mergedLevels ? level.mergedLevels.length : 1}
      };
      data.push({ time: endTime + i, value: level.level });

      levelSeries.createPriceLine(priceLine);
    });

    // data.push({ time: endTime + 61, value: fiboLevels[0].level }); // bugfix point
    data.sort((a, b) => a.time - b.time)

    try {
      levelSeries.setData(data);
    } catch (error) {
      console.log('series.setData drawFiboLevels', error)
    }


  } catch (e) {
    console.error('drawFiboLevels', e)
  }
  return allSeries
}



export function drawBacktestWaves(chart, waveBacktest, endTime, color = 'white', lineStyle = LineStyle.Solid, showIdx = false) {
  const { wavesChanges: waves } = waveBacktest

  const series = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  // remove time dubs
  const seriesData = []
  const timesArr = []
  for (let i = 0; i < waves?.length; i++) {
    const wave = waves[i];
    if (!timesArr.includes(wave.start)) {
      seriesData.push({ time: wave.start, value: wave.low });
      timesArr.push(wave.start)
    }
    if (!timesArr.includes(wave.end)) {
      seriesData.push({ time: wave.end, value: wave.low });
      timesArr.push(wave.end)
    }
  }
  seriesData.sort((a, b) => a.time - b.time)


  for (let i = 0; i < waves?.length; i++) {
    const wave = waves[i];

    let opacity = 0.25
    let prefix = ''
    if (wave.newWave) {
      opacity = 0.5
      prefix = 'N '
    } else if (wave.lastUp) {
      opacity = 0.3
      prefix = 'U '
    } else if (wave.extremUp) {
      opacity = 0.1
      prefix = 'E '
    }

    const showWaves = true

    if (showWaves) {
      if (!wave.type || wave.type === 'move') {
        const delta = wave.high - wave.low
        if (wave.direction === 'up') {
          const deltaPercent = (delta / wave.low) * 100
          const percent = deltaPercent < 100
            ? deltaPercent.toPrecision(2)
            : deltaPercent.toPrecision(3)
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`
          const point1 = { time: wave.start, price: wave.low };
          const point2 = { time: wave.end, price: wave.high, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(0, 255, 0, ${opacity})`,
            width: 2,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(0, 255, 0, ${opacity})`
          });
          series.attachPrimitive(trend);
        } else {
          const deltaPercent = (delta / wave.high) * 100
          const percent = deltaPercent < 100
            ? deltaPercent.toPrecision(2)
            : deltaPercent.toPrecision(3)
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`
          const point1 = { time: wave.start, price: wave.high };
          const point2 = { time: wave.end, price: wave.low, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(255, 255, 0, ${opacity})`,
            width: 2,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(255, 255, 0, ${opacity})`
          });
          series.attachPrimitive(trend);
        }
      } else {
        const delta = wave.high - wave.low
        const deltaPercent = (delta / wave.high) * 100
        const percent = deltaPercent < 100
          ? deltaPercent.toPrecision(2)
          : deltaPercent.toPrecision(3)

        if (wave.direction === 'up') {
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''

          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`
          const point1 = { time: wave.start, price: wave.low };
          const point2 = { time: wave.end, price: wave.high, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(150, 220, 0, ${opacity})`,
            width: 1,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(150, 220, 0, ${opacity})`,
          });
          series.attachPrimitive(trend);
        } else {
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`

          const point1 = { time: wave.start, price: wave.high };
          const point2 = { time: wave.end, price: wave.low, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(220, 150, 0, ${opacity})`,
            width: 1,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(220, 150, 0, ${opacity})`,
          });
          series.attachPrimitive(trend);
        }
      }
    }

    const showHighs = true
    const showLows = true
    const showLevels = true
    const showSL = true
    const showEnterStart = true
    const showTPPercent = true
    const showTPRatio = true
    const showTPRatioClusters = true

    if (i > 0 && i < waves.length - 1) {
      const windowTimeInCandles = 2
      const timeMult = 60

      const prevWave = waves[i - 1]
      const nextWave = waves[i + 1]
      const usedWave = wave

      // wave.newWave && 
      if (wave.direction === 'up') {
        if (showHighs && usedWave?.closestHighs?.length) {
          usedWave.closestHighs.forEach((level, i) => {
            const isTimeActive = level.start + windowTimeInCandles * timeMult < wave.end
            if (!isTimeActive) return

            if (showLevels) {
              const point1 = { time: level.end, price: level.high }
              const point2 = { time: nextWave.end, price: level.high }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 255, 255, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const bounceSLPercentLong = usedWave?.bounceSLPercentLong?.optimal?.limit
            if (showSL && bounceSLPercentLong) {
              const sl = level.high + level.high * bounceSLPercentLong
              const point1 = { time: level.end, price: sl }
              const point2 = { time: nextWave.end, price: sl }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 0, 0, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const bounceEnterLong = usedWave?.bounceEnterLong?.optimal?.limit
            if (showEnterStart && bounceEnterLong) {
              const enter = level.high - level.high * bounceEnterLong
              const point1 = { time: level.end, price: enter }
              const point2 = { time: nextWave.end, price: enter }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 255, 0, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const waveTargetPercent = usedWave?.waveTargetPercent
            if (showTPPercent && waveTargetPercent) {
              const price = level.high - level.high * waveTargetPercent
              const point1 = { time: level.end, price }
              const point2 = { time: nextWave.end, price }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(155, 255, 155, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const waveTargetDelta = wave.waveTargetDeltaUp
            if (showTPRatio && waveTargetDelta) {
              const price = level.high - waveTargetDelta
              const point1 = { time: level.end, price }
              const point2 = { time: nextWave.end, price }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(15, 255, 205, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const clusterTargetsUp = wave.clusterTargetsUp
            if (showTPRatioClusters && clusterTargetsUp.length) {
              for (const target of clusterTargetsUp) {
                const price = level.high - target
                const point1 = { time: level.end, price }
                const point2 = { time: nextWave.end, price }
                const trend = new TrendLine(chart, series, point1, point2, {
                  lineColor: 'rgba(15, 205, 240, 0.8)',
                  width: 1,
                  showLabels: false,
                });
                series.attachPrimitive(trend);
              }
            }

          });
        }
      }

      if (wave.direction === 'down') {
        if (showLows && usedWave?.closestLows?.length) {
          usedWave.closestLows.forEach((level, i) => {
            const isTimeActive = level.start + windowTimeInCandles * timeMult < wave.end
            if (!isTimeActive) return

            if (showLevels) {
              const point1 = { time: level.end, price: level.low }
              const point2 = { time: nextWave.end, price: level.low }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 255, 255, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const bounceSLPercentShort = usedWave?.bounceSLPercentShort?.optimal?.limit
            if (showSL && bounceSLPercentShort) {
              const sl = level.low - level.low * bounceSLPercentShort
              const point1 = { time: level.end, price: sl }
              const point2 = { time: nextWave.end, price: sl }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 0, 0, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const bounceEnterShort = usedWave?.bounceEnterShort?.optimal?.limit
            if (showEnterStart && bounceEnterShort) {
              const enter = level.low + level.low * bounceEnterShort
              const point1 = { time: level.end, price: enter }
              const point2 = { time: nextWave.end, price: enter }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 255, 0, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const waveTargetPercent = usedWave?.waveTargetPercent
            if (showTPPercent && waveTargetPercent) {
              const price = level.low + level.low * waveTargetPercent
              const point1 = { time: level.end, price }
              const point2 = { time: nextWave.end, price }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(155, 255, 155, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const waveTargetDelta = wave.waveTargetDeltaDown
            if (showTPRatio && waveTargetDelta) {
              const price = level.low + waveTargetDelta
              const point1 = { time: level.end, price }
              const point2 = { time: nextWave.end, price }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(15, 255, 205, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const clusterTargetsDown = wave.clusterTargetsDown
            if (showTPRatioClusters && clusterTargetsDown.length) {
              for (const target of clusterTargetsDown) {
                const price = level.low + target
                const point1 = { time: level.end, price }
                const point2 = { time: nextWave.end, price }
                const trend = new TrendLine(chart, series, point1, point2, {
                  lineColor: 'rgba(15, 205, 240, 0.8)',
                  width: 1,
                  showLabels: false,
                });
                series.attachPrimitive(trend);
              }
            }
          });
        }
      }
    }


  }


  try {
    series.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawWaves', error)
  }

  return series
}

export function drawBacktestInverseWaves(chart, waves, endTime, color = 'white', lineStyle = LineStyle.Solid, showIdx = false) {
  const series = chart.addLineSeries({
    color,
    lineWidth: 1,
    lineStyle,
    lastValueVisible: false,
    priceLineVisible: false,
  });

  // remove time dubs
  const seriesData = []
  const timesArr = []
  for (let i = 0; i < waves?.length; i++) {
    const wave = waves[i];
    if (!timesArr.includes(wave.start)) {
      seriesData.push({ time: wave.start, value: wave.low });
      timesArr.push(wave.start)
    }
    if (!timesArr.includes(wave.end)) {
      seriesData.push({ time: wave.end, value: wave.low });
      timesArr.push(wave.end)
    }
  }
  seriesData.sort((a, b) => a.time - b.time)


  for (let i = 0; i < waves?.length; i++) {
    const wave = waves[i];

    let opacity = 0.01
    let prefix = ''
    if (wave.newWave) {
      opacity = 0.01
      prefix = 'N '
    } else if (wave.lastUp) {
      opacity = 0.01
      prefix = 'U '
    } else if (wave.extremUp) {
      opacity = 0.3
      prefix = 'E '
    }

    const showWaves = true

    if (showWaves) {
      if (!wave.type || wave.type === 'move') {
        const delta = wave.high - wave.low
        if (wave.direction === 'up') {
          const deltaPercent = (delta / wave.low) * 100
          const percent = deltaPercent < 100
            ? deltaPercent.toPrecision(2)
            : deltaPercent.toPrecision(3)
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`
          const point1 = { time: wave.start, price: wave.low };
          const point2 = { time: wave.end, price: wave.high, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(0, 255, 0, ${opacity})`,
            width: 2,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(0, 255, 0, ${opacity})`
          });
          series.attachPrimitive(trend);
        } else {
          const deltaPercent = (delta / wave.high) * 100
          const percent = deltaPercent < 100
            ? deltaPercent.toPrecision(2)
            : deltaPercent.toPrecision(3)
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`
          const point1 = { time: wave.start, price: wave.high };
          const point2 = { time: wave.end, price: wave.low, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(255, 255, 0, ${opacity})`,
            width: 2,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(255, 255, 0, ${opacity})`
          });
          series.attachPrimitive(trend);
        }
      } else {
        const delta = wave.high - wave.low
        const deltaPercent = (delta / wave.high) * 100
        const percent = deltaPercent < 100
          ? deltaPercent.toPrecision(2)
          : deltaPercent.toPrecision(3)

        if (wave.direction === 'up') {
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''

          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`
          const point1 = { time: wave.start, price: wave.low };
          const point2 = { time: wave.end, price: wave.high, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(150, 220, 0, ${opacity})`,
            width: 1,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(150, 220, 0, ${opacity})`,
          });
          series.attachPrimitive(trend);
        } else {
          const lableIdx = showIdx ? `#${wave.idx || i} ` : ''
          const labelCluster = wave.cluster !== undefined ? ` (${wave.cluster})` : ''
          const label = `${prefix}${lableIdx}${percent}%${labelCluster}`

          const point1 = { time: wave.start, price: wave.high };
          const point2 = { time: wave.end, price: wave.low, label };
          const trend = new TrendLine(chart, series, point1, point2, {
            lineColor: `rgb(220, 150, 0, ${opacity})`,
            width: 1,
            showLabels: true,
            labelBackgroundColor: "rgba(255, 255, 255, 0)",
            labelTextColor: `rgb(220, 150, 0, ${opacity})`,
          });
          series.attachPrimitive(trend);
        }
      }
    }

    const showHighs = true
    const showLows = true
    const showLevels = true
    const showSL = true
    const showEnterStart = true
    const showTPPercent = true
    const showTPRatio = true
    const showTPRatioClusters = true

    if (i > 0 && i < waves.length - 1) {
      const windowTimeInCandles = 2
      const timeMult = 60

      const prevWave = waves[i - 1]
      const nextWave = waves[i + 1]
      const usedWave = wave

      // wave.newWave && 
      if (wave.direction === 'up') {
        if (showHighs && usedWave?.closestHighs?.length) {
          usedWave.closestHighs.forEach((level, i) => {
            const isTimeActive = level.start + windowTimeInCandles * timeMult < wave.end
            // if (!isTimeActive) return

            if (showLevels) {
              const point1 = { time: level.end, price: level.high }
              const point2 = { time: nextWave.end, price: level.high }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 255, 255, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const waveTargetDelta = wave.waveTargetDeltaUp
            if (showTPRatio && waveTargetDelta) {
              const price = level.high - waveTargetDelta
              const point1 = { time: level.end, price }
              const point2 = { time: nextWave.end, price }
              const trend = new TrendLine(chart, series, point1, point2, {
                lineColor: 'rgba(255, 88, 205, 0.99)',
                width: 1,
                showLabels: false,
              });
              series.attachPrimitive(trend);
            }

            const virtualLevel = waveTargetDelta ? level.high - waveTargetDelta : null
            if (virtualLevel) {

              const bounceSLPercentLong = usedWave?.bounceSLPercentLong?.optimal?.limit
              if (showSL && bounceSLPercentLong) {
                const sl = virtualLevel - virtualLevel * bounceSLPercentLong * 1.0
                const point1 = { time: level.end, price: sl }
                const point2 = { time: nextWave.end, price: sl }
                const trend = new TrendLine(chart, series, point1, point2, {
                  lineColor: 'rgba(255, 0, 0, 0.99)',
                  width: 1,
                  showLabels: false,
                });
                series.attachPrimitive(trend);
              }

              const bounceEnterLong = usedWave?.bounceEnterLong?.optimal?.limit
              if (showEnterStart && bounceEnterLong) {
                const slShift = bounceSLPercentLong ? virtualLevel * bounceSLPercentLong : Infinity
                const shift = Math.min(virtualLevel * bounceEnterLong, slShift)
                const enter = virtualLevel + shift
                const point1 = { time: level.end, price: enter }
                const point2 = { time: nextWave.end, price: enter }
                const trend = new TrendLine(chart, series, point1, point2, {
                  lineColor: 'rgba(255, 255, 0, 0.99)',
                  width: 1,
                  showLabels: false,
                });
                series.attachPrimitive(trend);
              }

              const waveTargetPercent = usedWave?.waveTargetPercent
              if (showTPPercent && waveTargetPercent) {
                const price = virtualLevel + virtualLevel * waveTargetPercent
                const point1 = { time: level.end, price }
                const point2 = { time: nextWave.end, price }
                const trend = new TrendLine(chart, series, point1, point2, {
                  lineColor: 'rgba(155, 255, 155, 0.99)',
                  width: 1,
                  showLabels: false,
                });
                series.attachPrimitive(trend);
              }
            }

            // const bounceSLPercentLong = usedWave?.bounceSLPercentLong?.optimal?.limit
            // if (showSL && bounceSLPercentLong) {
            //   const sl = level.high + level.high * bounceSLPercentLong
            //   const point1 = { time: level.end, price: sl }
            //   const point2 = { time: nextWave.end, price: sl }
            //   const trend = new TrendLine(chart, series, point1, point2, {
            //     lineColor: 'rgba(255, 0, 0, 0.99)',
            //     width: 1,
            //     showLabels: false,
            //   });
            //   series.attachPrimitive(trend);
            // }

            // const bounceEnterLong = usedWave?.bounceEnterLong?.optimal?.limit
            // if (showEnterStart && bounceEnterLong) {
            //   const enter = level.high - level.high * bounceEnterLong
            //   const point1 = { time: level.end, price: enter }
            //   const point2 = { time: nextWave.end, price: enter }
            //   const trend = new TrendLine(chart, series, point1, point2, {
            //     lineColor: 'rgba(255, 255, 0, 0.99)',
            //     width: 1,
            //     showLabels: false,
            //   });
            //   series.attachPrimitive(trend);
            // }

            // const waveTargetPercent = usedWave?.waveTargetPercent
            // if (showTPPercent && waveTargetPercent) {
            //   const price = level.high - level.high * waveTargetPercent
            //   const point1 = { time: level.end, price }
            //   const point2 = { time: nextWave.end, price }
            //   const trend = new TrendLine(chart, series, point1, point2, {
            //     lineColor: 'rgba(155, 255, 155, 0.99)',
            //     width: 1,
            //     showLabels: false,
            //   });
            //   series.attachPrimitive(trend);
            // }

            // const clusterTargetsUp = wave.clusterTargetsUp
            // if (showTPRatioClusters && clusterTargetsUp.length) {
            //   for (const target of clusterTargetsUp) {
            //     const price = level.high - target
            //     const point1 = { time: level.end, price }
            //     const point2 = { time: nextWave.end, price }
            //     const trend = new TrendLine(chart, series, point1, point2, {
            //       lineColor: 'rgba(15, 205, 240, 0.8)',
            //       width: 1,
            //       showLabels: false,
            //     });
            //     series.attachPrimitive(trend);
            //   }
            // }

          });
        }
      }

      // if (wave.direction === 'down') {
      //   if (showLows && usedWave?.closestLows?.length) {
      //     usedWave.closestLows.forEach((level, i) => {
      //       const isTimeActive = level.start + windowTimeInCandles * timeMult < wave.end
      //       if (!isTimeActive) return

      //       if (showLevels) {
      //         const point1 = { time: level.end, price: level.low }
      //         const point2 = { time: nextWave.end, price: level.low }
      //         const trend = new TrendLine(chart, series, point1, point2, {
      //           lineColor: 'rgba(255, 255, 255, 0.99)',
      //           width: 1,
      //           showLabels: false,
      //         });
      //         series.attachPrimitive(trend);
      //       }

      //       // const bounceSLPercentShort = usedWave?.bounceSLPercentShort?.optimal?.limit
      //       // if (showSL && bounceSLPercentShort) {
      //       //   const sl = level.low - level.low * bounceSLPercentShort
      //       //   const point1 = { time: level.end, price: sl }
      //       //   const point2 = { time: nextWave.end, price: sl }
      //       //   const trend = new TrendLine(chart, series, point1, point2, {
      //       //     lineColor: 'rgba(255, 0, 0, 0.99)',
      //       //     width: 1,
      //       //     showLabels: false,
      //       //   });
      //       //   series.attachPrimitive(trend);
      //       // }

      //       // const bounceEnterShort = usedWave?.bounceEnterShort?.optimal?.limit
      //       // if (showEnterStart && bounceEnterShort) {
      //       //   const enter = level.low + level.low * bounceEnterShort
      //       //   const point1 = { time: level.end, price: enter }
      //       //   const point2 = { time: nextWave.end, price: enter }
      //       //   const trend = new TrendLine(chart, series, point1, point2, {
      //       //     lineColor: 'rgba(255, 255, 0, 0.99)',
      //       //     width: 1,
      //       //     showLabels: false,
      //       //   });
      //       //   series.attachPrimitive(trend);
      //       // }

      //       // const waveTargetPercent = usedWave?.waveTargetPercent
      //       // if (showTPPercent && waveTargetPercent) {
      //       //   const price = level.low + level.low * waveTargetPercent
      //       //   const point1 = { time: level.end, price }
      //       //   const point2 = { time: nextWave.end, price }
      //       //   const trend = new TrendLine(chart, series, point1, point2, {
      //       //     lineColor: 'rgba(155, 255, 155, 0.99)',
      //       //     width: 1,
      //       //     showLabels: false,
      //       //   });
      //       //   series.attachPrimitive(trend);
      //       // }

      //       // const waveTargetDelta = wave.waveTargetDeltaDown
      //       // if (showTPRatio && waveTargetDelta) {
      //       //   const price = level.low + waveTargetDelta
      //       //   const point1 = { time: level.end, price }
      //       //   const point2 = { time: nextWave.end, price }
      //       //   const trend = new TrendLine(chart, series, point1, point2, {
      //       //     lineColor: 'rgba(15, 255, 205, 0.99)',
      //       //     width: 1,
      //       //     showLabels: false,
      //       //   });
      //       //   series.attachPrimitive(trend);
      //       // }

      //       // const clusterTargetsDown = wave.clusterTargetsDown
      //       // if (showTPRatioClusters && clusterTargetsDown.length) {
      //       //   for (const target of clusterTargetsDown) {
      //       //     const price = level.low + target
      //       //     const point1 = { time: level.end, price }
      //       //     const point2 = { time: nextWave.end, price }
      //       //     const trend = new TrendLine(chart, series, point1, point2, {
      //       //       lineColor: 'rgba(15, 205, 240, 0.8)',
      //       //       width: 1,
      //       //       showLabels: false,
      //       //     });
      //       //     series.attachPrimitive(trend);
      //       //   }
      //       // }
      //     });
      //   }
      // }
    }


  }


  try {
    series.setData(seriesData);
  } catch (error) {
    console.log('series.setData drawWaves', error)
  }

  return series
}

export function getBacktestTradesSeries(chart) {
  const series = []


  const buySeriesLong = chart.addLineSeries({
    color: 'rgba(0,255,0,0.9)',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
    axisLabelVisible: false,
    title: `BUY Long`,
  });
  series.push(buySeriesLong)

  const sellSeriesLong = chart.addLineSeries({
    color: 'rgba(255,0,0,0.9)',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
    axisLabelVisible: false,
    title: `SELL Long`,
  });
  series.push(sellSeriesLong)

  const buySeriesShort = chart.addLineSeries({
    color: 'rgba(0,255,0,0.9)',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
    axisLabelVisible: false,
    // title: `BUY`,
  });
  series.push(buySeriesShort)

  const sellSeriesShort = chart.addLineSeries({
    color: 'rgba(255,0,0,0.9)',
    lineWidth: 1,
    lineStyle: LineStyle.Solid,
    lastValueVisible: false,
    priceLineVisible: false,
    axisLabelVisible: false,
    // title: `SELL`,
  });
  series.push(sellSeriesShort)

  const buySeriesDataLong = [];
  const sellSeriesDataLong = [];
  const buySeriesDataShort = [];
  const sellSeriesDataShort = [];

  return {
    series,
    buySeriesLong,
    sellSeriesLong,
    buySeriesShort,
    sellSeriesShort,
    buySeriesDataLong,
    sellSeriesDataLong,
    buySeriesDataShort,
    sellSeriesDataShort,
  }
}

export function drawBacktestTrades(chart, opts, series2) {
  const { entryPoints, exitPointsShort, exitPointsLong } = opts

  if (!entryPoints?.bounce?.long?.length && !entryPoints?.bounce?.short?.length && !exitPointsLong?.length && !exitPointsShort?.length) return null

  console.log('drawBacktestTrades', entryPoints?.bounce?.short, exitPointsShort, entryPoints?.bounce?.long, exitPointsLong)

  const series = getBacktestTradesSeries(chart)
  const {
    buySeriesLong,
    sellSeriesLong,
    buySeriesShort,
    sellSeriesShort,
    buySeriesDataLong,
    sellSeriesDataLong,
    buySeriesDataShort,
    sellSeriesDataShort,
  } = series

  try {


    for (const point of entryPoints.bounce.long) {
      series.buySeriesDataLong.push(point);
      const color = point.isFirstPoint ? 'rgba(0,255,255,0.99)' : 'rgba(0,255,0,0.3)'

      const vertLine = new VertLine(chart, series.buySeriesLong, point.time, {
        labelText: 'Enter BLong',
        width: 1,
        showLabel: point.isFirstPoint,
        color,
        labelTextColor: 'white',
        labelBackgroundColor: 'rgba(0,255,0,0.3)',
      });
      series.buySeriesLong.attachPrimitive(vertLine);

      // const point1 = { time: point.time, price: point.value }
      // const point2 = { time: point.time, price: point.value, label: `${point.value}` }
      // const trend = new TrendLine(chart, series.buySeriesLong, point1, point2, {
      //   lineColor: 'rgba(0,255,0,0.3)',
      //   width: 1,
      //   showLabels: false,
      //   labelBackgroundColor: "rgba(255, 255, 255, 0)",
      //   labelTextColor: 'rgba(0,255,0,0.3)'
      // });
      // series.buySeriesLong.attachPrimitive(trend);

    }
    buySeriesLong.setData(buySeriesDataLong);

    for (const point of exitPointsLong) {
      series.sellSeriesDataLong.push(point);

      const color = point.closed ? 'rgba(255,0,255,0.99)' : 'rgba(255,0,0,0.3)'

      const vertLine = new VertLine(chart, series.sellSeriesLong, point.time, {
        labelText: 'Exit Long',
        width: 1,
        showLabel: point.closed,
        color,
        labelTextColor: 'white',
        labelBackgroundColor: 'rgba(255,0,0,0.3)',
      });
      series.sellSeriesLong.attachPrimitive(vertLine);
    }
    sellSeriesLong.setData(sellSeriesDataLong);


    for (const point of entryPoints.bounce.short) {
      sellSeriesDataShort.push(point);

      const vertLine = new VertLine(chart, sellSeriesShort, point.time, {
        // labelText: ev.type,
        width: 1,
        // showLabel: true,
        color: 'rgba(255,0,0,0.3)',
        // labelTextColor: 'white',
        // labelBackgroundColor: 'rgba(255,0,0,0.3)',
      });
      sellSeriesShort.attachPrimitive(vertLine);
    }
    sellSeriesShort.setData(sellSeriesDataShort);

    for (const point of exitPointsShort) {
      buySeriesDataShort.push(point);

      const vertLine = new VertLine(chart, buySeriesShort, point.time, {
        // labelText: ev.type,
        width: 1,
        // showLabel: true,
        color: 'rgba(0,255,0,0.3)',
        // labelTextColor: 'white',
        // labelBackgroundColor: 'rgba(0,255,0,0.3)',
      });
      buySeriesShort.attachPrimitive(vertLine);
    }
    buySeriesShort.setData(buySeriesDataShort);

  } catch (error) {

  }

  return series
}


// **********************************
//  levels
// **********************************

export function findKeyLevels(candles, minutesInCandle, atr, initAtr) {
  let highLevels = [];
  let lowLevels = [];
  let highStack = [];
  let lowStack = [];

  for (let i = 0; i < candles.length; i++) {
    const candle = candles[i]
    candle.atr = atr.nextValue(candle.open, candle.close, candle.high, candle.low) || initAtr

    while (highStack.length && candles[i].high > highLevels[highStack[highStack.length - 1]].level) {
      let idx = highStack.pop();
      highLevels[idx].duration = i - highLevels[idx].idx;
    }
    highStack.push(i);
    highLevels.push({ level: candles[i].high, duration: 0, minutesInCandle, startTime: candles[i].time, idx: i, atr: candle.atr });

    while (lowStack.length && candles[i].low < lowLevels[lowStack[lowStack.length - 1]].level) {
      let idx = lowStack.pop();
      lowLevels[idx].duration = i - lowLevels[idx].idx;
    }
    lowStack.push(i);
    lowLevels.push({ level: candles[i].low, duration: 0, minutesInCandle, startTime: candles[i].time, idx: i, atr: candle.atr });
  }

  // console.log('total candles', candles.length)
  while (highStack.length) {
    const idx = highStack.pop();
    let duration = candles.length - highLevels[idx].idx;
    highLevels[idx].duration = duration;
    highLevels[idx].unbeat = true;
    // console.log('unbeat high', idx, highLevels[idx].level, highLevels[idx].duration)
  }

  while (lowStack.length) {
    let idx = lowStack.pop();
    let duration = candles.length - lowLevels[idx].idx;
    lowLevels[idx].duration = duration;
    lowLevels[idx].unbeat = true;
    // console.log('unbeat low', idx, lowLevels[idx].level, lowLevels[idx].duration)
  }

  return { highLevels, lowLevels };
}

export function filterKeyLevels({ highLevels, lowLevels }, windowSize, touchesAtrMult) {
  const filterLevels = (levels, isHigh) => {
    let filteredLevels = [];

    for (let i = windowSize; i < levels.length; i++) {
      let isExtremum = true;
      for (let j = 1; j <= windowSize; j++) {
        if ((isHigh && levels[i - j].level >= levels[i].level) || (!isHigh && levels[i - j].level <= levels[i].level)) {
          isExtremum = false;
          break;
        }
      }

      if (isExtremum && levels[i].duration > 1) {
        levels[i].touches = 1; // Начальное касание уровня
        const delta = levels[i]?.atr * touchesAtrMult || 0
        const threshold = isHigh ? levels[i].level - delta : levels[i].level + delta;

        // Подсчет касаний с момента создания уровня
        for (let k = i + 1; k < levels.length; k++) {
          if ((isHigh && levels[k].level > levels[i].level) || (!isHigh && levels[k].level < levels[i].level)) {
            break; // Прерываем подсчет, если уровень пробит
          } else if ((isHigh && levels[k].level >= threshold) || (!isHigh && levels[k].level <= threshold)) {
            if (k > i + 1) levels[i].touches++;
          }
        }

        filteredLevels.push(levels[i]);
      }
    }

    return filteredLevels;
  };

  return {
    highLevels: filterLevels(highLevels, true),
    lowLevels: filterLevels(lowLevels, false)
  };
}

export function filterKeyLevels2({ highLevels, lowLevels }, minWindowSize, maxWindowSize, atrMult, percentage, maxIdx, useLast) {
  const importantHighLevels = filterImportantLevels(highLevels, minWindowSize, maxWindowSize);
  const importantLowLevels = filterImportantLevels(lowLevels, minWindowSize, maxWindowSize);

  const highLevels2 = importantHighLevels.map((level) => ({ ...level, type: 'resistance' }));
  const lowLevels2 = importantLowLevels.map((level) => ({ ...level, type: 'support' }));

  const allLevels = [...highLevels2, ...lowLevels2];

  // console.log('allLevels', allLevels);

  return findSimilarLevels(allLevels, atrMult, percentage, useLast);
}


export function filterImportantLevels(levels, minWindowSize, maxWindowSize) {
  const beatenLevels = levels.filter(lvl => !lvl.unbeat).length;
  let avgDuration = levels.reduce((acc, level) => acc + (level.unbeat ? 0 : level.duration), 0) / beatenLevels;
  avgDuration = Math.min(avgDuration, maxWindowSize);
  avgDuration = Math.max(avgDuration, minWindowSize);
  return levels.filter(lvl => (lvl.unbeat && lvl.duration > minWindowSize) || lvl.duration > avgDuration);
}

export function findSimilarLevels(levels, atrMult, percentage, useLast) {
  levels.sort((a, b) => a.level - b.level);
  // Initialize lowerBound and upperBound for each level
  levels.forEach(level => {
    const lowerBound = level.level - level.atr * atrMult || level.level * (1 - percentage);
    const upperBound = level.level + level.atr * atrMult || level.level * (1 + percentage);

    level.lowerBound = lowerBound // level.level * (1 - percentage);
    level.upperBound = upperBound // level.level * (1 + percentage);
  });

  let merged = true;

  while (merged) {
    merged = false;
    const result = [];
    for (let i = 0; i < levels.length - 1; i++) {
      const current = levels[i];
      const next = levels[i + 1];

      if (Math.abs(current.level - next.level) / current.level <= percentage) {
        const mergedLevel = mergeLevels(current, next, useLast);

        result.push(mergedLevel);
        i++;
        merged = true;  // Signal that a merge occurred
      } else {
        result.push(current);
      }
    }

    // Handle the last level separately
    if (levels.length > 0) {
      const last = levels[levels.length - 1];
      if (result.length > 0 && Math.abs(last.level - result[result.length - 1].level) / last.level <= percentage) {
        const mergedLevel = mergeLevels(result.pop(), last, useLast);

        result.push(mergedLevel);
        merged = true;  // Signal that a merge occurred
      } else {
        result.push(last);
      }
    }

    levels = result;  // Update levels for the next round
  }

  return levels;
}

export function mergeLevels(current, next, useLast) {
  const isBeatenByNext = current.idx + current.duration === next.idx;
  const isBeatenByCurrent = next.idx + next.duration === current.idx;
  const mergeTouches = isBeatenByNext || isBeatenByCurrent;

  const currentTouches = (!current.unbeat && !mergeTouches)
    ? 0 : current.touches ? current.touches : 1;
  const nextTouches = (!next.unbeat && !mergeTouches)
    ? 0 : next.touches ? next.touches : 1;

  let last = next
  if (next.idx < current.idx) {
    last = current
  }
  const lastTouches = (!last.unbeat && !mergeTouches)
    ? 0 : last.touches ? last.touches : 1;

  const mergedLevels = [...(current.mergedLevels || [current]), ...(next.mergedLevels || [next])]
  mergedLevels.sort((a, b) => a.startTime - b.startTime)
  last = mergedLevels[mergedLevels.length - 1]

  let mergedLevel
  if (useLast) {
    mergedLevel = {
      level: last.level,
      lastLevel: last.lastLevel || last.level,
      duration: current.lastDuration || current.duration + next.duration || next.lastDuration,
      lastDuration: last.lastDuration || last.duration,
      startTime: last.startTime,
      touches: lastTouches,
      mergedTouches: currentTouches + nextTouches,
      lowerBound: last.lowerBound,
      upperBound: last.upperBound,
      mergedLevels,
    }
  } else {
    mergedLevel = {
      level: (current.level + next.level) / 2,
      lastLevel: last.lastLevel || last.level,
      duration: current.lastDuration || current.duration + next.duration || next.lastDuration,
      lastDuration: last.lastDuration || last.duration,
      startTime: Math.min(current.startTime, next.startTime),
      touches: lastTouches,
      mergedTouches: currentTouches + nextTouches,
      lowerBound: Math.min(current.lowerBound, next.lowerBound),
      upperBound: Math.max(current.upperBound, next.upperBound),
      mergedLevels,
    }
  }

  if (current.unbeat || next.unbeat) mergedLevel.unbeat = true;
  if (current.type === next.type) {
    mergedLevel.type = current.type;
  } else {
    mergedLevel.type = last.type
    mergedLevel.mtype = 'double';
  }
  // console.log('mergeLevels', current, next, mergedLevel);
  return mergedLevel;
}

// fibo 

export function getFibonacciLevels(levels) {
  const fibonacciLevels = []
  const fibLevels = [0.236, 0.382, 0.5, 0.618, 0.786, 1.618] // 0.236, 0.786, 1.618, 2, 2.618, 4.236]

  for (let i = 0; i < levels.length; i++) {
    const lvl1 = levels[i]
    const price1 = lvl1.level
    const type1 = lvl1.type

    for (let j = i + 1; j < levels.length; j++) {
      const lvl2 = levels[j]
      const price2 = lvl2.level
      const type2 = lvl2.type
      if (price1 === price2) {
        // console.log('price1 === price2', price1, price2)
        continue
      }
      if (type1 === type2 && (type1 !== "double" && type2 !== "double")) {
        // console.log('type1 === type2', type1, type2)
        continue
      }

      const priceDiff = Math.abs(price1 - price2)
      const minPrice = Math.min(price1, price2)
      const maxPrice = Math.max(price1, price2)

      // для нисходящего тренда
      // Уровень Фибоначчи = минимальная цена + (максимальная цена ーминимальная цена) * уровень Фибоначчи
      // для восходящего тренда
      // Уровень Фибоначчи = максимальная цена ー (максимальная цена ーминимальная цена) * уровень Фибоначчи
      for (const mult of fibLevels) {
        const levelUp = { level: minPrice + priceDiff * mult, type: "fibo", mult, lvl1: lvl1.level, lvl2: lvl2.level }
        const levelDn = { level: maxPrice - priceDiff * mult, type: "fibo", mult, lvl1: lvl1.level, lvl2: lvl2.level }
        fibonacciLevels.push(levelUp)
        fibonacciLevels.push(levelDn)
      }
    }
  }

  const filtered = []
  // remove duplicates
  fibonacciLevels.forEach(lvl1 => {
    if (!filtered.some(lvl2 => lvl1.lvl1 === lvl2.lvl1 && lvl1.lvl2 === lvl2.lvl2 && lvl1.level === lvl2.level)) {
      filtered.push(lvl1);
    }
  });

  // fibonacciLevels.sort((a, b) => a.level - b.level)
  // filtered.sort((a, b) => a.level - b.level)

  // console.log('fibonacciLevels filtered', filtered)

  const similar = findSimilarFiboLevels(filtered, similarLevelPercent, true)

  // console.log('fibonacciLevels similar', similar)

  return similar
}

export function findSimilarFiboLevels(levels, percentage) {
  levels.sort((a, b) => a.level - b.level);
  // Initialize lowerBound and upperBound for each level
  levels.forEach(level => {
    level.lowerBound = level.level * (1 - percentage);
    level.upperBound = level.level * (1 + percentage);
  });

  let merged = true;

  while (merged) {
    merged = false;
    const result = [];
    for (let i = 0; i < levels.length - 1; i++) {
      const current = levels[i];
      const next = levels[i + 1];

      if (Math.abs(current.level - next.level) / current.level <= percentage) {
        const mergedLevel = mergeFibo(current, next)

        result.push(mergedLevel);
        i++;
        merged = true;  // Signal that a merge occurred
      } else {
        result.push(current);
      }
    }

    // Handle the last level separately
    if (levels.length > 0) {
      const last = levels[levels.length - 1];
      if (result.length > 0 && Math.abs(last.level - result[result.length - 1].level) / last.level <= percentage) {
        const mergedLevel = mergeFibo(result.pop(), last)

        result.push(mergedLevel);
        merged = true;  // Signal that a merge occurred
      } else {
        result.push(last);
      }
    }

    levels = result;  // Update levels for the next round
  }

  return levels;
}

export function mergeFibo(current, next) {
  const mergedLevels = [...(current.mergedLevels || [current]), ...(next.mergedLevels || [next])]
  mergedLevels.sort((a, b) => a.startTime - b.startTime)

  const mergedLevel = {
    type: 'fibo',
    level: (current.level + next.level) / 2,
    lowerBound: Math.min(current.lowerBound, next.lowerBound),
    upperBound: Math.max(current.upperBound, next.upperBound),
    mergedLevels,
  };

  return mergedLevel;
}


// **********************************
//  Wave Stats
// **********************************

// ***********************************************
// Statistics & Utils
// ***********************************************


export function calculateStatistics(v, useEMA = false, period = simpleStatsEmaLength) {
  if (!v.length) return { avg: 0, median: 0, min: 0, max: 0, stdDev: 0, slope: 0, intercept: 0 };

  const values = v.map(val => val || 0);

  const sum = values.reduce((acc, val) => acc + val, 0);
  const avg = sum / values.length;

  // Линейная регрессия
  const xBar = (values.length - 1) / 2;
  let num = 0;
  let den = 0;
  values.forEach((y, x) => {
    num += (x - xBar) * (y - avg);
    den += (x - xBar) * (x - xBar);
  });

  const slope = num / den;
  const intercept = avg - slope * xBar;

  let emaValues, emaStats
  if (useEMA) {
    emaValues = calculateEMA(values, period);
    emaStats = calculateStatistics(emaValues);
  }

  values.sort((a, b) => a - b);
  const median = values.length % 2 === 0 ? (values[values.length / 2 - 1] + values[values.length / 2]) / 2 : values[Math.floor(values.length / 2)];
  const min = values[0];
  const max = values[values.length - 1];
  const variance = values.reduce((acc, val) => acc + Math.pow(val - avg, 2), 0) / values.length;
  const stdDev = Math.sqrt(variance);

  if (useEMA) {
    return {
      count: values.length,
      avg, median, min, max, stdDev, slope, intercept,
      ema: emaStats
    };
  } else {
    return {
      count: values.length,
      avg, median, min, max, stdDev, slope, intercept
    };
  }
}

export function getSimpleWaveStats(waves) {
  const moveWaves = waves.filter(wave => !wave.type || wave.type === 'move')
  const pullbackWaves = waves.filter(wave => wave.type === 'pullback')

  const upWaves = waves.filter(wave => wave.direction === 'up')
  const downWaves = waves.filter(wave => wave.direction === 'down')

  const moveUpWaves = moveWaves.filter(wave => wave.direction === 'up')
  const moveDownWaves = moveWaves.filter(wave => wave.direction === 'down')
  const pullbackUpWaves = pullbackWaves.filter(wave => wave.direction === 'up')
  const pullbackDownWaves = pullbackWaves.filter(wave => wave.direction === 'down')

  const stats = {
    wavesNumber: waves.length,
    duration: waves.map(wave => (wave.end - wave.start) / waveDurationMult), // Продолжительность в минутах
    delta: waves.map(wave => wave.delta),
    deltaPercent: waves.map(wave => wave.deltaPercent),
    // 
    upWavesNumber: upWaves.length,
    upDuration: upWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    upDelta: upWaves.map(wave => wave.delta),
    upDeltaPercent: upWaves.map(wave => wave.deltaPercent),
    // 
    downWavesNumber: downWaves.length,
    downDuration: downWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    downDelta: downWaves.map(wave => wave.delta),
    downDeltaPercent: downWaves.map(wave => wave.deltaPercent),
    // 
    moveWavesNumber: moveWaves.length,
    moveDuration: moveWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    moveDelta: moveWaves.map(wave => wave.delta),
    moveDeltaPercent: moveWaves.map(wave => wave.deltaPercent),
    //
    pullbackWavesNumber: pullbackWaves.length,
    pullbackDuration: pullbackWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    pullbackDelta: pullbackWaves.map(wave => wave.delta),
    pullbackDeltaPercent: pullbackWaves.map(wave => wave.deltaPercent),
    //
    moveUpWavesNumber: moveUpWaves.length,
    moveUpDuration: moveUpWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    moveUpDelta: moveUpWaves.map(wave => wave.delta),
    moveUpDeltaPercent: moveUpWaves.map(wave => wave.deltaPercent),
    //
    moveDownWavesNumber: moveDownWaves.length,
    moveDownDuration: moveDownWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    moveDownDelta: moveDownWaves.map(wave => wave.delta),
    moveDownDeltaPercent: moveDownWaves.map(wave => wave.deltaPercent),
    //
    pullbackUpWavesNumber: pullbackUpWaves.length,
    pullbackUpDuration: pullbackUpWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    pullbackUpDelta: pullbackUpWaves.map(wave => wave.delta),
    pullbackUpDeltaPercent: pullbackUpWaves.map(wave => wave.deltaPercent),
    //
    pullbackDownWavesNumber: pullbackDownWaves.length,
    pullbackDownDuration: pullbackDownWaves.map(wave => (wave.end - wave.start) / waveDurationMult),
    pullbackDownDelta: pullbackDownWaves.map(wave => wave.delta),
    pullbackDownDeltaPercent: pullbackDownWaves.map(wave => wave.deltaPercent),
  };

  return {
    overall: {
      count: stats.wavesNumber,
      duration: calculateStatistics(stats.duration, true),
      delta: calculateStatistics(stats.delta, true),
      deltaPercent: calculateStatistics(stats.deltaPercent, true),
      up: {
        count: stats.upWavesNumber,
        duration: calculateStatistics(stats.upDuration, true),
        delta: calculateStatistics(stats.upDelta, true),
        deltaPercent: calculateStatistics(stats.upDeltaPercent, true),
      },
      down: {
        count: stats.downWavesNumber,
        duration: calculateStatistics(stats.downDuration, true),
        delta: calculateStatistics(stats.downDelta, true),
        deltaPercent: calculateStatistics(stats.downDeltaPercent, true),
      },
    },
    move: {
      count: stats.moveWavesNumber,
      duration: calculateStatistics(stats.moveDuration, true),
      delta: calculateStatistics(stats.moveDelta, true),
      deltaPercent: calculateStatistics(stats.moveDeltaPercent, true),
      up: {
        count: stats.moveUpWavesNumber,
        duration: calculateStatistics(stats.moveUpDuration, true),
        delta: calculateStatistics(stats.moveUpDelta, true),
        deltaPercent: calculateStatistics(stats.moveUpDeltaPercent, true),
      },
      down: {
        count: stats.moveDownWavesNumber,
        duration: calculateStatistics(stats.moveDownDuration, true),
        delta: calculateStatistics(stats.moveDownDelta, true),
        deltaPercent: calculateStatistics(stats.moveDownDeltaPercent, true),
      },
    },
    pullback: {
      count: stats.pullbackWavesNumber,
      duration: calculateStatistics(stats.pullbackDuration, true),
      delta: calculateStatistics(stats.pullbackDelta, true),
      deltaPercent: calculateStatistics(stats.pullbackDeltaPercent, true),
      up: {
        count: stats.pullbackUpWavesNumber,
        duration: calculateStatistics(stats.pullbackUpDuration, true),
        delta: calculateStatistics(stats.pullbackUpDelta, true),
        deltaPercent: calculateStatistics(stats.pullbackUpDeltaPercent, true),
      },
      down: {
        count: stats.pullbackDownWavesNumber,
        duration: calculateStatistics(stats.pullbackDownDuration, true),
        delta: calculateStatistics(stats.pullbackDownDelta, true),
        deltaPercent: calculateStatistics(stats.pullbackDownDeltaPercent, true),
      },
    },
  };

}

export function calculateEMA(values, period) {
  const smoothingFactor = 2 / (period + 1);
  const emaValues = [];
  let prevEMA = values[0]; // Начальное значение EMA равно первому значению

  for (let i = 1; i < values.length; i++) {
    const currentEMA = (values[i] * smoothingFactor) + (prevEMA * (1 - smoothingFactor));
    emaValues.push(currentEMA);
    prevEMA = currentEMA;
  }

  return emaValues;
}


export function getWaveContinuation(waves, candles) {
  let hasNewCandles = false
  let highUpdated = false
  let lowUpdated = false
  let waveUpdated = false
  let isNewWave = false

  const newCandles = []
  if (!waves.length || !candles.length) {
    return {
      hasNewCandles,
      highUpdated,
      lowUpdated,
      newCandles,
    }
  }

  const prevWave = waves[waves.length - 2]
  const lastWave = waves[waves.length - 1]
  let high = lastWave.high
  let low = lastWave.low
  let end = lastWave.end
  let waveType = lastWave.type

  for (let i = candles.length - 1; i >= 0; i--) {
    const candle = candles[i]
    if (candle.time >= lastWave.end) {
      if (candle.high > high) {
        high = candle.high
        end = candle.time
        highUpdated = true

        if (prevWave && candle.high > prevWave.high) {
          waveType = 'move'
        }

        if (lastWave.direction === 'up') {
          waveUpdated = true
        } else {
          isNewWave = true
        }
      }
      if (candle.low < low) {
        low = candle.low
        end = candle.time
        lowUpdated = true

        if (prevWave && candle.low < prevWave.low) {
          waveType = 'move'
        }

        if (lastWave.direction === 'down') {
          waveUpdated = true
        } else {
          isNewWave = true
        }
      }
      hasNewCandles = true
      newCandles.unshift(candle)
    } else {
      break
    }
  }

  return {
    hasNewCandles,
    isNewWave,
    waveUpdated,
    highUpdated,
    lowUpdated,
    newCandles,
    type: waveType,
    high,
    low,
    end,
  }
}

// ***********************************************
// Wave Ratio Stats
// ***********************************************

export function getWaveRatioStats(waves, field = 'delta') { // check results with deltaPercent
  const moveCohort = [1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7];
  const pullbackCohort = [0, 0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1];

  const tempInit = () => {
    return {
      any: [],
      up: [],
      down: [],
      moveUp: [],
      moveDown: [],
      pullbackUp: [],
      pullbackDown: []
    }
  }

  const temp = {
    move: tempInit(),
    pullback: tempInit(),
  }

  for (let i = 0; i < waves.length - 1; i++) {
    const wave = waves[i]
    const nextWave = waves[i + 1]
    const ratio = nextWave[field] / wave[field]

    temp[nextWave.type].any.push(ratio)

    if (wave.direction === 'up') {
      temp[nextWave.type].up.push(ratio)

      if (wave.type === 'move') {
        temp[nextWave.type].moveUp.push(ratio)
      } else if (wave.type === 'pullback') {
        temp[nextWave.type].pullbackUp.push(ratio)
      }
    } else if (wave.direction === 'down') {
      temp[nextWave.type].down.push(ratio)

      if (wave.type === 'move') {
        temp[nextWave.type].moveDown.push(ratio)
      } else if (wave.type === 'pullback') {
        temp[nextWave.type].pullbackDown.push(ratio)
      }
    }

  }

  // console.log('temp getWaveRatioStats', temp)

  const results = { move: {}, pullback: {} }
  for (const category in temp) {
    const cohort = category === 'move' ? moveCohort : pullbackCohort

    for (const key in temp[category]) {
      // const distribution = cohort.reduce((acc, p) => ({ ...acc, [p]: 0 })); 
      const distribution = {}
      for (const p of cohort) {
        distribution[p] = 0
      }

      for (const ratio of temp[category][key]) {
        let closestP = cohort[0]; // Начинаем с первого элемента в cohort
        let minDistance = Math.abs(ratio - closestP); // Начальное минимальное расстояние

        // Ищем ближайший элемент в cohort
        for (const p of cohort) {
          const distance = Math.abs(ratio - p);
          if (distance < minDistance) {
            minDistance = distance;
            closestP = p;
          }
        }

        // Увеличиваем счетчик для ближайшего элемента
        distribution[closestP] += 1 / temp[category][key].length;
      }

      const singleMaxExtendEpsilonSteps = 5
      const singleMaxNoisePercent = 0.15

      const values = temp[category][key]
      const basicStats = calculateStatistics(values, true)

      let clusters
      let clustersIterations = []
      let epsilon = 0.02
      const minPointsPercent = 0.02

      for (let i = 0; i < singleMaxExtendEpsilonSteps; i++) {
        const clustersIter = clusterizeDbscan(values, epsilon, minPointsPercent)
        clustersIterations.push({
          epsilon,
          clusters: clustersIter
        })

        if (clustersIter.noise.length / values.length < singleMaxNoisePercent) {
          clusters = clustersIter
          break
        } else {
          epsilon *= 2
        }
      }

      if (!clusters) {
        clusters = clustersIterations[clustersIterations.length - 1].clusters
      }

      // const clusters = clusterizeDbscan(values, 0.05, 0.05)

      results[category][key] = {
        frequency: values.length / waves.length,
        stats: basicStats,
        clusters,
        distribution,
        values,
        clustersIterations
      }

      // console.log('results', category, 'after', key, results[category][key])
    }
  }

  return results
}

// sorted version
export function clusterizeDbscan(values, epsilon = 0.05, minPointsPercent = 0.05) {
  const clustering = dbscan({
    dataset: values,
    epsilon,
    minimumPoints: Math.max(values.length * minPointsPercent, 2),
    // distanceFunction: (a, b) => Math.abs(a.created - b.created)
  });

  // console.log("Clusters dbscan:", clustering);

  const mappedClusters = clustering.clusters.map(cluster =>
    cluster.map(index => values[index])
  );

  const noise = clustering.noise.map(index => values[index]);

  // console.log("Clusters dbscan mapped:", mappedClusters, 'noise', noise);

  const clustersStats = mappedClusters.map(cluster => calculateStatistics(cluster));

  // Создаем массив объектов для сортировки
  const combined = clustersStats.map((stat, i) => ({
    stat,
    cluster: clustering.clusters[i],
    mappedCluster: mappedClusters[i]
  }));

  // Сортируем по возрастанию avg
  combined.sort((a, b) => a.stat.avg - b.stat.avg);

  // Извлекаем отсортированные массивы
  const sortedClusters = combined.map(item => item.cluster);
  const sortedMappedClusters = combined.map(item => item.mappedCluster);
  const sortedStats = combined.map(item => item.stat);

  return {
    dbscan: { ...clustering, clusters: sortedClusters },
    clusters: sortedMappedClusters,
    stats: sortedStats,
    noise
  };
}


export function clusterizeKmeans(values) {
  // Функция для нормализации данных
  function normalize(data) {
    let mean = data.reduce((sum, value) => sum + value, 0) / data.length;
    let std = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
    return data.map(value => [(value - mean) / std]);
  }

  // Нормализация данных
  let normalized = values.map(value => [value]); //normalize(values);
  console.log('clusterize initial:', values, "normalized:", normalized);

  function getInertias(data) {
    let inertias = [];
    const kMax = Math.min(10, data.length);
    for (let k = 1; k <= kMax; k++) {
      let result = kmeans(data, k);
      let inertia = 0;
      for (let i = 0; i < data.length; i++) {
        const point = data[i];
        const centroid = result.centroids[result.clusters[i]];
        const distance = Math.pow(point[0] - centroid[0], 2);
        inertia += distance;
      }
      inertias.push(inertia);
    }
    return inertias
  }


  // Вывод значений инерции для разных количеств кластеров
  const inertias = getInertias(normalized);
  console.log("Inertias:", inertias);

  // Кластеризация
  let clusters = kmeans(normalized, 3);

  console.log("Clusters:", clusters);

  function remapClusters(clusters) {
    // Создаем карту сортировки центроидов
    const sortedCentroidIndexes = clusters.centroids
      .map((value, index) => ({ index, value: value[0] })) // Используем value[0], так как центроиды у вас вложенные массивы
      .sort((a, b) => a.value - b.value)
      .map((centroid, newIndex) => ({ oldIndex: centroid.index, newIndex }));

    console.log("sortedCentroidIndexes:", sortedCentroidIndexes);
    // Создаем объект для ремапа
    const remap = sortedCentroidIndexes.reduce((acc, curr) => {
      acc[curr.oldIndex] = curr.newIndex;
      return acc;
    }, {});

    console.log("Map for remapping clusters:", remap, clusters);
    // Применение ремапа к вашим кластерам
    const remappedClusters = clusters.clusters.map(cluster => remap[cluster]);

    // console.log("Remapped clusters:", remappedClusters);
    return remappedClusters
  }

  const remappedClusters = remapClusters(clusters);

  // Вывод результатов
  console.log("Clusters remapped:", remappedClusters);

  return remappedClusters
}

// ***********************************************
// Breakouts & Bounces
// ***********************************************

export function getWaveExtremStats(waves) {
  const highStack = [];
  const lowStack = [];

  const results = {
    // BREAKOUTS
    cascades: {
      any: [],
      up: [],
      down: [],
    },
    // single level
    single: {
      up: {
        trueBreakouts: [],
        falseBreakouts: [],
        falseBreakouts1: [],
        falseBreakouts2: [],
      },
      down: {
        trueBreakouts: [],
        falseBreakouts: [],
        falseBreakouts1: [],
        falseBreakouts2: [],
      },
    },
    // PULLBACKS
    pullbacks: {
      any: [],
      up: [],
      down: [],
    }
  }

  for (let i = 0; i < waves.length; i++) {
    const wave = waves[i];
    // console.log('getWaveExtremStats', i, highStack, breakouts)

    if (wave.direction === 'up') {
      const breakCandidates = []
      let newHigh = null

      for (let j = highStack.length - 1; j >= 0; j--) {
        const closestHighIdx = highStack[j];
        const closestHigh = waves[closestHighIdx]

        if (wave.high > closestHigh.high) {
          const info = {
            prevIdx: closestHighIdx,
            newIdx: i,
            prevHigh: closestHigh.high,
            newHigh: wave.high
          }

          // false brakeout check 1
          if (i + 1 < waves.length - 1) {
            const nextWave = waves[i + 1]
            if (nextWave.low < closestHigh.high) {
              info.falseBreakout1 = true
            } else {
              info.falseBreakout1 = false
            }
          } else {
            info.falseBreakout1 = null
          }
          // false brakeout check 2
          if (i + 3 < waves.length - 1) {
            const nextWave = waves[i + 3]
            if (nextWave.low < closestHigh.high) {
              info.falseBreakout2 = true
            } else {
              info.falseBreakout2 = false
            }
          } else {
            info.falseBreakout2 = null
          }

          breakCandidates.push(info);

          if (info.falseBreakout1 || info.falseBreakout2) {
            results.single.up.falseBreakouts.push(info)
          }
          if (info.falseBreakout1) {
            results.single.up.falseBreakouts1.push(info)
          }
          if (!info.falseBreakout1 && info.falseBreakout2) {
            results.single.up.falseBreakouts2.push(info)
          }
          if (!info.falseBreakout1 && !info.falseBreakout2) {
            results.single.up.trueBreakouts.push(info)
          }

          highStack.pop();
          newHigh = i;
        }
      }

      if (highStack.length) {
        const prevHigh = waves[highStack[highStack.length - 1]].high

        const info = {
          prevIdx: highStack[highStack.length - 1],
          newIdx: i,
          prevHigh,
          newHigh: wave.high
        }

        results.pullbacks.down.push(info)
        results.pullbacks.any.push(info)
      }

      highStack.push(i);

      if (newHigh !== null) {
        const info = {
          direction: 'up',
          waveId: newHigh,
          beaten: breakCandidates.map(b => b.prevIdx),
          extra: breakCandidates
        }
        results.cascades.up.push(info)
        results.cascades.any.push(info)
        // console.log('getWaveExtremStats iteration', i, 'higher high', waves[i].high) //, 'highStack', highStack, breakouts)
      } else {
        // console.log('getWaveExtremStats iteration', i, 'lower high', waves[i].high) //, 'highStack', highStack, breakouts)
      }

    } else if (wave.direction === 'down') {
      const breakCandidates = []
      let newLow = null

      for (let j = lowStack.length - 1; j >= 0; j--) {
        const closestLowIdx = lowStack[j];
        const closestLow = waves[closestLowIdx]

        if (wave.low < closestLow.low) {
          const info = {
            prevIdx: closestLowIdx,
            newIdx: i,
            prevLow: closestLow.low,
            newLow: wave.low
          }

          // false brakeout check 1
          if (i + 1 < waves.length - 1) {
            const nextWave = waves[i + 1]
            if (nextWave.high > closestLow.low) {
              info.falseBreakout1 = true
            } else {
              info.falseBreakout1 = false
            }
          } else {
            info.falseBreakout1 = null
          }
          // false brakeout check 2
          if (i + 3 < waves.length - 1) {
            const nextWave = waves[i + 3]
            if (nextWave.high > closestLow.low) {
              info.falseBreakout2 = true
            } else {
              info.falseBreakout2 = false
            }
          } else {
            info.falseBreakout2 = null
          }

          breakCandidates.push(info);

          if (info.falseBreakout1 || info.falseBreakout2) {
            results.single.down.falseBreakouts.push(info)
          }
          if (info.falseBreakout1) {
            results.single.down.falseBreakouts1.push(info)
          }
          if (!info.falseBreakout1 && info.falseBreakout2) {
            results.single.down.falseBreakouts2.push(info)
          }
          if (!info.falseBreakout1 && !info.falseBreakout2) {
            results.single.down.trueBreakouts.push(info)
          }

          lowStack.pop();
          newLow = i;
        }
      }

      if (lowStack.length) {
        const info = {
          prevIdx: lowStack[lowStack.length - 1],
          newIdx: i,
          prevLow: waves[lowStack[lowStack.length - 1]].low,
          newLow: wave.low
        }
        results.pullbacks.up.push(info)
        results.pullbacks.any.push(info)
      }

      lowStack.push(i);

      if (newLow !== null) {
        const info = {
          direction: 'down',
          waveId: newLow,
          beaten: breakCandidates.map(b => b.prevIdx),
          extra: breakCandidates
        }
        results.cascades.down.push(info)
        results.cascades.any.push(info)
        // console.log('getWaveExtremStats iteration', i, 'lower low', waves[i].low) //, 'lowStack', lowStack, breakouts)
      } else {
        // console.log('getWaveExtremStats iteration', i, 'higher low', waves[i].low) //, 'lowStack', lowStack, breakouts)
      }
    }

  }

  // console.log("getWaveExtremStats results:", results)

  const singleStats = { up: {}, down: {} }
  const singleTemp = { up: {}, down: {} }

  const singleFields = [
    // {
    //   field: 'delta',
    //   epsilon: 0.05, // need to use coin min price step size calculations
    //   minPointsPercent: 0.05
    // },
    {
      field: 'deltaPercent',
      epsilon: 0.0005, // 0.05% ~ average market order fee
      minPointsPercent: 0.03
    },
    {
      field: 'prevRatio',
      epsilon: 0.01,
      minPointsPercent: 0.03
    },
    // {
    //   field: 'currentRatio',
    //   epsilon: 0.025,
    //   minPointsPercent: 0.03
    // },
    {
      field: 'lastRatio',
      epsilon: 0.01,
      minPointsPercent: 0.01
    }
  ]

  const singleMaxExtendEpsilonSteps = 5
  const singleMaxNoisePercent = 0.15

  // ****************************************************
  // brakeout stats
  // ****************************************************

  for (const key in results.single.up) {
    singleTemp.up[key] = []
    for (const result of results.single.up[key]) {
      const w1 = waves[result.prevIdx]
      const w2 = waves[result.newIdx]
      const wLast = waves[result.newIdx - 1]
      const delta = w2.high - w1.high
      const deltaPercent = delta / w1.high
      const prevRatio = delta / w1.delta
      const currentRatio = delta / w2.delta
      const lastRatio = delta / wLast.delta
      singleTemp.up[key].push({ delta, deltaPercent, prevRatio, currentRatio, lastRatio })
    }

    singleStats.up[key] = {}
    for (const variant of singleFields) {
      const values = singleTemp.up[key].map(item => item[variant.field])
      const basicStats = calculateStatistics(values, true)

      let clusters
      let clustersIterations = []
      let epsilon = variant.epsilon
      for (let i = 0; i < singleMaxExtendEpsilonSteps; i++) {
        const clustersIter = clusterizeDbscan(values, epsilon, variant.minPointsPercent)
        clustersIterations.push({
          epsilon,
          clusters: clustersIter
        })

        if (clustersIter.noise.length / values.length < singleMaxNoisePercent) {
          clusters = clustersIter
          break
        } else {
          epsilon *= 2
        }
      }

      if (!clusters) {
        clusters = clustersIterations[clustersIterations.length - 1].clusters
      }

      singleStats.up[key][variant.field] = {
        values,
        stats: basicStats,
        clusters,
        coverage: 1 - clusters.noise.length / values.length,
        clustersIterations
      }
    }
  }

  for (const key in results.single.down) {
    singleTemp.down[key] = []
    for (const result of results.single.down[key]) {
      const w1 = waves[result.prevIdx]
      const w2 = waves[result.newIdx]
      const wLast = waves[result.newIdx - 1]
      const delta = w1.low - w2.low
      const deltaPercent = delta / w1.low
      const prevRatio = delta / w1.delta
      const currentRatio = delta / w2.delta
      const lastRatio = delta / wLast.delta
      singleTemp.down[key].push({ delta, deltaPercent, prevRatio, currentRatio, lastRatio })
    }

    singleStats.down[key] = {}
    for (const variant of singleFields) {
      const values = singleTemp.down[key].map(item => item[variant.field])
      const basicStats = calculateStatistics(values, true)

      let clusters
      let clustersIterations = []
      let epsilon = variant.epsilon
      for (let i = 0; i < singleMaxExtendEpsilonSteps; i++) {
        const clustersIter = clusterizeDbscan(values, epsilon, variant.minPointsPercent)
        clustersIterations.push({
          epsilon,
          clusters: clustersIter
        })

        if (clustersIter.noise.length / values.length < singleMaxNoisePercent) {
          clusters = clustersIter
          break
        } else {
          epsilon *= 2
        }
      }

      if (!clusters) {
        clusters = clustersIterations[clustersIterations.length - 1].clusters
      }

      singleStats.down[key][variant.field] = {
        values,
        stats: basicStats,
        clusters,
        coverage: 1 - clusters.noise.length / values.length,
        clustersIterations
      }
    }
  }

  // console.log("getWaveExtremStats single temp:", singleTemp)
  // console.log("getWaveExtremStats single stats:", singleStats)

  // ****************************************************
  // bounce stats
  // ****************************************************

  const pullbackStats = { up: {}, down: {} }
  const pullbackTemp = { up: [], down: [] }

  const pullbackFields = [
    // {
    //   field: 'delta',
    //   epsilon: 0.05, // need to use coin min price step size calculations
    //   minPointsPercent: 0.05
    // },
    {
      field: 'deltaPercent',
      epsilon: 0.0001, // 0.05% ~ average market order fee
      minPointsPercent: 0.01
    },
    {
      field: 'prevRatio',
      epsilon: 0.01,
      minPointsPercent: 0.01
    },
    // {
    //   field: 'currentRatio',
    //   epsilon: 0.025,
    //   minPointsPercent: 0.01
    // },
    {
      field: 'lastRatio',
      epsilon: 0.01,
      minPointsPercent: 0.01
    }
  ]

  const pullbackMaxExtendEpsilonSteps = 5
  const pullbackMaxNoisePercent = 0.15

  for (const result of results.pullbacks.down) {
    const w1 = waves[result.prevIdx]
    const w2 = waves[result.newIdx]
    const delta = w1.high - w2.high
    const wLast = waves[result.newIdx - 1]
    const deltaPercent = delta / w1.high
    const prevRatio = delta / w1.delta
    const currentRatio = delta / w2.delta
    const lastRatio = delta / wLast.delta
    pullbackTemp.down.push({ delta, deltaPercent, prevRatio, currentRatio, lastRatio })
  }

  for (const result of results.pullbacks.up) {
    const w1 = waves[result.prevIdx]
    const w2 = waves[result.newIdx]
    const wLast = waves[result.newIdx - 1]
    const delta = w2.low - w1.low
    const deltaPercent = delta / w1.low
    const prevRatio = delta / w1.delta
    const currentRatio = delta / w2.delta
    const lastRatio = delta / wLast.delta
    pullbackTemp.up.push({ delta, deltaPercent, prevRatio, currentRatio, lastRatio })
  }

  for (const variant of pullbackFields) {
    const values = pullbackTemp.down.map(item => item[variant.field])
    const basicStats = calculateStatistics(values, true)

    let clusters
    let clustersIterations = []
    let epsilon = variant.epsilon
    for (let i = 0; i < pullbackMaxExtendEpsilonSteps; i++) {
      const clustersIter = clusterizeDbscan(values, epsilon, variant.minPointsPercent)
      clustersIterations.push({
        epsilon,
        clusters: clustersIter
      })

      if (clustersIter.noise.length / values.length < pullbackMaxNoisePercent) {
        clusters = clustersIter
        break
      } else {
        epsilon *= 2
      }
    }

    if (!clusters) {
      clusters = clustersIterations[clustersIterations.length - 1].clusters
    }

    pullbackStats.down[variant.field] = {
      values,
      stats: basicStats,
      clusters,
      coverage: 1 - clusters.noise.length / values.length,
      clustersIterations
    }
  }

  for (const variant of pullbackFields) {
    const values = pullbackTemp.up.map(item => item[variant.field])
    const basicStats = calculateStatistics(values, true)

    let clusters
    let clustersIterations = []
    let epsilon = variant.epsilon
    for (let i = 0; i < pullbackMaxExtendEpsilonSteps; i++) {
      const clustersIter = clusterizeDbscan(values, epsilon, variant.minPointsPercent)
      clustersIterations.push({
        epsilon,
        clusters: clustersIter
      })

      if (clustersIter.noise.length / values.length < pullbackMaxNoisePercent) {
        clusters = clustersIter
        break
      } else {
        epsilon *= 2
      }
    }

    if (!clusters) {
      clusters = clustersIterations[clustersIterations.length - 1].clusters
    }

    pullbackStats.up[variant.field] = {
      values,
      stats: basicStats,
      clusters,
      coverage: 1 - clusters.noise.length / values.length,
      clustersIterations
    }
  }

  // console.log("getWaveExtremStats pullbacks temp:", pullbackTemp)
  // console.log("getWaveExtremStats pullbacks stats:", pullbackStats)

  return {
    data: results,
    stats: {
      single: singleStats,
      pullback: pullbackStats,
    }
  }
}

export function getBounceSL(waveExtremStats, direction, alphaCoverage = 5, alphaLimit = 8, key = 'falseBreakouts1') {
  if (!waveExtremStats) return null

  const sideStats = waveExtremStats?.stats?.single[direction]
  const dp = sideStats?.[key]?.deltaPercent

  if (!dp) return null

  const total = dp.values.length
  const initOptions = []

  const stdDevMultipliers = [1, 1.5, 2, 3]
  // 0.683 - 1 sigma, 0.866 - 1.5 sigma, 0.954 - 2 sigma, 0.997 - 3 sigma
  for (const cluster of dp.clusters.stats) {
    for (const multiplier of stdDevMultipliers) {
      const newSL = cluster.avg + cluster.stdDev * multiplier
      const covered = dp.values.filter(val => val <= newSL).length
      const coverage = covered / total
      initOptions.push({
        coverage,
        limit: newSL,
        covered,
        stdDev: multiplier,
      })
    }
  }
  initOptions.sort((a, b) => a.coverage - b.coverage)

  const optimalLimit = findOptimalLimit(initOptions, alphaCoverage, alphaLimit)
  if (!optimalLimit) return null

  const { options, optimal } = optimalLimit

  return {
    total,
    options,
    optimal
  }
}

export function getBounceEnter(waveExtremStats, direction, alphaCoverage = 5, alphaLimit = 50) {
  if (!waveExtremStats) return null

  const sideStats = waveExtremStats?.stats?.pullback[direction]
  const dp = sideStats?.deltaPercent

  if (!dp) return null

  const total = dp.values.length
  const initOptions = []

  const stdDevMultipliers = [1, 1.5, 2, 3]
  // 0.683 - 1 sigma, 0.866 - 1.5 sigma, 0.954 - 2 sigma, 0.997 - 3 sigma
  for (const cluster of dp.clusters.stats) {
    for (const multiplier of stdDevMultipliers) {
      const newLimit = cluster.avg + cluster.stdDev * multiplier
      const covered = dp.values.filter(val => val <= newLimit).length
      const coverage = covered / total
      initOptions.push({
        coverage,
        limit: newLimit,
        covered,
        stdDev: multiplier,
      })
    }
  }
  initOptions.sort((a, b) => a.coverage - b.coverage)

  const optimalLimit = findOptimalLimit(initOptions, alphaCoverage, alphaLimit)
  if (!optimalLimit) return null

  const { options, optimal } = optimalLimit

  return {
    total,
    options,
    optimal,
  }
}

export function findOptimalLimit(options, alphaCoverage = 3, alphaLimit = 6) {
  if (options.length === 0) return null;

  const normalizedOptions = options.map(option => ({
    ...option,
    utilityCoverage: diminishingUtility(option.coverage, alphaCoverage),
    utilityLimit: diminishingUtility(option.limit, alphaLimit),
  }));

  let bestOption = normalizedOptions[0];
  let bestScore = -Infinity;

  for (const option of normalizedOptions) {
    const score = option.utilityCoverage - option.utilityLimit;
    option.score = score

    // console.log('findOptimalLimit', option, score);

    if (score > bestScore) {
      bestScore = score;
      bestOption = option;
    }
  }

  return {
    optimal: bestOption,
    options: normalizedOptions,
  }
}

export function findOptimalWaveWindow(options, alphaVolatility = 5, alphaDuration = 5) {
  if (options.length === 0) return null;

  const normalizedOptions = options.map(option => ({
    ...option,
    utilityVolatility: diminishingUtility(option.delta * 10, alphaVolatility),
    utilityDuration: diminishingUtility(option.duration * 10, alphaDuration),
  }));

  let bestOption = normalizedOptions[0];
  let bestScore = -Infinity;

  for (const option of normalizedOptions) {
    const score = option.utilityVolatility - option.utilityDuration;
    option.score = score

    // console.log('findOptimalWaveWindow', option, score);

    if (score > bestScore) {
      bestScore = score;
      bestOption = option;
    }
  }

  return {
    optimal: bestOption,
    options: normalizedOptions,
  }
}

export function diminishingUtility(x, alpha = 5) {
  return 1 - Math.exp(-alpha * x);
}

// ***********************************************

export function getWaveLevels(waves) {
  const highs = []
  const lows = []

  for (let i = 0; i < waves.length; i++) {
    const wave = waves[i];

    if (wave.direction === 'up') {
      for (let j = highs.length - 1; j >= 0; j--) {
        const closestHigh = highs[j];
        if (wave.high > closestHigh.high) {
          highs.pop()
        }
      }
      highs.push(wave)
    } else if (wave.direction === 'down') {
      for (let j = lows.length - 1; j >= 0; j--) {
        const closestLow = lows[j];
        if (wave.low < closestLow.low) {
          lows.pop()
        }
      }
      lows.push(wave)
    }
  }

  return {
    highs,
    lows,
  }
}

// ***********************************************

export const getSequenceStats = (data, maxLength = 4) => {
  const typeMapping = {};
  const directionMapping = {};
  let typeCounter = 0;
  let directionCounter = 0;

  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getTypeId = (type) => {
    if (!(type in typeMapping)) {
      typeMapping[type] = typeCounter++;
    }
    return typeMapping[type];
  };

  const getDirectionId = (direction) => {
    if (!(direction in directionMapping)) {
      directionMapping[direction] = directionCounter++;
    }
    return directionMapping[direction];
  };

  const stats = {};
  const totalSequences = Array(maxLength).fill(0);

  for (let i = 1; i < data.length; i++) {
    let sequence = [(getTypeId(data[i].type || 'none') << 8) | getDirectionId(data[i].direction)];

    for (let j = i + 1; j < data.length && sequence.length < maxLength; j++) {
      sequence.push((getTypeId(data[j].type || 'none') << 8) | getDirectionId(data[j].direction));
      const key = sequence.join('->');

      stats[key] = (stats[key] || 0) + 1;
      totalSequences[sequence.length - 1]++;
    }
  }

  const readableStats = Array.from({ length: maxLength }, () => []);
  for (const key in stats) {
    const sequence = key.split('->').map(code => {
      const typeId = code >> 8;
      const directionId = code & 0xFF;

      const type = Object.keys(typeMapping).find(key => typeMapping[key] === typeId);
      const direction = Object.keys(directionMapping).find(key => directionMapping[key] === directionId);

      return `${type}${capitalizeFirstLetter(direction || 'Any')}`;
    });

    const sequenceLength = sequence.length;
    const readableKey = sequence.join(' -> ');
    const frequency = stats[key] / totalSequences[sequenceLength - 1];
    readableStats[sequenceLength - 1].push({ sequence: readableKey, frequency });
  }

  readableStats.forEach(group => group.sort((a, b) => b.frequency - a.frequency));
  readableStats.shift(); // Удаляем первый элемент, содержащий пустую последовательность

  return readableStats;
};

export const predictSequence = (waves, stats) => {
  const maxLength = stats.length;
  const recentSequence = waves.slice(-maxLength);

  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const results = [];

  for (let i = 1; i <= recentSequence.length && i <= maxLength; i++) {
    const key = recentSequence
      .slice(-i)
      .map(wave => `${wave.type || 'none'}${capitalizeFirstLetter(wave.direction || 'Any')}`)
      .join(' -> ');

    if (stats[i - 1]) {
      const matches = stats[i - 1].filter(item => item.sequence.startsWith(key));
      if (matches.length > 0) {
        const totalFrequency = matches.reduce((sum, match) => sum + match.frequency, 0);

        const prediction = matches.map(match => {
          const nextWaveSequence = match.sequence.split(' -> ');
          const nextWave = nextWaveSequence[i];

          // Определение типа и направления на основе известных значений
          let nextType = 'none';
          let nextDirection = 'Unknown';

          if (nextWave.startsWith('move')) {
            nextType = 'move';
          } else if (nextWave.startsWith('pullback')) {
            nextType = 'pullback';
          }

          if (nextWave.endsWith('Up')) {
            nextDirection = 'up';
          } else if (nextWave.endsWith('Down')) {
            nextDirection = 'down';
          }

          return {
            nextWave: nextWave || 'Unknown',
            type: nextType,
            direction: nextDirection,
            probability: match.frequency / totalFrequency,
          };
        }).sort((a, b) => b.probability - a.probability);

        const result = {
          sequence: key,
          prediction
        }

        results.push(result);

        // console.log('predictSequence recentSequence', i, key, result.prediction[0])
      }
    }
  }

  return results;
};

// **********************************
//  Utils
// **********************************

export function getMinMaxLevelsAfterTime(levels, time) {
  let maxLevel = null;
  let minLevel = null;

  levels.forEach(level => {
    // если уровень содержит mergedLevels, берем наибольший startTime
    const startTime = level.mergedLevels
      ? Math.max(level.startTime, ...level.mergedLevels.map(l => l.startTime))
      : level.startTime;

    if (startTime >= time) {
      if (!maxLevel || level.level > maxLevel.level) {
        maxLevel = level;
      }
      if (!minLevel || level.level < minLevel.level) {
        minLevel = level;
      }
    }
  });

  return { minLevel, maxLevel };
}


// export function mergeLevels(current, next, useLast) {
//   const isBeatenByNext = current.idx + current.duration === next.idx;
//   const isBeatenByCurrent = next.idx + next.duration === current.idx;
//   const mergeTouches = isBeatenByNext || isBeatenByCurrent;

//   const currentTouches = (!current.unbeat && !mergeTouches)
//     ? 0 : current.touches ? current.touches : 1;
//   const nextTouches = (!next.unbeat && !mergeTouches)
//     ? 0 : next.touches ? next.touches : 1;

//   let mergedLevel
//   if (useLast) {
//     let last = next
//     if (next.idx < current.idx) {
//       last = current
//     }
//     mergedLevel = {
//       level: last.level,
//       duration: last.duration,
//       startTime: last.startTime,
//       touches: currentTouches + nextTouches,
//       lowerBound: last.lowerBound,
//       upperBound: last.upperBound,
//       mergedLevels: [...(current.mergedLevels || [current]), ...(next.mergedLevels || [next])]
//     }
//   } else {
//     mergedLevel = {
//       level: (current.level + next.level) / 2,
//       duration: current.duration + next.duration,
//       startTime: Math.min(current.startTime, next.startTime),
//       touches: currentTouches + nextTouches,
//       lowerBound: Math.min(current.lowerBound, next.lowerBound),
//       upperBound: Math.max(current.upperBound, next.upperBound),
//       mergedLevels: [...(current.mergedLevels || [current]), ...(next.mergedLevels || [next])]
//     }
//   }

//   if (current.unbeat || next.unbeat) mergedLevel.unbeat = true;
//   if (current.type === next.type) {
//     mergedLevel.type = current.type;
//   } else {
//     mergedLevel.type = 'double';
//   }
//   // console.log('mergeLevels', current, next, mergedLevel);
//   return mergedLevel;
// }




// **********************************
//  Pivots
// **********************************


export function Pivots(candleInterval, minuteCandles, chart) {
  const intervalMinutes = intervalToMinutes(candleInterval)
  const pivotMult = 60
  const pivotCandles = convertCandles(minuteCandles, 1, intervalMinutes * pivotMult)

  const pv = new Pivot('fibonacci') //'classic' | 'woodie' | 'camarilla' | 'fibonacci'
  const pivotShift = 60 * intervalMinutes * pivotMult
  let pivotsData = pivotCandles.map(c => {
    const value = pv.nextValue(c.high, c.low, c.close);
    return { time: c.time + pivotShift, value: value }
    //   {
    //     "time": 1686960000,
    //     "value": {
    //         "r3": 264.6066666666666,
    //         "r2": 257.2133333333333,
    //         "r1": 251.90666666666664,
    //         "pp": 244.51333333333332,
    //         "s1": 239.20666666666665,
    //         "s2": 231.81333333333333,
    //         "s3": 226.50666666666666
    //     }
    // }
  })

  // console.log('pivotsData', pivotsData)
  const pivotLevels = {}
  for (let i = 0; i < pivotsData.length; i++) {
    const period = pivotsData[i]
    const time = period.time
    const value = period.value

    for (let key in value) {
      pivotLevels[key] = pivotLevels[key] || []
      pivotLevels[key].push({ time, value: value[key] })
      if (i < pivotsData.length - 1) {
        pivotLevels[key].push({ time: pivotsData[i + 1].time - 1, value: value[key] })
      }
    }
  }
  console.log('pivotLevels', pivotLevels)

  for (const lvl in pivotLevels) {
    const series = chart.addLineSeries({
      color: getLevelColor(lvl),
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });

    try {
      series.setData(pivotLevels[lvl]);
    } catch (error) {
      console.log('series.setData pivotLevels', error)
    }
  }
}

export function getLevelColor(level) {
  switch (level) {
    case 'r3':
      return 'rgba(190, 90, 120, 0.7)'
    case 'r2':
      return 'rgba(190, 60, 120, 0.8)'
    case 'r1':
      return 'rgba(190, 30, 120, 0.9)'
    case 'pp':
      return 'rgba(30, 140, 10, 1)'
    case 's1':
      return 'rgba(90, 120, 190, 0.9)'
    case 's2':
      return 'rgba(90, 100, 190, 0.8)'
    case 's3':
      return 'rgba(90, 80, 190, 0.7)'
    default:
      return 'rgba(255, 255, 255, 0.5)'
  }
}

// **********************************
//  Extrems
// **********************************

export function FindExtrems(candles, start, end) {
  let hh = -Infinity;
  let ll = Infinity;
  let hhIndex = -1;
  let llIndex = -1;
  let lows = [];
  let highs = [];

  for (let i = start; i < end; i++) {
    const candle = candles[i];

    if (candle.high > hh) {
      hh = candle.high;
      hhIndex = i;
      highs = [i];
    } else if (candle.high === hh) {
      highs.push(i);
    }

    if (candle.low < ll) {
      ll = candle.low;
      llIndex = i;
      lows = [i];
    } else if (candle.low === ll) {
      lows.push(i);
    }
  }
  return { hh, ll, hhIndex, llIndex, lows, highs };
}

export function ExtremLevels(candles, chart, series, maxDepth = 1) {
  // Инициализация данных серии
  const seriesData = [];
  const markers = [];

  for (let depth = 0; depth < maxDepth; depth++) {
    let startIndex = 0;
    let endIndex = candles.length;
    let extrems = FindExtrems(candles, startIndex, endIndex)
    let { hh, ll, hhIndex, llIndex, lows, highs } = extrems
    console.log('extrems', depth, extrems)
    const intervals = [startIndex, endIndex]

    if (hhIndex !== -1 && !intervals.includes(hhIndex)) {
      intervals.push(hhIndex)
      const candle = candles[hhIndex]
      markers.push({
        time: candle.time,
        position: 'aboveBar',
        color: '#841111',
        shape: 'arrowDown',
        text: `H:${depth}`,
      })
    }
    if (llIndex !== -1 && !intervals.includes(llIndex)) {
      intervals.push(llIndex)
      const candle = candles[llIndex]
      markers.push({
        time: candle.time,
        position: 'belowBar',
        color: '#118411',
        shape: 'arrowUp',
        text: `L:${depth}`,
      })
    }
    intervals.sort((a, b) => a - b)
    console.log('intervals', depth, intervals)
  }


  // seriesData.sort((a, b) => a.time - b.time);
  // // Отрисовываем серии на графике
  // const series = chart.addLineSeries({
  //   color: 'red',
  //   lineWidth: 1,
  //   lineStyle: LineStyle.Solid,
  //   lastValueVisible: false,
  //   priceLineVisible: false,
  // });
  // series.setData(seriesData);
  series.setMarkers(markers);
}

export function getLatinLetterByIndex(index) {
  return String.fromCharCode(65 + index);
}

const ex = {
  // levels
  findKeyLevels,
  filterKeyLevels,
  filterKeyLevels2,
  filterImportantLevels,
  findSimilarLevels,
  mergeLevels,
  // waves
  getWaves,
  createWaveHierarchy,
  compressWaveCascades,
  // draw
  // draw levels
  drawKeyLevels,
  drawImportantLevels,
  getLevelColor,
  getImportantLevelColor,
  // draw waves
  drawLevelsLine,
  drawWaves,
  drawWavesHierarchy,
  drawWavesFibo,
  drawWaveFibo,
  drawFiboLines,
  // fibo
  getFibonacciLevels,
  findSimilarFiboLevels,
  mergeFibo,
  drawFiboLevels,
  // other
  Pivots,
  FindExtrems,
  ExtremLevels,
  // utils
  getMinMaxLevelsAfterTime,
  getLatinLetterByIndex,
}

export default ex
