
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Resizable } from 're-resizable';
import { useLocation } from "react-router-dom";

import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';

import {
  currentSymbolState,
  updateRateIntervalState,
  unixTimeState,
  tempCandleIntervalState,
  clusterCandleIntervalState,
  loadCandlesState,
  resampleOnServerState,
  resampleOnServerLevelState,
  recordsModeState,
} from '#state';

import {
  replayState,
  replaysListState,
  wsTimerState,
  timeMachineTimeState,
  candlesState,
  candlesH1State,
  candlesSelector,
  clustersState,
  clustersSelector,
  bookState,
  bookSelector,
  bookUpdatesState,
  bookUpdatesSelector,
  resampledUpdatesCache,
  exchangeInfoState,
  replayMetaState,
} from '#state/data';

import { TradeSimulator } from './TradeSimulator';
import { Backtest } from './Backtest2';
import { OrderBookList } from './charts/OrderBookList';

import { TWChart } from './charts/CandleChart';
import { TradesChart } from './charts/TradesChart';
import { ClustersChart } from './charts/ClustersChart';
import { IndicatorsChart } from './charts/IndicatorsChart';

import { tradeBuffers, orderBooks } from '#state/local';

import {
  getReplay,
  getReplaysList,
  getCandles,
  getCandlesBinance,
  getCandlesBinanceH1,
  getClusters,
  getOrderBook,
  getOrderBookUpdates,
  getExchangeInfo,
} from '../utils';
import { intervalToMinutes } from '#src/utils'

let maxDepth = 1000

export function Replay(props) {
  const location = useLocation();
  const locationParts = location.pathname.split('/');
  const id = locationParts.pop();
  const modeLocation = locationParts.pop();
  // const mode = useRecoilValue(recordsModeState);
  const updateRateInterval = useRecoilValue(updateRateIntervalState);
  // const setTimeMachineTime = useSetRecoilState(timeMachineTimeState);
  const [currentSymbol, setCurrentSymbol] = useRecoilState(currentSymbolState);
  // const exchangeInfo = useRecoilValue(exchangeInfoState);
  // const [startUnixTime, setStartUnixTime] = useRecoilState(unixTimeState);
  // const [loadCandles, setLoadCandles] = useRecoilState(loadCandlesState);
  // const clusterCandleInterval = useRecoilValue(clusterCandleIntervalState);
  // const tempCandleInterval = useRecoilValue(tempCandleIntervalState);
  const [replay, setReplay] = useRecoilState(replayState);
  const [replayMeta, setReplayMeta] = useRecoilState(replayMetaState);

  // const setClustersData = useSetRecoilState(clustersState);
  // const setBookData = useSetRecoilState(bookState);
  // const orderBook = useRecoilValue(bookSelector);
  // const setBookUpdatesData = useSetRecoilState(bookUpdatesState);
  // const orderBookUpdates = useRecoilValue(bookUpdatesSelector);
  // const [resampleOnServer, setResampleOnServer] = useRecoilState(resampleOnServerState);
  // const resampleOnServerLevel = useRecoilValue(resampleOnServerLevelState);
  const [wsTimer, setWsTimer] = useRecoilState(wsTimerState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const run = async () => {
      const newReplay = await getReplay({ id, mode: modeLocation });
      if (newReplay) {
        setCurrentSymbol(newReplay.symbol);
        setReplay(newReplay)
        setLoaded(true)
        console.log('replay', newReplay)
        console.log('replayMeta', replayMeta)
      }
    }
    run();
  }, [setReplay]);

  useEffect(() => {
    if (replay) {
      tradeBuffers.futures = replay.tradeBuffers.usdm
      tradeBuffers.spot = replay.tradeBuffers.spot
      // 
      if (replay.usdmBook) {
        orderBooks.futures.book.asks = new Map(replay.usdmBook.asks.slice(0, maxDepth));
        orderBooks.futures.book.bids = new Map(replay.usdmBook.bids.slice(0, maxDepth));
        orderBooks.futures.book.lastUpdateId = replay.usdmBook.lastUpdateId;
        orderBooks.futures.book.lastUpdateTime = replay.usdmBook.lastUpdateTime;
      } else {
        orderBooks.futures.book.asks = new Map();
        orderBooks.futures.book.bids = new Map();
      }

      let bufferTimestamp = 0;
      for (const msg of replay.usdmUpdates) {
        msg.a.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.futures.book.asks.delete(p);
          } else {
            orderBooks.futures.book.asks.set(p, q);
          }
        });

        msg.b.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.futures.book.bids.delete(p);
          } else {
            orderBooks.futures.book.bids.set(p, q);
          }
        });

        orderBooks.futures.book.lastUpdateId = msg.u;
        orderBooks.futures.book.lastUpdateTime = msg.T;

        if ((msg.T - bufferTimestamp) >= updateRateInterval) {
          orderBooks.futures.books.push({
            lastUpdateId: orderBooks.futures.book.lastUpdateId,
            lastUpdateTime: orderBooks.futures.book.lastUpdateTime,
            bids: cloneMap(orderBooks.futures.book.bids, maxDepth),
            asks: cloneMap(orderBooks.futures.book.asks, maxDepth),
          });
          bufferTimestamp = msg.T;
        }
      }

      // 
      if (replay.spotBook) {
        orderBooks.spot.book.asks = new Map(replay.spotBook.asks.slice(0, maxDepth));
        orderBooks.spot.book.bids = new Map(replay.spotBook.bids.slice(0, maxDepth));
        orderBooks.spot.book.firstUpdateId = replay.spotBook.firstUpdateId;
        orderBooks.spot.book.lastUpdateId = replay.spotBook.lastUpdateId;
        orderBooks.spot.book.lastUpdateTime = replay.spotBook.lastUpdateTime;
      } else {
        orderBooks.spot.book.asks = new Map();
        orderBooks.spot.book.bids = new Map();
      }

      bufferTimestamp = 0;
      for (const msg of replay.spotUpdates) {
        msg.a.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.spot.book.asks.delete(p);
          } else {
            orderBooks.spot.book.asks.set(p, q);
          }
        });

        msg.b.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.spot.book.bids.delete(p);
          } else {
            orderBooks.spot.book.bids.set(p, q);
          }
        });

        orderBooks.spot.book.firstUpdateId = msg.U;
        orderBooks.spot.book.lastUpdateId = msg.u;
        orderBooks.spot.book.lastUpdateTime = msg.E;

        if ((msg.E - bufferTimestamp) >= updateRateInterval) {
          orderBooks.spot.books.push({
            lastUpdateId: orderBooks.spot.book.lastUpdateId,
            lastUpdateTime: orderBooks.spot.book.lastUpdateTime,
            bids: cloneMap(orderBooks.spot.book.bids, maxDepth),
            asks: cloneMap(orderBooks.spot.book.asks, maxDepth),
          });
          bufferTimestamp = msg.E;
        }
      }
      setWsTimer(replay.time);
      // setStartUnixTime(replay.startUnixTime);
      // setLoadCandles(replay.loadCandles);
    }
  }, [replay, updateRateInterval]);

  let showMeta = replayMeta ? {
    action: replayMeta.action,
    strategy: replayMeta.strategy,
    direction: replayMeta.direction,
    reason: replayMeta.reason,
    price: replayMeta.price,
    openPrice: replayMeta.openPrice,
    closePrice: replayMeta.closePrice,
    delayedLvlPrice: replayMeta.delayedLvlPrice,
    history: replayMeta.history,
  } : null

  if (replay?.event) {
    showMeta = replay.event
  }


  if (!loaded) {
    return <Box sx={{
      mt: 10,
      fontSize: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh'
    }}>
      <CircularProgress />
    </Box>
  }

  return (
    <>
      {/* <Box sx={{ mt: 1, fontSize: '1rem' }}>
        <Box>BufferStart Time: {replay ? new Date(replay.tradeBuffers.usdm[0].T).toISOString() : ''}</Box>
        {showMeta ? (
          <Box>
            <Box>Action: {showMeta.action}</Box>
            <Box>Strategy: {showMeta.strategy}</Box>
            <Box>Direction: {showMeta.direction}</Box>
            <Box>Reason: {showMeta.reason}</Box>
            <Box>Lvl Price: {showMeta.delayedLvlPrice}</Box>
            <Box>Last Weighted Price: {showMeta.price}</Box>
            <Box>Open Price: {showMeta.openPrice}</Box>
            <Box>Close Price: {showMeta.closePrice}</Box>
          </Box>
        ) : null}
      </Box> */}

      <CandleLoader />
      <ClustersLoader />

      <Box sx={{ mt: 1, fontSize: '1rem', display: 'flex' }}>
        <Backtest replay={replay} />
      </Box>
      
      {/* <Box sx={{ mt: 1, fontSize: '1rem', display: 'flex' }}>
        <TradeSimulator replay={replay} />
      </Box> */}

      {/* <Box sx={{ mt: 1, fontSize: '1rem' }}>
        <OrderBookList replay={true} replayData={replay} />
      </Box>

      <Box sx={{ mt: 1, mb: 2 }}>
        <TradesChart replay={true} replayData={replay} />
      </Box>

      <Box sx={{ mt: 1, mb: 2 }}>
        <IndicatorsChart replay={true} replayData={replay} />
      </Box>

      <Box sx={{ mt: 1, mb: 1 }}>
        <ClustersChart replay={true} replayData={replay} />
      </Box> */}

      <Box sx={{}}>
        <TWChart replay={true} replayData={replay} />
      </Box>
    </>
  );
}

export default Replay;

function CandleLoader() {
  const setTimeMachineTime = useSetRecoilState(timeMachineTimeState);
  const currentSymbol = useRecoilValue(currentSymbolState);
  const exchangeInfo = useRecoilValue(exchangeInfoState);
  const tempCandleInterval = useRecoilValue(tempCandleIntervalState);
  const replay = useRecoilValue(replayState);
  const setCandlesData = useSetRecoilState(candlesState);
  const setCandlesH1Data = useSetRecoilState(candlesH1State);

  useEffect(() => {
    const run = async () => {
      const symbol = currentSymbol;
      const startTime = replay.tradeBuffers.usdm[0].T
      setTimeMachineTime(startTime);
      // let from = startTime - 1000 * 60 * 60 * 24 * 5; // 10k min is ~ 1 week (10080)
      const symbolInfo = exchangeInfo.symbols.find((s) => s.symbol === symbol)
      // if (symbolInfo.onboardDate > from) {
      //   from = symbolInfo.onboardDate;
      // }
      // const to = Math.min(startTime + 1000 * 60 * 60, Date.now());
      // const interval = '1m';
      // const sharedOpts = { from, to, symbol, interval };
      // await getCandlesBinance({
      //   ...sharedOpts,
      //   setCandlesData
      // });

      let m1StartShiftBack = 1000 * 60 * 60 * 24 * 2;
      switch (tempCandleInterval) {
        case '1m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 2;
          break;
        case '5m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 4;
          break;
        case '15m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 6;
          break;
        case '30m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 8;
          break;
        default:
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 10;
          break;
      }

      let m1Start = Math.floor(startTime / 24 / 60 / 60 / 1000) * 24 * 60 * 60 * 1000
      m1Start = m1Start - m1StartShiftBack;

      const toH1 = Math.floor(m1Start / 24 / 60 / 60 / 1000) * 24 * 60 * 60 * 1000
      let fromH1 = toH1 - 1000 * 60 * 60 * 24 * 40 * 2; // 960 1h candles is ~ 40 days (1 batch load)

      if (symbolInfo.onboardDate > fromH1) {
        fromH1 = symbolInfo.onboardDate;
      }

      let from = toH1 //startTime - 1000 * 60 * 60 * 24 * 7; // 10k min is ~ 1 week (10080)

      if (symbolInfo.onboardDate > from) {
        from = symbolInfo.onboardDate;
      }
      const intervalMinutes = intervalToMinutes(tempCandleInterval)
      const endM1 = Math.min(startTime + 1000 * 60 * intervalMinutes * 960, Date.now());
      const to = endM1;
      const interval = '1m';
      const sharedOpts = { from, to, symbol, interval };
      await getCandlesBinance({
        ...sharedOpts,
        setCandlesData
      });

      await getCandlesBinanceH1({
        symbol,
        interval: '1h',
        from: fromH1,
        to: toH1,
        setCandlesH1Data
      });
    }

    if (exchangeInfo && replay) {
      run();
    }
  }, [replay, currentSymbol, tempCandleInterval, exchangeInfo, setCandlesData, setCandlesH1Data, setTimeMachineTime]);

  return null;
}

function ClustersLoader() {
  const currentSymbol = useRecoilValue(currentSymbolState);
  const startUnixTime = useRecoilValue(unixTimeState);
  const loadCandles = useRecoilValue(loadCandlesState);
  const clusterCandleInterval = useRecoilValue(clusterCandleIntervalState);
  const replay = useRecoilValue(replayState);

  const setClustersData = useSetRecoilState(clustersState);

  useEffect(() => {
    const run = async () => {
      const intervalMinutes = intervalToMinutes(clusterCandleInterval)
      const totalCandles = Math.floor(loadCandles / intervalMinutes);
      const candlesLeft = Math.floor(totalCandles / 2);
      const candlesRight = Math.ceil(totalCandles / 2);
      const from = replay.tradeBuffers.usdm[0].T - 1000 * 60 * intervalMinutes * candlesLeft;
      const to = replay.tradeBuffers.usdm[0].T + 1000 * 60 * intervalMinutes * candlesRight;
      const symbol = currentSymbol;
      const interval = clusterCandleInterval;
      const opts = { from, to, symbol, interval, setClustersData };

      await getClusters(opts);
    }
    if (replay) {
      run();
    }
  }, [replay, currentSymbol, loadCandles, startUnixTime, clusterCandleInterval, setClustersData]);

  return null
}


function cloneMap(original, maxDepth = 1000) {
  return new Map(Array.from(original, ([key, value]) => [key, value]).slice(0, maxDepth));
}