import { atom, selector } from 'recoil';
// import * as d3 from "d3";
import { getCandleTimes, getMinuteStart, getSecondStart } from '../utils';
import { joinCandles, convertCandles, intervalToMinutes } from '#src/utils'
import {
  percentile,
  groupPrices,
  groupUpdates,
  getSamplesFromUpdates
} from './utils';
import {
  currentFactorState,
  bookUpdatesResampleState,
  resampleOnServerState,
} from './index.js';

import {
  mainCandleIntervalState,
  tempCandleIntervalState,
} from './index';

const url = new URL('../workers/bookUpdatesResampler.js', import.meta.url);
const resampleWorker = new window.Worker(url);
resampleWorker.onerror = (err) => {
  console.error('bookUpdatesResampler worker error', err);
};
// resampleWorker.onmessage = (e) => {
//   const resampledUpdates = e.data;
//   console.log('worker resampledUpdates', resampledUpdates)
// };

export const serverSettingState = atom({
  key: 'serverSettingState',
  default: null,
});

export const replayMetaState = atom({
  key: 'replayMetaState',
  default: null,
});

export const timeMachineTimeState = atom({
  key: 'timeMachineTimeState',
  default: null,
});

export const timeMachineCandleTimeState = atom({
  key: 'timeMachineCandleTimeState',
  default: null,
});

export const wsTimerState = atom({
  key: 'wsTimerState',
  default: 0,
});

export const exchangeInfoState = atom({
  key: 'exchangeInfoState',
  default: null,
});

export const replayState = atom({
  key: 'replayState',
  default: null,
});

export const replaysListState = atom({
  key: 'replaysListState',
  default: [],
});
export const replaysLastSeenId = atom({
  key: 'replaysLastSeenId',
  default: null,
});

export const statsListState = atom({
  key: 'statsListState',
  default: [],
});
export const statsLastSeenId = atom({
  key: 'statsLastSeenId',
  default: null,
});

export const tradesState = atom({
  key: 'tradesState',
  default: [],
});

export const tradesSelector = selector({
  key: 'tradesSelector',
  get: ({ get }) => {
    const trades = get(tradesState);
    // const now = Date.now();
    // const start = getSecondStart(now);
    // const filtered = trades.filter(trade => trade.T <= start);
    // return filtered;
    return trades;
  },
});

// **********************************************
// Candles
// **********************************************
export const candlesH1State = atom({
  key: 'candlesH1State',
  default: [],
});

export const candlesH1Selector = selector({
  key: 'candlesH1Selector',
  get: ({ get }) => {
    const candlesData = get(candlesH1State);
    const data = candlesData.map(candle => {
      return {
        time: Math.floor(candle.startTime / 1000),
        close: parseFloat(candle.close),
        open: parseFloat(candle.open),
        high: parseFloat(candle.high),
        low: parseFloat(candle.low),
        volume: parseFloat(candle.volume),
      }
    })
    data.sort((a, b) => a.time - b.time);
    return data;
  },
});

export const mainCandlesSelector = selector({
  key: 'mainCandlesSelector',
  get: ({ get }) => {
    const candlesH1 = get(candlesH1Selector);
    const mainCandlesInterval = get(mainCandleIntervalState);
    if (mainCandlesInterval === '1h') {
      return candlesH1;
    } else {
      const converted = convertCandles(candlesH1, 60, mainCandlesInterval)
      return converted
    }
  },
});

export const candlesState = atom({
  key: 'candlesState',
  default: [],
});

export const candlesSelector = selector({
  key: 'candlesSelector',
  get: ({ get }) => {
    const candlesData = get(candlesState);
    const data = candlesData.map(candle => {
      return {
        time: Math.floor(candle.startTime / 1000),
        close: parseFloat(candle.close),
        open: parseFloat(candle.open),
        high: parseFloat(candle.high),
        low: parseFloat(candle.low),
        volume: parseFloat(candle.volume),
      }
    })
    data.sort((a, b) => a.time - b.time);
    return data;
  },
});

export const tempCandlesSelector = selector({
  key: 'tempCandlesSelector',
  get: ({ get }) => {
    const candlesData = get(candlesSelector);
    const tempCandlesInterval = get(tempCandleIntervalState);
    if (tempCandlesInterval === '1m') {
      return candlesData;
    } else {
      const converted = convertCandles(candlesData, 1, tempCandlesInterval)
      return converted
    }
  },
});

export const candleUpdatesState = atom({
  key: 'candleUpdatesState',
  default: [],
});

export const candleUpdatesSelector = selector({
  key: 'candleUpdatesSelector',
  get: ({ get }) => {
    const candlesData = get(candleUpdatesState);
    const data = candlesData.map(candle => {
      return {
        time: Math.floor(candle.startTime / 1000),
        close: parseFloat(candle.close),
        open: parseFloat(candle.open),
        high: parseFloat(candle.high),
        low: parseFloat(candle.low),
        volume: parseFloat(candle.volume),
      }
    })
    data.sort((a, b) => a.time - b.time);
    return data;
  },
});

export const lastCandleUpdateState = atom({
  key: 'lastCandleUpdateState',
  default: null,
});

export const lastCandleUpdateSelector = selector({
  key: 'lastCandleUpdateSelector',
  get: ({ get }) => {
    const candle = get(lastCandleUpdateState);
    if (!candle) return null;
    return {
      time: Math.floor(candle.startTime / 1000),
      close: parseFloat(candle.close),
      open: parseFloat(candle.open),
      high: parseFloat(candle.high),
      low: parseFloat(candle.low),
      volume: parseFloat(candle.volume),
    }

  },
});

// **********************************************
// Clusters
// **********************************************
export const clustersState = atom({
  key: 'clustersState',
  default: [],
});

export const clustersSelector = selector({
  key: 'clustersSelector',
  get: ({ get }) => {
    const clustersData = get(clustersState);
    const currentFactor = get(currentFactorState);

    const data = clustersData.map(candle => {
      const groupedSummV = groupPrices(candle.summV, currentFactor)
      const groupedSummT = groupPrices(candle.summT, currentFactor)
      const groupedBuyV = groupPrices(candle.buyV, currentFactor)
      const groupedBuyT = groupPrices(candle.buyT, currentFactor)
      const groupedSellV = groupPrices(candle.sellV, currentFactor)
      const groupedSellT = groupPrices(candle.sellT, currentFactor)
      const groupedDeltaV = groupPrices(candle.deltaV, currentFactor)
      const groupedDeltaT = groupPrices(candle.deltaT, currentFactor)

      return {
        time: candle.time,
        totalV: candle.totalV,
        totalT: candle.totalT,
        totalDV: candle.totalDV,
        totalDT: candle.totalDT,
        summV: groupedSummV,
        summT: groupedSummT,
        buyV: groupedBuyV,
        buyT: groupedBuyT,
        sellV: groupedSellV,
        sellT: groupedSellT,
        deltaV: groupedDeltaV,
        deltaT: groupedDeltaT,
      }
    })
    return data;
  },
});



export const clustersAllSelector = selector({
  key: 'clustersAllSelector',
  get: ({ get }) => {
    const clustersData = get(clustersState);
    const currentFactor = get(currentFactorState);

    let agg = {
      totalV: 0, totalT: 0,
      totalDV: 0, totalDT: 0,
      summV: {}, summT: {},
      buyV: {}, buyT: {},
      sellV: {}, sellT: {},
      deltaV: {}, deltaT: {}
    };

    for (const candle of clustersData) {
      agg.totalV += candle.totalV
      agg.totalT += candle.totalT
      agg.totalDV += candle.totalDV
      agg.totalDT += candle.totalDT

      for (let lvl in candle.summV) {
        agg.summV[lvl] = agg.summV[lvl] ? agg.summV[lvl] + candle.summV[lvl] : candle.summV[lvl]
        agg.summT[lvl] = agg.summT[lvl] ? agg.summT[lvl] + candle.summT[lvl] : candle.summT[lvl]
        agg.deltaV[lvl] = agg.deltaV[lvl] ? agg.deltaV[lvl] + candle.deltaV[lvl] : candle.deltaV[lvl]
        agg.deltaT[lvl] = agg.deltaT[lvl] ? agg.deltaT[lvl] + candle.deltaT[lvl] : candle.deltaT[lvl]
      }

      for (let lvl in candle.buyV) {
        agg.buyV[lvl] = agg.buyV[lvl] ? agg.buyV[lvl] + candle.buyV[lvl] : candle.buyV[lvl]
        agg.buyT[lvl] = agg.buyT[lvl] ? agg.buyT[lvl] + candle.buyT[lvl] : candle.buyT[lvl]
      }

      for (let lvl in candle.sellV) {
        agg.sellV[lvl] = agg.sellV[lvl] ? agg.sellV[lvl] + candle.sellV[lvl] : candle.sellV[lvl]
        agg.sellT[lvl] = agg.sellT[lvl] ? agg.sellT[lvl] + candle.sellT[lvl] : candle.sellT[lvl]
      }
    }

    // use average price instead of weighted average price
    // to fix different prices in different groups
    const useAveragePrice = true
    const groupedSummV = groupPrices(agg.summV, currentFactor, useAveragePrice)
    const groupedSummT = groupPrices(agg.summT, currentFactor, useAveragePrice)
    const groupedBuyV = groupPrices(agg.buyV, currentFactor, useAveragePrice)
    const groupedBuyT = groupPrices(agg.buyT, currentFactor, useAveragePrice)
    const groupedSellV = groupPrices(agg.sellV, currentFactor, useAveragePrice)
    const groupedSellT = groupPrices(agg.sellT, currentFactor, useAveragePrice)
    const groupedDeltaV = groupPrices(agg.deltaV, currentFactor, useAveragePrice)
    const groupedDeltaT = groupPrices(agg.deltaT, currentFactor, useAveragePrice)

    return {
      totalV: agg.totalV,
      totalT: agg.totalT,
      totalDV: agg.totalDV,
      totalDT: agg.totalDT,
      summV: groupedSummV,
      summT: groupedSummT,
      buyV: groupedBuyV,
      buyT: groupedBuyT,
      sellV: groupedSellV,
      sellT: groupedSellT,
      deltaV: groupedDeltaV,
      deltaT: groupedDeltaT,
    }


    // const trades = {}
    // for (const candle of clustersData) {
    //   for (const price in candle.summV) {
    //     if (!trades[price]) {
    //       trades[price] = candle.summV[price]
    //     } else {
    //       trades[price] += candle.summV[price]
    //     }
    //   }
    // }

    // // remove errors and noise
    // const grouped = groupPrices(trades, currentFactor)
    // const avgVolume = Object.values(grouped).reduce((a, b) => a + b, 0) / Object.keys(grouped).length;
    // for (const price in grouped) {
    //   if (grouped[price] < avgVolume / 1000) {
    //     delete grouped[price]
    //   }
    // }

    // return grouped;
  },
});


export const bookState = atom({
  key: 'bookState',
  default: [],
});
export const bookSelector = selector({
  key: 'bookSelector',
  get: ({ get }) => {
    const bookData = get(bookState);
    const aggRes = bookData.map(c => {
      const res = {
        time: c.time,
        asks: {},
        bids: {},
        bestAsk: c.asks[c.asks.length - 1].p,
        bestBid: c.bids[0].p,
      }
      for (const lvl of c.asks) {
        res.asks[lvl.p] = lvl.q
      }
      for (const lvl of c.bids) {
        res.bids[lvl.p] = lvl.q
      }

      return res
    })

    return aggRes
  },
});

export const bookUpdatesState = atom({
  key: 'bookUpdatesState',
  default: [],
});

export let resampledUpdatesCache = []
export const bookUpdatesSelector = selector({
  key: 'bookUpdatesSelector',
  get: async ({ get }) => {
    const resample = get(bookUpdatesResampleState);
    const resampleActive = get(resampleOnServerState);
    const bookUpdatesData = get(bookUpdatesState);

    console.log('bookUpdatesData', bookUpdatesData)
    if (resampleActive && !resample) return bookUpdatesData

    const resampledUpdates = await work(resampleWorker, {
      bookUpdatesData,
      resample,
    });
    resampledUpdatesCache = resampledUpdates
    return resampledUpdates
  },
});

function work(worker, message) {
  return new Promise((resolve, reject) => {
    const listener = (event) => {
      worker.removeEventListener('message', listener);
      resolve(event.data);
    };
    worker.addEventListener('message', listener);
    worker.postMessage(message);
  });
}