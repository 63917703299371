
import React, { useEffect, useRef, useState } from 'react';
import {
  createChart,
  CrosshairMode,
  LineStyle,
} from 'lightweight-charts';
import { Resizable } from 're-resizable';
import localforage from 'localforage';
import { kmeans } from 'ml-kmeans';
import dbscan from '@cdxoo/dbscan'
import {
  EMA,
  SMMA,
  Extremums,
  Pivot,
  ATR,
  PSAR,
  AO,
  AC,
  Stochastic,
  StochasticRSI,
  RSI,
  CCI,
  ROC,
  DC,
  cRSI,
  ADX,
  ChaikinOscillator,
} from '@debut/indicators';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  candlesState,
  candlesSelector,
  tempCandlesSelector,
  candlesH1Selector,
  mainCandlesSelector,
  candleUpdatesSelector,
  lastCandleUpdateState,
  lastCandleUpdateSelector,
  clustersState,
  clustersSelector,
  bookState,
  bookSelector,
  bookUpdatesState,
  bookUpdatesSelector,
  resampledUpdatesCache,
  timeMachineCandleTimeState,
} from '#state/data';
import {
  currentSymbolState,
  mainCandleIntervalState,
  mainWindowSizeState,
  mainFilter2DurationState,
  mainF1PercentState,
  mainF2PercentState,
  tempCandleIntervalState,
  tempWindowSizeState,
  tempFilter2DurationState,
  tempF1PercentState,
  tempF2PercentState,
} from '#state';

import {
  candleChartHeightState,
  candleChartWidthState,
} from '#state/gui';

import { joinCandles, convertCandles, intervalToMinutes } from '#src/utils'

import { TrendLine } from './plugins/TrendLine';
import { VertLine } from './plugins/VertLine';
import { UserPriceAlerts } from './plugins/UserPriceAlerts';

import {
  // levels
  findKeyLevels,
  filterKeyLevels,
  filterKeyLevels2,
  filterImportantLevels,
  findSimilarLevels,
  mergeLevels,
  // waves
  getWaves,
  createWaveHierarchy,
  compressWaveCascades,
  // draw
  // draw levels
  drawKeyLevels,
  drawImportantLevels,
  getLevelColor,
  getImportantLevelColor,
  // draw waves
  drawLevelsLine,
  drawWaves,
  drawPredictedWavesLine,
  drawPredictedWaves,
  drawWavesHierarchy,
  drawWavesFibo,
  // fibo
  getFibonacciLevels,
  findSimilarFiboLevels,
  mergeFibo,
  drawFiboLevels,
  // trend lines
  getWavesTrendLines,
  drawWavesTrendLines,
  // other
  Pivots,
  // wave stats
  calculateStatistics,
  getSimpleWaveStats,
  getWaveRatioStats,
  getWaveExtremStats,
  getBounceSL,
  getBounceEnter,
  findOptimalWaveWindow,
  getWaveLevels,
  getSequenceStats,
  predictSequence,
  getWaveContinuation,
  drawBacktestWaves,
  drawBacktestInverseWaves,
  drawBacktestTrades,
  getBacktestTradesSeries,
} from './CandleUtils';

// *************************************************
// view config
// *************************************************

// levels
const showRecordLevels = true
const showLevels = false
const LEVEL_HIDE_DISTANCE = 0.2 // 10% from the last close price
const ATR_LENGTH = 14
const ATR_MAIN_LENGTH = 14
const ATR_TEMP_LENGTH = 14
// waves
const showZigZag = false
const showHighLowChannels = false
const showWaves = false
const showWavesTrendLines = true
const showCorrectionBox = true
const showWavesHierarchy = false
const showInitialWavesHierarchy = false
const showInitialWavesCascades = false
const showBacktestWaves = true
const showBacktestTrades = false
// indexes
const maxNestingToShowLabels = 10 // 0 = off
const showWavesHierarchyIdx = false
const showWaveIdx = false
const showWaveCascadeIdx = false
// wavesFibo
const showWaveFiboRetracement = false
const showWaveFiboMain = false
const showWaveFiboTemp = true
const maxWaveFiboNesting = 3
// 
//other 
const showPivots = false
const simpleStatsEmaLength = 10

const minimalCorrelation = 0.2
const minimalSoloCorrelation = 0.2
const minimalPairsCorrelation = 0.3
const minimalTripletsCorrelation = 0.4

const indicatorsConfig = [
  {
    keyPrefix: 'ATR',
    indicator: ATR,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['high', 'low', 'close'],
    argsMoment: ['high', 'low'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'EMA',
    indicator: EMA,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['avg4'],
    argsMoment: ['avg4'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'VolEMA',
    indicator: EMA,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['volume'],
    argsMoment: ['volume'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'SMMA',
    indicator: SMMA,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['avg4'],
    argsMoment: ['avg4'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'AO',
    indicator: AO,
    variants: [[3, 21], [5, 34], [9, 42], [14, 64], [21, 89], [42, 144]],
    argsValue: ['high', 'low'],
    argsMoment: ['high', 'low'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'AC',
    indicator: AC,
    variants: [undefined],
    argsValue: ['high', 'low'],
    argsMoment: ['high', 'low'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'Stochastic',
    indicator: Stochastic,
    variants: [[3, 21], [5, 34], [9, 42], [14, 64], [21, 89], [42, 144]],
    argsValue: ['high', 'low', 'close'],
    argsMoment: ['high', 'low', 'close'],
    resultKey: 'value',
    resultSubKeys: ['k', 'd'],
  },
  {
    keyPrefix: 'StochasticRSI',
    indicator: StochasticRSI,
    variants: [[3, 3, 14, 14]],
    argsValue: ['close'],
    argsMoment: ['close'],
    resultKey: 'value',
    resultSubKeys: ['k', 'd', 'stochRsi'],
  },
  {
    keyPrefix: 'RSI_close',
    indicator: RSI,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['close'],
    argsMoment: ['close'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'RSI_avg4',
    indicator: RSI,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['avg4'],
    argsMoment: ['avg4'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'CCI',
    indicator: CCI,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['high', 'low', 'close'],
    argsMoment: ['high', 'low', 'close'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'ROC_close',
    indicator: ROC,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['close'],
    argsMoment: ['close'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'ROC_avg4',
    indicator: ROC,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['avg4'],
    argsMoment: ['avg4'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'DC',
    indicator: DC,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['high', 'low'],
    argsMoment: ['high', 'low'],
    resultKey: 'value',
    resultSubKeys: ['upper', 'middle', 'lower'],
  },
  {
    keyPrefix: 'cRSI_avg4',
    indicator: cRSI,
    variants: [[3, 2, 100]],
    argsValue: ['avg4'],
    argsMoment: ['avg4'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'cRSI_close',
    indicator: cRSI,
    variants: [[3, 2, 100]],
    argsValue: ['close'],
    argsMoment: ['close'],
    resultKey: 'value',
  },
  {
    keyPrefix: 'ADX',
    indicator: ADX,
    variants: [3, 5, 9, 14, 21, 42, 89, 144],
    argsValue: ['high', 'low', 'close'],
    argsMoment: ['high', 'low', 'close'],
    resultKey: 'value',
    resultSubKeys: ['adx', 'pdi', 'mdi'],
  },
  {
    keyPrefix: 'ChaikinOscillator',
    indicator: ChaikinOscillator,
    variants: [[2, 7], [3, 10], [7, 42], [14, 89]],
    argsValue: ['high', 'low', 'close', 'volume'],
    argsMoment: ['high', 'low', 'close', 'volume'],
    resultKey: 'value',
  },
]

const indicatorsList = []
indicatorsConfig.forEach(config => {
  const { keyPrefix, indicator, variants, argsValue, argsMoment, resultKey, resultSubKeys } = config
  variants.forEach(variant => {
    const isSingle = !Array.isArray(variant)
    const postfix = isSingle ? variant || 0 : variant.join('_')
    const opts = {
      group: keyPrefix,
      key: `${keyPrefix}_${postfix}`,
      indicator,
      indicatorOpts: isSingle ? [variant] : variant,
      argsValue,
      argsMoment,
      resultKey,
      resultSubKeys,
    }
    indicatorsList.push(opts)
  })
})

// chart
const opts = {
  // autoSize: true,
  rightPriceScale: {
    // visible: true,
    // drawLastPrice: false,
    borderColor: 'rgba(197, 203, 206, 1)',
    scaleMargins: {
      top: 0.1,
      bottom: 0.1,
    },
  },
  leftPriceScale: {
    // visible: false,
    // drawLastPrice: false,
    borderColor: 'rgba(197, 203, 206, 1)',
  },
  layout: {
    background: {
      type: 'solid',
      color: 'rgba(26, 29, 30, 1)',
    },
    textColor: 'rgba(255, 255, 255, 1)',
  },
  grid: {
    horzLines: {
      color: 'rgba(128, 128, 128, 1)',
    },
    vertLines: {
      color: 'rgba(128, 128, 128, 1)',
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
  },
  timeScale: {
    borderColor: 'rgba(197, 203, 206, 1)',
    ticksVisible: true,
    timeVisible: true,
    rightOffset: 18,
  },
  handleScroll: {
    // vertTouchDrag: false,
  },
  localization: {
    priceFormatter: price => price > 9999
      ? price.toFixed(0)
      : price.toPrecision(4)
        .toLocaleString('fullwide', {
          useGrouping: false
        })
  },
}

// "candles": [
//   {
//       "startTime": 1685390400000,
//       "endTime": 1685390459999,
//       "open": "27624.10",
//       "close": "27623.40",
//       "high": "27624.1",
//       "low": "27613.3",
//       "volume": "167.517",
//       "quoteVolume": "4626593.8797",
//       "trades": 1997
//   },]
let currentChart
let tempLevelSeries = []
let tempLevelSeries2 = []
let predictedWaves = []

// backtest
const marketFee = 0.0005
const limitFee = 0.0002
const cashbackRate = 0.35
// 
const initialBalance = 100
const positionPercent = 0.02
// 
const bouncePreLvlEnterPoints = 3
const bouncePostLvlEnterPoints = 3
// 
const takePercent = 1


export const TWChart = props => {
  const [resizing, setResizing] = useState(false);
  const [width, setWidth] = useRecoilState(candleChartWidthState);
  const [height, setHeight] = useRecoilState(candleChartHeightState);
  // chart state 
  const chartContainerRef = useRef();
  const chartRef = useRef(null);

  // resize chart
  useEffect(() => {
    if (chartRef.current) {
      if (resizing) {
        chartRef.current.applyOptions({
          layout: {
            background: {
              type: 'solid',
              color: 'rgba(0,0,0,1)',
            }
          }
        });
      } else {
        chartRef.current.applyOptions({
          layout: opts.layout
        });
      }
    }
  }, [resizing])

  // resize chart 2
  useEffect(() => {
    if (chartRef.current) {
      const width = chartContainerRef.current.clientWidth;
      chartRef.current.applyOptions({
        width,
        height,
      });
    }
  }, [width, height])


  return (
    <Resizable
      minHeight={200}
      onResizeStop={(e, direction, ref, d) => {
        const newWidth = width + d.width
        const newHeight = height + d.height
        setWidth(newWidth);
        setHeight(newHeight);
        setResizing(false);
        localforage.setItem('candleChartWidthState', newWidth);
        localforage.setItem('candleChartHeightState', newHeight);
      }}
      onResizeStart={(e, direction, ref, d) => {
        setResizing(true);
      }}
      style={{
        boxShadow: '1px 1px 2px 1px rgba(0,0,0,0.5)',
        backgroundColor: resizing ? 'black' : 'transparent',
        paddingBottom: '1vh',
      }}
    >
      <div className='twchart' ref={chartContainerRef} />
      <Chart {...props} chartRef={chartRef} chartContainerRef={chartContainerRef} />
    </Resizable>);
};

function Chart(props) {
  const { replay, replayData, chartRef, chartContainerRef } = props;
  // resize state
  const [height, setHeight] = useRecoilState(candleChartHeightState);

  // chart state 
  const currentSymbol = useRecoilValue(currentSymbolState);
  // 
  const timeMachineLine = React.useRef(null);
  const setTimeMachineCandleTime = useSetRecoilState(timeMachineCandleTimeState);
  const timeMachineCandleTimeRef = useRef(null);

  const tempCandleInterval = useRecoilValue(tempCandleIntervalState);
  const candles = useRecoilValue(candlesSelector);
  const tempCandlesData = useRecoilValue(tempCandlesSelector);
  const tempCandlesRef = useRef(null);
  // 
  const mainCandleInterval = useRecoilValue(mainCandleIntervalState);
  // const candlesH1 = useRecoilValue(candlesH1Selector);
  const mainCandlesData = useRecoilValue(mainCandlesSelector);
  const mainCandlesRef = useRef(null);


  const candlesUpdates = useRecoilValue(candleUpdatesSelector);
  const lastCandleUpdate = useRecoilValue(lastCandleUpdateSelector);

  const mainWindowSize = useRecoilValue(mainWindowSizeState);
  const mainFilter2Duration = useRecoilValue(mainFilter2DurationState);
  const mainF1Percent = useRecoilValue(mainF1PercentState);
  const mainF2Percent = useRecoilValue(mainF2PercentState);

  const tempWindowSize = useRecoilValue(tempWindowSizeState);
  const tempFilter2Duration = useRecoilValue(tempFilter2DurationState);
  const tempF1Percent = useRecoilValue(tempF1PercentState);
  const tempF2Percent = useRecoilValue(tempF2PercentState);

  const candlestickSeriesRef = useRef(null);
  const volumeSeriesRef = useRef(null);
  const atrRef = useRef(null);

  const ema1Ref = useRef(null);
  const ema1SeriesRef = useRef(null);

  const psarRef = useRef(null);
  const psarSeriesRef = useRef(null);

  const [removeChart, setRemoveChart] = useState(null);

  console.log('TWChart rerender')

  const enableUpdates = replay ? false : true

  // let chart = chartS
  // let candlestickSeries = candlestickSeriesS
  // let volumeSeries = volumeSeriesS
  // let ema1 = ema1S
  // let ema1Series = ema1SeriesS
  // console.log('candlesSelector', candles)
  // [{
  //   "time": 1685790060,
  //   "close": 306.42,
  //   "open": 306.39,
  //   "high": 306.43,
  //   "low": 306.39,
  //   "volume": 69.62
  // }]

  function clearTempLevelSeries() {
    let result = true
    try {
      if (tempLevelSeries && tempLevelSeries.length) {
        tempLevelSeries.forEach(series => {
          try {
            if (series) currentChart.removeSeries(series)
          } catch (error) {
            console.log('tempLevelSeries removeSeries failed', error)
          }
        })
      }
      tempLevelSeries.length = 0
    } catch (e) {
      console.log('clearTempLevelSeries tempLevelSeries failed', e)
      tempLevelSeries.length = 0
      result = result && false
    }

    try {
      if (tempLevelSeries2 && tempLevelSeries2.length) {
        tempLevelSeries2.forEach(series => {
          try {
            if (series) currentChart.removeSeries(series)
          } catch (error) {
            console.log('tempLevelSeries2 removeSeries failed', error)
          }
        })
        tempLevelSeries2.length = 0
      }
    } catch (e) {
      console.log('clearTempLevelSeries tempLevelSeries2 failed', e)
      tempLevelSeries2.length = 0
      result = result && false
    }

    return result
  }


  function updateIndicators({ mainCandles, tempCandles }) {

    // ***********************************************
    // Pivots
    // ***********************************************

    if (showPivots) {
      Pivots(mainCandleInterval, tempCandlesData, chartRef.current)
    }

    // ***********************************************
    // LEVELS
    // ***********************************************

    console.log('updateIndicators clearTempLevelSeries')
    const cleared = clearTempLevelSeries()

    // if (replayData && replayData.levelsGlobal && replayData.levelsLocal) {
    //   replayLevels({ replayData })
    // } else {
    let globalCandles = mainCandles
    let localCandles = tempCandles
    if (replayData) {
      // const startTime = replayData.tradeBuffers.usdm[0].T / 1000
      // globalCandles = mainCandles.filter(c => c.time <= startTime)
      // localCandles = tempCandles.filter(c => c.time <= startTime)
    }
    if (cleared) {
      console.log('handleLevels call')
      handleLevels({
        mainCandleInterval,
        mainCandles: globalCandles,
        mainWindowSize,
        mainFilter2Duration,
        mainF1Percent,
        mainF2Percent,
        tempCandleInterval,
        tempCandles: localCandles,
        tempWindowSize,
        tempFilter2Duration,
        tempF1Percent,
        tempF2Percent,
        timeMachineCandleTime: timeMachineCandleTimeRef.current,
      })
    } else {
      console.log('handleLevels canceled')
    }
    //}

    if (replay && replayData) {
      console.log('CandleChart replayData', replayData)
      const tempIntervalMinutes = intervalToMinutes(tempCandleInterval)


      if (showRecordLevels) {
        if (replayData?.event?.delayedLvlPrice) {
          const eventTime = replayData?.event?.history?.length
            ? replayData?.event?.history[0].time
            : replayData.time

          if (eventTime) {
            const time = Math.floor(eventTime / 60 / 1000 / tempIntervalMinutes) * tempIntervalMinutes * 60 // in seconds
            const buySeriesData = [];
            const buySeries = chartRef.current.addLineSeries({
              color: 'rgba(255,255,0,0.9)',
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              lastValueVisible: true,
              priceLineVisible: true,
              axisLabelVisible: true,
              title: `LEVEL`,
            });
            tempLevelSeries.push(buySeries)
            const price = replayData.event.delayedLvlPrice
            const point = { time, value: price }
            buySeriesData.push(point);
            buySeries.setData(buySeriesData);

          }
        }

        if (replayData?.event?.INIT_TP_THRESHOLD) {
          const eventTime = replayData?.event?.history?.length
            ? replayData?.event?.history[0].time
            : replayData.time
          if (eventTime) {
            const time = Math.floor(eventTime / 60 / 1000 / tempIntervalMinutes) * tempIntervalMinutes * 60 // in seconds
            const buySeriesData = [];
            const buySeries = chartRef.current.addLineSeries({
              color: 'rgba(0,255,250,0.9)',
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              lastValueVisible: true,
              priceLineVisible: true,
              axisLabelVisible: true,
              title: `INIT TP`,
            });
            tempLevelSeries.push(buySeries)
            const isLong = replayData.event.direction === 'long'
            const price = isLong
              ? replayData.event.delayedLvlPrice + replayData.event.delayedLvlPrice * replayData.event.INIT_TP_THRESHOLD
              : replayData.event.delayedLvlPrice - replayData.event.delayedLvlPrice * replayData.event.INIT_TP_THRESHOLD
            const point = { time, value: price }
            buySeriesData.push(point);
            buySeries.setData(buySeriesData);

          }
        }

        if (replayData?.event?.INIT_SL_THRESHOLD) {
          const eventTime = replayData?.event?.history?.length
            ? replayData?.event?.history[0].time
            : replayData.time
          if (eventTime) {
            const time = Math.floor(eventTime / 60 / 1000 / tempIntervalMinutes) * tempIntervalMinutes * 60 // in seconds
            const buySeriesData = [];
            const buySeries = chartRef.current.addLineSeries({
              color: 'rgba(255,0,250,0.9)',
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              lastValueVisible: true,
              priceLineVisible: true,
              axisLabelVisible: true,
              title: `INIT SL`,
            });
            tempLevelSeries.push(buySeries)
            const isLong = replayData.event.direction === 'long'
            const price = isLong
              ? replayData.event.delayedLvlPrice - replayData.event.delayedLvlPrice * replayData.event.INIT_SL_THRESHOLD
              : replayData.event.delayedLvlPrice + replayData.event.delayedLvlPrice * replayData.event.INIT_SL_THRESHOLD
            const point = { time, value: price }
            buySeriesData.push(point);
            buySeries.setData(buySeriesData);

          }
        }

        if (replayData?.event?.delayedEnters?.length) {
          let openNum = 0
          for (const enter of replayData.event.delayedEnters) {
            const eventTime = replayData?.event?.history?.length
              ? replayData?.event?.history[0].time
              : replayData.time
            if (eventTime) {
              const time = Math.floor(eventTime / 60 / 1000 / tempIntervalMinutes) * tempIntervalMinutes * 60 // in seconds
              const isLong = replayData.event.direction === 'long'
              if (isLong) {
                const buySeriesData = [];
                const buySeries = chartRef.current.addLineSeries({
                  color: 'rgba(0,155,0,0.3)',
                  lineWidth: 1,
                  lineStyle: LineStyle.Solid,
                  lastValueVisible: true,
                  priceLineVisible: true,
                  axisLabelVisible: true,
                  title: `buy ${openNum++} $${formatNumber(enter.usdAmount)}`,
                });
                tempLevelSeries.push(buySeries)
                const point = { time, value: enter.price }
                buySeriesData.push(point);
                buySeries.setData(buySeriesData);
              } else {
                const sellSeriesData = [];
                const sellSeries = chartRef.current.addLineSeries({
                  color: 'rgba(155,0,0,0.3)',
                  lineWidth: 1,
                  lineStyle: LineStyle.Solid,
                  lastValueVisible: true,
                  priceLineVisible: true,
                  axisLabelVisible: true,
                  title: `sell ${openNum++} $${formatNumber(enter.usdAmount)}`,
                });
                tempLevelSeries.push(sellSeries)
                const point = { time, value: enter.price }
                sellSeriesData.push(point);
                sellSeries.setData(sellSeriesData);
              }
            }
          }
        }

        if (replayData?.event?.history?.length) {
          let openNum = 0
          let closeNum = 0
          const opens = []
          for (const ev of replayData.event.history) {
            const eventTime = ev.time
            if (eventTime) {
              const time = Math.floor(eventTime / 60 / 1000 / tempIntervalMinutes) * tempIntervalMinutes * 60 // in seconds
              let color = 'blue'
              const isOpen = ev.type === 'openLong' || ev.type === 'openShort'
              if (isOpen) {
                opens.push({ time, price: ev.price, amount: ev.amount })
              }

              if (ev.side === 'buy') {
                color = 'rgba(0,255,0,0.1)'
                const buySeriesData = [];
                const buySeries = chartRef.current.addLineSeries({
                  color: 'rgba(0,255,0,1)',
                  lineWidth: 1,
                  lineStyle: LineStyle.Solid,
                  lastValueVisible: true,
                  priceLineVisible: true,
                  axisLabelVisible: true,
                  title: `${ev.type} ${isOpen ? openNum++ : closeNum++}`,
                });
                tempLevelSeries.push(buySeries)
                //const time = Math.floor(ev.time / 1000)
                const point = { time, value: ev.price }
                buySeriesData.push(point);
                buySeries.setData(buySeriesData);
              } else if (ev.side === 'sell') {
                color = 'rgba(255,0,0,0.1)'

                const sellSeriesData = [];
                const sellSeries = chartRef.current.addLineSeries({
                  color: 'rgba(255,0,0,1)',
                  lineWidth: 1,
                  lineStyle: LineStyle.Solid,
                  lastValueVisible: true,
                  priceLineVisible: true,
                  axisLabelVisible: true,
                  title: `${ev.type} ${isOpen ? openNum++ : closeNum++}`,
                });
                tempLevelSeries.push(sellSeries)
                //const time = Math.floor(ev.time / 1000)
                const point = { time, value: ev.price }
                sellSeriesData.push(point);
                sellSeries.setData(sellSeriesData);
              }
              const vertLine = new VertLine(chartRef.current, candlestickSeriesRef.current, time, {
                labelText: ev.type,
                width: 1,
                showLabel: true,
                color: color,
                labelTextColor: 'white',
                labelBackgroundColor: color,
              });
              candlestickSeriesRef.current.attachPrimitive(vertLine);

            }
          }

          if (opens.length) {
            const totalAmount = opens.reduce((acc, val) => acc + val.amount, 0)
            const avgWeightedPrice = opens.reduce((acc, val) => acc + val.price * val.amount, 0) / totalAmount
            const time = opens[opens.length - 1].time
            const seriesData = [];
            const series = chartRef.current.addLineSeries({
              color: 'rgba(255, 255, 0, 1)',
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              lastValueVisible: true,
              priceLineVisible: true,
              axisLabelVisible: true,
              title: `AVG Open`,
            });
            tempLevelSeries.push(series)
            const point = { time, value: avgWeightedPrice }
            seriesData.push(point);
            series.setData(seriesData);
            console.log('AVG Open', opens, seriesData)
          }

        } else {
          const t1 = replayData?.event?.now || replayData?.time
          const t2 = replayData?.tradeBuffers?.usdm[0]?.T
          const replayTime = t1 || t2
          if (replayTime) {
            // find prev tempIntervalMinutes candle time
            const time = Math.floor(t2 / 60 / 1000 / tempIntervalMinutes) * tempIntervalMinutes * 60 // in seconds

            const vertLine = new VertLine(chartRef.current, candlestickSeriesRef.current, time, {
              labelText: 'Replay',
              width: 1,
              showLabel: true,
              color: 'blue',
              labelTextColor: 'white',
              labelBackgroundColor: 'blue',
            });
            candlestickSeriesRef.current.attachPrimitive(vertLine);
          }
        }
      }
    }

    // split candles mark
    if (mainCandleInterval !== tempCandleInterval && tempCandles?.length) {
      const splitTime = tempCandles[0].time
      const vertLine = new VertLine(chartRef.current, candlestickSeriesRef.current, splitTime, {
        labelText: `${mainCandleInterval} -> ${tempCandleInterval}`,
        width: 1,
        showLabel: true,
        color: '#9800ff',
        labelTextColor: 'white',
        labelBackgroundColor: '#9800ff',
      });
      candlestickSeriesRef.current.attachPrimitive(vertLine);
    }


    // ***********************************************
    // Other 
    // ***********************************************

    // if (tempCandles.length >= ATR_LENGTH + 1) {
    //   const closeEma = new EMA(ATR_LENGTH);
    //   let atr1 = null
    //   let closeEma1 = null
    //   const atrCandles = tempCandles.slice(-(ATR_LENGTH + 1))

    //   for (const c of atrCandles) {
    //     atr1 = atr.nextValue(c.open, c.close, c.high, c.low)
    //     closeEma1 = closeEma.nextValue((c.open + c.close + c.high + c.low) / 4)
    //   }
    //   const lastClose = atrCandles[atrCandles.length - 1].close
    //   const atrPercent = atr1 / closeEma1 * 100
    //   console.log('atr', atr1, atrPercent, closeEma1, lastClose)
    // }

    // if (selectedCandles.length >= windowLength) {
    // HighsAndLows(selectedCandles, 2, chart)
    // ZigZag(selectedCandles, chart, 9)
    // ZigZag2(selectedCandles, chart, 0.7)
    // ExtremLevels(selectedCandles, chart, candlestickSeries, 1)
    // }


    // ***********************************************
    // scale and handle resize
    // ***********************************************

    // currentChart.timeScale().fitContent();
  }


  // init chart
  useEffect(() => {
    setTimeMachineCandleTime(null);
    timeMachineCandleTimeRef.current = null;
    // ***********************************************
    // Chart
    // ***********************************************
    const handleResize = () => {
      try {
        const width = chartContainerRef.current.clientWidth;
        // const height = chartContainerRef.current.clientHeight;
        // const fullHeight = window.innerHeight
        chart.applyOptions({
          width,
          height,
        });
      } catch (error) {
        console.log('handleResize error', error)
      }
    };

    const currentRef = chartContainerRef.current

    const fullHeight = window.innerHeight
    const width = chartContainerRef.current.clientWidth;
    // const height = chartContainerRef.current.clientHeight;

    console.log('height', height)
    const chart = createChart(chartContainerRef.current, {
      ...opts,
      width,
      height,
    });
    // setChart(chart)
    chartRef.current = chart
    currentChart = chart

    // ***********************************************
    // Candles
    // ***********************************************
    const candlestickSeries = chart.addCandlestickSeries({
      priceScaleId: 'right'
    });
    // setCandlestickSeries(candlestickSeries)
    candlestickSeriesRef.current = candlestickSeries
    candlestickSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.1, // highest point of the series will be 10% away from the top
        bottom: 0.2, // lowest point will be 40% away from the bottom
      },
    })


    // ***********************************************
    // Volume Histogram
    // ***********************************************

    const volumeSeries = chart.addHistogramSeries({
      color: '#26a69a',
      priceScaleId: '',
      lastValueVisible: false,
      lineWidth: 2,
      priceFormat: {
        type: 'volume',
      },
      priceLineVisible: false,
      // overlay: true 
    });
    // setVolumeSeries(volumeSeries)
    volumeSeriesRef.current = volumeSeries

    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    // ***********************************************
    // ToolTip
    // ***********************************************
    const toolTipWidth = 80;
    const toolTipHeight = 80;
    const toolTipMargin = 15;

    // Create and style the tooltip html element
    const toolTip = document.createElement('div');
    toolTip.style = `
    width: 96px; 
    height: 100px; 
    position: absolute;
    display: none; 
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: left;
    z-index: 1000;
    top: 12px; 
    left: 12px;
    pointer-events: none;
    border-radius: 2px;`;

    toolTip.style.background = '#14141488';
    toolTip.style.color = 'white';
    chartContainerRef.current.appendChild(toolTip);

    chart.subscribeCrosshairMove(param => {
      try {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > chartContainerRef.current.clientWidth ||
          param.point.y < 0 ||
          param.point.y > chartContainerRef.current.clientHeight
        ) {
          toolTip.style.display = 'none';
        } else {
          // time will be in the same format that we supplied to setData.
          const dateStr = new Date(param.time * 1000).toLocaleString('ru-RU')
          toolTip.style.display = 'block';
          const candleData = param.seriesData.get(candlestickSeries);
          const volumeData = param.seriesData.get(volumeSeries);
          const high = candleData && candleData.high !== undefined ? candleData.high : 0;
          const low = candleData && candleData.low !== undefined ? candleData.low : 0;
          const volume = volumeData && volumeData.value !== undefined ? volumeData.value : 0;
          toolTip.innerHTML = `
          <div style="font-size: 16px; margin: 2px 0px;">
            ${(100 * (high / low) - 100).toFixed(2)}%
          </div>
          <div style="margin: 2px 0px;">
            H:${high.toPrecision(5)}
          </div>
          <div style="margin: 2px 0px;">
            L:${low.toPrecision(5)}
          </div>
          <div style="margin: 2px 0px;">
            ~$${formatNumber((high + low) / 2 * volume)} 
          </div>
          <div>
            ${dateStr}
          </div>`;

          const y = param.point.y;
          let left = param.point.x + toolTipMargin;
          if (left > chartContainerRef.current.clientWidth - toolTipWidth) {
            left = param.point.x - toolTipMargin - toolTipWidth;
          }

          let top = y + toolTipMargin;
          if (top > chartContainerRef.current.clientHeight - toolTipHeight) {
            top = y - toolTipHeight - toolTipMargin;
          }
          toolTip.style.left = left + 'px';
          toolTip.style.top = top + 'px';
        }
      } catch (error) {
        console.log('chart.subscribeCrosshairMove error', error)
      }
    });

    chart.subscribeClick(param => {
      if (!param.point) {
        return;
      }
      // console.log('chart.subscribeClick', param)
      // console.log(`Click at ${param.point.x}, ${param.point.y}. The time is ${param.time}.`);

      if (timeMachineLine.current) {
        try {
          candlestickSeriesRef.current.detachPrimitive(timeMachineLine.current);
          timeMachineLine.current = null;
        } catch (error) {
          console.log('timeMachineLine detachPrimitive error', error)
        }
      }

      if (param.time) {
        try {
          const vertLine = new VertLine(chartRef.current, candlestickSeriesRef.current, param.time, {
            labelText: 'Time Machine',
            width: 1,
            showLabel: true,
            color: 'rgba(255,255,255,0.5)',
            labelTextColor: 'black',
            labelBackgroundColor: 'white',
          });
          candlestickSeriesRef.current.attachPrimitive(vertLine);
          timeMachineLine.current = vertLine;

          setTimeMachineCandleTime(param.time);
          timeMachineCandleTimeRef.current = param.time;

          const updateIndicatorsOpts = {
            mainCandles: mainCandlesRef.current,
            tempCandles: tempCandlesRef.current
          }
          console.log('updateIndicators subscribeClick time', param.time, updateIndicatorsOpts)
          updateIndicators(updateIndicatorsOpts)
        } catch (error) {
          console.log('timeMachineLine attachPrimitive error', error)
        }

      }
    })

    // ***********************************************
    // UserPriceAlerts
    // ***********************************************

    const userPriceAlertsPrimitive = new UserPriceAlerts();
    userPriceAlertsPrimitive.setSymbolName(currentSymbol);
    candlestickSeries.attachPrimitive(userPriceAlertsPrimitive);


    userPriceAlertsPrimitive.alertAdded().subscribe((alertInfo) => {
      console.log(
        `➕ Alert added @ ${alertInfo.price} with the id: ${alertInfo.id}`
      );
    });

    userPriceAlertsPrimitive.alertRemoved().subscribe((id) => {
      console.log(`❌ Alert removed with the id: ${id}`);
    });

    // ***********************************************
    // EMAs
    // ***********************************************
    const ema1 = new EMA(13);
    // setEma1(ema1)
    ema1Ref.current = ema1

    const ema1Series = chart.addLineSeries({
      color: 'rgba(90, 140, 190, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    // setEma1Series(ema1Series)
    ema1SeriesRef.current = ema1Series

    // ***********************************************
    // KAMA + PSAR
    // ***********************************************
    const psar = new PSAR(0.02, 0.2, 0.24)
    psarRef.current = psar

    const psarSeries = chart.addLineSeries({
      color: 'rgba(255, 140, 190, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    psarSeriesRef.current = psarSeries
    // ***********************************************
    // Other
    // ***********************************************

    const atr1 = new ATR(ATR_LENGTH);
    // setAtr(atr1)
    atrRef.current = atr1

    // ***********************************************
    // scale and handle resize
    // ***********************************************

    // chart.timeScale().fitContent();
    window.addEventListener('resize', handleResize);

    return () => {
      chart.unsubscribeClick()
      chart.unsubscribeCrosshairMove()

      currentRef.removeChild(toolTip);
      window.removeEventListener('resize', handleResize);
      console.log('init chart cleanup')
      clearTempLevelSeries()
      if (timeMachineLine.current) {
        try {
          candlestickSeries.detachPrimitive(timeMachineLine.current);
          timeMachineLine.current = null;
        } catch (error) {
          console.log('timeMachineLine detachPrimitive error', error)
        }
      }
    };
  }, [])

  // init chart data series
  useEffect(() => {
    // if (!chartRef.current || !candlestickSeriesRef.current || !volumeSeriesRef.current || !ema1Ref.current || !ema1SeriesRef.current) return () => { }
    // if (currentChart !== chart) return () => { }

    candlestickSeriesRef.current.setData([]);
    volumeSeriesRef.current.setData([]);

    const now = Date.now()

    // fix for light-charts selector data mutation
    const mainCandles = JSON.parse(JSON.stringify(mainCandlesData));
    mainCandlesRef.current = mainCandles
    // fix for light-charts selector data mutation
    const tempCandles = JSON.parse(JSON.stringify(tempCandlesData));
    tempCandlesRef.current = tempCandles

    if (mainCandles && mainCandles.length
      && tempCandles && tempCandles.length
      && mainCandles[mainCandles.length - 1].time === tempCandles[0].time) {
      tempCandles.shift()
    }

    const combinedCandles = [...mainCandles, ...tempCandles]
    try {
      candlestickSeriesRef.current.setData(combinedCandles);
    } catch (error) {
      console.log('candlestickSeries.setData error', error)
    }

    let volumeData = combinedCandles.map(c => ({
      time: c.time,
      value: c.volume,
      color: c.open <= c.close
        ? 'rgba(0, 150, 136, 0.8)'
        : 'rgba(255,82,82, 0.8)',
    }))
    // volumeData.sort((a, b) => a.time - b.time);
    try {
      volumeSeriesRef.current.setData(volumeData);
    } catch (error) {
      console.log('volumeSeries.setData error', error)
    }



    // const kamaClose = KAMA(tempCandles, 'close', 20, 3, 20)
    // const kamaHigh = KAMA(tempCandles, 'high', 20, 3, 20)
    // const kamaLow = KAMA(tempCandles, 'low', 20, 3, 20)
    const kamaPsar = []
    const psar = new PSAR(0.001, 0.005, 0.05)

    // for (let i = 0; i < kamaClose.length; i++) {
    //   const mid = (kamaHigh[i].value + kamaLow[i].value) * 0.5
    //   const value = psar.nextValue(kamaHigh[i].value, kamaLow[i].value, kamaClose[i].value)
    //   // psarRef.current.isBullTrend
    //   const divUp = psar.isBullTrend && value > kamaClose[i].value
    //   const divDown = !psar.isBullTrend && value < kamaClose[i].value
    //   if (divUp || divDown) {
    //     console.log('kama divergence', kamaHigh[i], kamaLow[i], kamaClose[i], value)
    //   }
    //   kamaPsar.push({ time: kamaClose[i].time, value: value })
    // }
    //console.log('psarRef.current', psar)
    //console.log('kamaPsar', kamaPsar)
    //console.log('kamas', kamaClose, kamaHigh, kamaLow)

    // let psar1 = combinedCandles.map(c => {
    //   const value = psarRef.current.nextValue(c.high, c.low, c.close)
    //   return { time: c.time, value: value }
    // })
    try {
      psarSeriesRef.current.setData(kamaPsar);
    } catch (error) {
      console.log('series.setData psarSeriesRef', error)
    }

    let emas1 = combinedCandles.map(c => {
      const value = ema1Ref.current.nextValue(c.close);
      return { time: c.time, value: value }
    })

    // try {
    //   ema1SeriesRef.current.setData(kamaClose);
    // } catch (error) {
    //   console.log('series.setData ema1Series', error)
    // }

    const updateIndicatorsOpts = { mainCandles, tempCandles }
    console.log('updateIndicators init chart data series', updateIndicatorsOpts)
    updateIndicators(updateIndicatorsOpts)

  }, [
    currentSymbol,
    // 
    mainCandleInterval,
    mainCandlesData,
    mainWindowSize,
    mainFilter2Duration,
    mainF1Percent,
    mainF2Percent,
    // 
    tempCandleInterval,
    tempCandlesData,
    tempWindowSize,
    tempFilter2Duration,
    tempF1Percent,
    tempF2Percent,
    // 
    chartRef.current,
    // candlestickSeries,
    // volumeSeries,
    // ema1,
    // ema1Series,
    replay,
    replayData,
    // 
    timeMachineCandleTimeRef.current,
  ]);



  // *******************************************************
  // Updates
  // *******************************************************

  // update chart data series
  useEffect(() => {
    // if (!chartRef.current || !candlestickSeries || !volumeSeries || !ema1 || !ema1Series) return () => { }
    // if (currentChart !== chartRef.current) return () => { }
    if (!enableUpdates) return () => { }

    if (candles.length && candlesUpdates.length) {
      const mainCandles = JSON.parse(JSON.stringify(mainCandlesData)); // fix for light-charts selector data mutation

      const minuteCandles2 = candles.concat(candlesUpdates)
      const tempCandles = convertCandles(minuteCandles2, 1, tempCandleInterval)

      const combinedCandles = [...mainCandles, ...tempCandles]

      const candle = tempCandles[tempCandles.length - 1]


      // ***********************************************
      // UPDATE MAIN SERIES
      // ***********************************************

      try {
        candlestickSeriesRef.current.update(candle);
      } catch (error) {
        console.log('candlesUpdates candlestickSeries.update failed', error)
      }

      try {
        volumeSeriesRef.current.update({
          time: candle.time,
          value: candle.volume,
          color: candle.open <= candle.close
            ? 'rgba(0, 150, 136, 0.8)'
            : 'rgba(255,82,82, 0.8)',
        })
      } catch (error) {
        console.warn('candlesUpdates volumeSeries.update failed', error)
      }

      const ema1Value = ema1Ref.current.nextValue(candle.close);
      try {
        ema1SeriesRef.current.update({ time: candle.time, value: ema1Value });
      } catch (error) {
        console.warn('candlesUpdates ema1Series.update failed', error)
      }


      // ***********************************************
      // LEVELS
      // ***********************************************

      console.log('update chart data seriesclearTempLevelSeries')
      const cleared = clearTempLevelSeries()
      if (cleared) {
        console.log('handleLevels call')
        handleLevels({
          mainCandleInterval,
          mainCandles,
          mainWindowSize,
          mainFilter2Duration,
          mainF1Percent,
          mainF2Percent,
          tempCandleInterval,
          tempCandles,
          tempWindowSize,
          tempFilter2Duration,
          tempF1Percent,
          tempF2Percent,
          timeMachineCandleTime: timeMachineCandleTimeRef.current,
        })
      } else {
        console.log('handleLevels canceled')
      }


    }
  }, [
    currentSymbol,
    chartRef.current,
    candles,
    mainCandlesData,
    mainCandleInterval,
    mainWindowSize,
    mainFilter2Duration,
    mainF1Percent,
    mainF2Percent,
    tempCandleInterval,
    tempWindowSize,
    tempFilter2Duration,
    tempF1Percent,
    tempF2Percent,
    candlesUpdates,
    timeMachineCandleTimeRef.current,
  ]);

  // update last candle and volume
  useEffect(() => {
    // if (!chartRef.current || !candlestickSeriesRef.current || !volumeSeries || !ema1 || !ema1Series) return () => { }
    // if (currentChart !== chartRef.current) return () => { }
    if (!enableUpdates || !lastCandleUpdate) return () => { }

    if (candles.length && lastCandleUpdate) {
      const intervalMinutes = intervalToMinutes(tempCandleInterval)
      const minuteCandles = candles.slice(-intervalMinutes).concat(candlesUpdates).slice(-intervalMinutes)
      minuteCandles.push(lastCandleUpdate);
      const convertedCandles = convertCandles(minuteCandles, 1, tempCandleInterval)
      const selectedCandles = convertedCandles

      const candle = selectedCandles[selectedCandles.length - 1]

      // console.log('lastCandleUpdate', candle)
      try {
        candlestickSeriesRef.current.update(candle);
      } catch (error) {
        console.warn('lastCandleUpdate candlestickSeries.update failed', error)
      }

      try {
        volumeSeriesRef.current.update({
          time: candle.time,
          value: candle.volume,
          color: candle.open <= candle.close
            ? 'rgba(0, 150, 136, 0.8)'
            : 'rgba(255,82,82, 0.8)',
        })
      } catch (error) {
        console.warn('lastCandleUpdate volumeSeries.update failed', error)
      }
    }
  }, [
    chartRef.current,
    candles,
    mainCandleInterval,
    lastCandleUpdate,
    currentSymbol,
  ])

  // // remove old chart
  // useEffect(() => {
  //   if (removeChart) {
  //     console.log('removeChart clearTempLevelSeries')
  //     clearTempLevelSeries()
  //     removeChart.remove();
  //     setRemoveChart(null);
  //   }
  // }, [removeChart])

  return null

}

function replayLevels(opts) {
  const {
    replayData,
  } = opts

  if (replayData && replayData.levelsGlobal && replayData.levelsLocal) {
    // console.log('replayData', replayData)

    const maxEndTime = Math.floor(Date.now() / 1000) //Math.max(endTime, tempEndTime)

    const localLevelsToDraw = replayData.levelsLocal.unbeat.map(level => {
      return {
        ...level,
        startTime: Math.floor(level.startTime / 1000),
      }
    })
    const globalLevelsToDraw = replayData.levelsGlobal.unbeat.map(level => {
      return {
        ...level,
        startTime: Math.floor(level.startTime / 1000),
      }
    })

    const mainLevels = drawImportantLevels(currentChart, globalLevelsToDraw, maxEndTime, LEVEL_HIDE_DISTANCE)
    tempLevelSeries.push(...mainLevels)
    const tempLevels = drawImportantLevels(currentChart, localLevelsToDraw, maxEndTime, LEVEL_HIDE_DISTANCE)
    tempLevelSeries.push(...tempLevels)
  }

}

function handleLevels(props) {
  const {
    mainCandleInterval,
    mainCandles,
    mainWindowSize,
    mainFilter2Duration,
    mainF1Percent,
    mainF2Percent,
    tempCandleInterval,
    tempCandles,
    tempWindowSize,
    tempFilter2Duration,
    tempF1Percent,
    tempF2Percent,
    timeMachineCandleTime,
  } = props

  const mainIntervalMinutes = intervalToMinutes(mainCandleInterval)
  // const mainHourInCandles = 60 / mainIntervalMinutes
  // const mainWindowLength = mainHourInCandles * mainWindowSize
  // const mainMinDuration = mainHourInCandles * mainFilter2Duration
  const mainWindowLength = mainWindowSize
  const mainMinDuration = mainFilter2Duration

  const tempIntervalMinutes = intervalToMinutes(tempCandleInterval)
  // const tempHourInCandles = 60 / tempIntervalMinutes
  const tempWindowLength = tempWindowSize
  const tempMinDuration = tempFilter2Duration

  let filteredMainCandles = mainCandles
  let filteredTempCandles = tempCandles
  if (timeMachineCandleTime) {
    filteredMainCandles = mainCandles.filter(c => c.time <= timeMachineCandleTime)
    filteredTempCandles = tempCandles.filter(c => c.time <= timeMachineCandleTime)
  }

  const isMainCandlesValidInterval = filteredMainCandles.length >= mainWindowLength
  const isTempCandlesValidInterval = filteredTempCandles.length >= tempWindowLength
  let isCandlesValid = isMainCandlesValidInterval && isTempCandlesValidInterval
  if (mainIntervalMinutes === tempIntervalMinutes) {
    isCandlesValid = isMainCandlesValidInterval
  }

  if (isCandlesValid) {
    allAboutLevels({
      mainIntervalMinutes,
      mainCandles: filteredMainCandles,
      mainWindowLength,
      mainMinDuration,
      mainF1Percent,
      mainF2Percent,
      // temp
      tempIntervalMinutes,
      tempCandles: filteredTempCandles,
      tempWindowLength,
      tempMinDuration,
      tempF1Percent,
      tempF2Percent,
    })
  } else {
    console.log('not enough candles to calculate levels', mainCandles.length, mainWindowLength, tempCandles.length, tempWindowLength)
  }
}

function allAboutLevels(opts) {
  console.time('Time allAboutLevels');
  const {
    mainCandles,
    mainIntervalMinutes,
    mainWindowLength,
    mainMinDuration,
    mainF1Percent,
    mainF2Percent,
    tempCandles,
    tempIntervalMinutes,
    tempWindowLength,
    tempMinDuration,
    tempF1Percent,
    tempF2Percent,
  } = opts

  console.log('allAboutLevels main', mainIntervalMinutes, mainWindowLength, mainMinDuration, mainF1Percent, mainF2Percent)
  console.log('allAboutLevels temp', tempIntervalMinutes, tempWindowLength, tempMinDuration, tempF1Percent, tempF2Percent)

  const atrMain = new ATR(ATR_MAIN_LENGTH);
  const atrMainPre = new ATR(ATR_MAIN_LENGTH);
  const atrMainPreCandles = mainCandles.slice(0, ATR_MAIN_LENGTH + 1)
  let atrMainPreValue = 0
  for (let i = 0; i < atrMainPreCandles.length; i++) {
    const candle = atrMainPreCandles[i]
    atrMainPreValue = atrMainPre.nextValue(candle.open, candle.close, candle.high, candle.low)
  }
  // console.log('atrMainPreValue', atrMainPreValue)
  let mainSelectedCandles = mainCandles

  const tempHourInCandles = 60 / tempIntervalMinutes
  const tempSelectedCandles = tempCandles//.slice(-tempHourInCandles * 24 * 8)
  const tempMaxIdx = tempSelectedCandles.length - 1

  if (tempIntervalMinutes === mainIntervalMinutes) {
    //console.log('allAboutLevels mainSelectedCandles', mainSelectedCandles)
    mainSelectedCandles = mainCandles.concat(tempSelectedCandles)
    //console.log('allAboutLevels tempSelectedCandles', tempSelectedCandles)
    //console.log('joined candles', mainSelectedCandles)
  }

  const mainMaxIdx = mainSelectedCandles.length - 1
  const endTime = mainSelectedCandles[mainSelectedCandles.length - 1].time + mainIntervalMinutes * 60

  const latestClose = tempSelectedCandles.length
    ? tempSelectedCandles[tempSelectedCandles.length - 1].close
    : mainSelectedCandles[mainSelectedCandles.length - 1].close

  const tempEndTime = tempSelectedCandles.length
    ? tempSelectedCandles[tempSelectedCandles.length - 1].time + tempIntervalMinutes * 60
    : mainSelectedCandles[mainSelectedCandles.length - 1].time + mainIntervalMinutes * 60

  const maxEndTime = Math.max(endTime, tempEndTime)


  // ***********************************************
  console.time('Time mainKeyLevels');
  const mainKeyLevels = findKeyLevels(mainSelectedCandles, mainIntervalMinutes, atrMain, atrMainPreValue)
  console.log('mainKeyLevels', mainKeyLevels)
  console.timeEnd('Time mainKeyLevels');

  console.time('Time mainFilteredLevels1');
  // last param is atr multiplier for upper & lower bound for touches count
  const mainFilteredLevels1 = filterKeyLevels(mainKeyLevels, mainWindowLength, mainF1Percent)
  console.log('mainFilteredLevels1', mainFilteredLevels1)
  // drawKeyLevels(currentChart, mainFilteredLevels1, endTime)
  console.timeEnd('Time mainFilteredLevels1');

  console.time('Time mainImportantLevels');
  const mainImportantLevels = filterKeyLevels2(mainFilteredLevels1, mainMinDuration, mainWindowLength, mainF1Percent, mainF2Percent, mainMaxIdx)
  console.log('mainImportantLevels', mainImportantLevels)
  console.timeEnd('Time mainImportantLevels');

  const mainUnbeatLevels = mainImportantLevels.filter(lvl => lvl.unbeat && lvl.duration > mainMinDuration)
  console.log('mainUnbeatLevels', mainUnbeatLevels)


  let mainUnbeatLevels2 = mainUnbeatLevels

  // ***********************************************
  // TEMP CANDLES?
  // ***********************************************
  let tempFilteredLevels1
  let tempImportantLevels
  let tempUnbeatLevels

  if (tempIntervalMinutes !== mainIntervalMinutes) {
    console.time('Time allAboutLevels tempLevels');

    const atrTemp = new ATR(ATR_TEMP_LENGTH);
    const atrTempPre = new ATR(ATR_TEMP_LENGTH);
    const atrTempPreCandles = tempSelectedCandles.slice(0, ATR_TEMP_LENGTH + 1)
    let atrTempPreValue = 0
    for (let i = 0; i < atrTempPreCandles.length; i++) {
      const candle = atrTempPreCandles[i]
      atrTempPreValue = atrTempPre.nextValue(candle.open, candle.close, candle.high, candle.low)
    }
    // console.log('atrTempPreValue', atrTempPreValue)

    console.time('Time tempKeyLevels');
    const tempKeyLevels = findKeyLevels(tempSelectedCandles, tempIntervalMinutes, atrTemp, atrTempPreValue)
    console.log('tempKeyLevels', tempKeyLevels)
    console.timeEnd('Time tempKeyLevels');

    console.time('Time tempFilteredLevels1');
    tempFilteredLevels1 = filterKeyLevels(tempKeyLevels, tempWindowLength, tempF1Percent)
    console.log('tempFilteredLevels1', tempFilteredLevels1)
    // drawKeyLevels(currentChart, tempFilteredLevels1, endTime)
    console.timeEnd('Time tempFilteredLevels1');

    console.time('Time tempImportantLevels');
    tempImportantLevels = filterKeyLevels2(tempFilteredLevels1, tempMinDuration, tempWindowLength, tempF1Percent, tempF2Percent, tempMaxIdx, true)
    console.log('tempImportantLevels', tempImportantLevels)
    console.timeEnd('Time tempImportantLevels');

    tempUnbeatLevels = tempImportantLevels.filter(lvl => lvl.unbeat && lvl.duration > tempMinDuration)
    console.log('tempUnbeatLevels', tempUnbeatLevels)

    // ********************************
    // get min and max temp level prices and filter main levels between them

    if (tempUnbeatLevels.length >= 2) {
      let minTempPrice = Infinity
      let maxTempPrice = -Infinity
      for (const lvl of tempUnbeatLevels) {
        const price = lvl.level
        if (price < minTempPrice) {
          minTempPrice = price
        } else if (price > maxTempPrice) {
          maxTempPrice = price
        }
      }
      mainUnbeatLevels2 = mainUnbeatLevels.filter(lvl => {
        const price = lvl.level
        return price <= minTempPrice || price >= maxTempPrice
      })
      console.log('mainUnbeatLevels2 temp levels', minTempPrice, maxTempPrice, mainUnbeatLevels2)

      minTempPrice = Infinity
      maxTempPrice = -Infinity
      for (const candle of tempSelectedCandles) {
        const price = candle.close
        if (price < minTempPrice) {
          minTempPrice = price
        } else if (price > maxTempPrice) {
          maxTempPrice = price
        }
      }

      mainUnbeatLevels2 = mainUnbeatLevels.filter(lvl => {
        const price = lvl.level
        return price <= minTempPrice || price >= maxTempPrice
      })
      console.log('mainUnbeatLevels2 temp price', minTempPrice, maxTempPrice, mainUnbeatLevels2)

    }

    if (showLevels) {
      console.time('Time drawImportantLevels tempLevels');
      const tempLevels = drawImportantLevels(currentChart, tempUnbeatLevels, maxEndTime, latestClose, 0.75, LEVEL_HIDE_DISTANCE) // tempImportantLevels
      tempLevelSeries.push(...tempLevels)
      console.timeEnd('Time drawImportantLevels tempLevels');
    }

    // ***********************************************
    // Draw Temp Waves
    // ***********************************************
    if (showHighLowChannels) {
      console.time('Time showHighLowChannels tempLevels');
      const zzSeriesTempLow = drawLevelsLine(currentChart, tempFilteredLevels1.lowLevels, endTime, 'green')
      tempLevelSeries2.push(zzSeriesTempLow)
      const zzSeriesTempHigh = drawLevelsLine(currentChart, tempFilteredLevels1.highLevels, endTime, 'red')
      tempLevelSeries2.push(zzSeriesTempHigh)
      console.timeEnd('Time showHighLowChannels tempLevels');
    }

    const waveWindows = [3, 5, 15, 30, 60]
    const waveWindowsInitOptions = []
    for (const window of waveWindows) {
      const keyLevels = filterKeyLevels(tempKeyLevels, window, tempF1Percent)
      const wavesData = getWaves(keyLevels.lowLevels, keyLevels.highLevels)
      const simpleStats = getSimpleWaveStats(wavesData.waves)
      const duration = simpleStats.pullback.duration.median
      const delta = simpleStats.pullback.deltaPercent.median
      waveWindowsInitOptions.push({
        window,
        duration,
        delta
      })
      console.log('waveWindows simpleStats', window, simpleStats);
    }
    const optimalWaveWindowsTemp = findOptimalWaveWindow(waveWindowsInitOptions, 5, 5)
    console.log('optimalWaveWindows tempLevels', optimalWaveWindowsTemp);


    console.time('Time getWaves tempLevels');
    const wavesDataTemp = getWaves(tempFilteredLevels1.lowLevels, tempFilteredLevels1.highLevels)
    console.log('wavesDataTemp', wavesDataTemp)
    console.timeEnd('Time getWaves tempLevels');
    if (showZigZag) {
      console.time('Time showZigZag tempLevels');
      const zzSeriesWaveTemp = drawLevelsLine(currentChart, wavesDataTemp.levels, endTime, 'white', LineStyle.Dashed)
      tempLevelSeries2.push(zzSeriesWaveTemp)
      console.timeEnd('Time showZigZag tempLevels');
    }

    const wavesTemp = wavesDataTemp.waves
    // console.log('wavesTemp', wavesTemp)
    if (showWaves) {
      console.time('Time drawWaves tempLevels');
      const wavesTempSeries = drawWaves(currentChart, wavesTemp, endTime, 'transparent', LineStyle.Solid, showWaveIdx)
      tempLevelSeries2.push(wavesTempSeries)
      console.timeEnd('Time drawWaves tempLevels');
    }

    if (showWavesTrendLines) {
      console.time('Time drawWavesTrendLines tempLevels');
      const trendLinesData = getWavesTrendLines(wavesTemp, endTime);
      const trendLinesSeries = drawWavesTrendLines(currentChart, trendLinesData)
      tempLevelSeries2.push(...trendLinesSeries)
      console.timeEnd('Time drawWavesTrendLines tempLevels');
    }

    console.time('Time getWaveContinuation wavesTemp');
    const waveContinuationTemp = getWaveContinuation(wavesTemp, tempSelectedCandles)
    console.timeEnd('Time getWaveContinuation wavesTemp');
    console.log('getWaveContinuation wavesTemp', waveContinuationTemp)

    console.time('Time getWaveLevels wavesTemp');
    const waveLevelsTemp = getWaveLevels(wavesTemp)
    console.timeEnd('Time getWaveLevels wavesTemp');
    console.log('getWaveLevels wavesTemp', waveLevelsTemp)

    console.time('Time getSimpleWaveStats wavesTemp');
    const simpleWaveStatsTemp = getSimpleWaveStats(wavesTemp)
    console.timeEnd('Time getSimpleWaveStats wavesTemp');
    console.log('simpleWaveStats wavesTemp', simpleWaveStatsTemp)

    console.time('Time getWaveExtremStats wavesTemp');
    const waveExtremStatsTemp = getWaveExtremStats(wavesTemp)
    console.timeEnd('Time getWaveExtremStats wavesTemp');
    console.log('getWaveExtremStats wavesTemp', waveExtremStatsTemp)

    const bounceSLPercentUp = getBounceSL(waveExtremStatsTemp, 'up')
    console.log('getBounceSL up wavesTemp', bounceSLPercentUp)
    const bounceSLPercentDown = getBounceSL(waveExtremStatsTemp, 'down')
    console.log('getBounceSL down wavesTemp', bounceSLPercentDown)

    const bounceEnterPercentUp = getBounceEnter(waveExtremStatsTemp, 'up')
    console.log('getBounceEnter up wavesTemp', bounceEnterPercentUp)
    const bounceEnterPercentDown = getBounceEnter(waveExtremStatsTemp, 'down')
    console.log('getBounceEnter down wavesTemp', bounceEnterPercentDown)

    console.time('Time getWaveRatioStats wavesTemp');
    const wavePullbackStatsTemp = getWaveRatioStats(wavesTemp)
    console.timeEnd('Time getWaveRatioStats wavesTemp');
    console.log('getWaveRatioStats wavesTemp', wavePullbackStatsTemp)

    console.time('Time getSequenceStats wavesTemp');
    const sequenceStatsTemp = getSequenceStats(wavesTemp)
    console.timeEnd('Time getSequenceStats wavesTemp');
    console.log('getSequenceStats wavesTemp', sequenceStatsTemp)

    const nextWaves = predictSequence(wavesTemp, sequenceStatsTemp);
    console.log('predictSequence', nextWaves);

    // console.time('Time makeSimpleTests wavesTemp');
    // const testsNumberTemp = makeSimpleTests(wavesTemp)
    // console.timeEnd('Time makeSimpleTests wavesTemp')
    // console.log('makeSimpleTests Temp', testsNumberTemp)

    // if (showWaves && testsNumberTemp === 1) {
    //   console.log('predictedWaves', predictedWaves)
    //   // const predictedWavesSeries = drawWaves(currentChart, predictedWaves, endTime, 'yellow', LineStyle.Solid)
    //   const predictedWavesSeries = drawPredictedWaves(currentChart, predictedWaves, endTime, 'transparent', LineStyle.Solid)
    //   tempLevelSeries2.push(predictedWavesSeries)
    // }

    console.time('Time createWaveHierarchy tempLevels');
    const waveHierarchyTemp = createWaveHierarchy(wavesTemp, 'W');
    // console.log('waveHierarchyTemp', waveHierarchyTemp);
    console.timeEnd('Time createWaveHierarchy tempLevels');
    // const waveHierarchyTempSeries = drawWavesHierarchy(currentChart, waveHierarchyTemp, 0, endTime, 'transparent', maxNestingToShowLabels, showWavesHierarchyIdx, showCorrectionBox)
    // tempLevelSeries2.push(...waveHierarchyTempSeries);

    console.time('Time compressWaveCascades tempLevels');
    const cascadeStagesTemp = compressWaveCascades(waveHierarchyTemp)
    console.log('cascadeStagesTemp', cascadeStagesTemp)
    console.timeEnd('Time compressWaveCascades tempLevels');
    if (cascadeStagesTemp.length) {
      const lastStage = cascadeStagesTemp[cascadeStagesTemp.length - 1]
      // const waveCascades = lastStage.cascades
      // const wavesCascadesSeries = drawWaves(currentChart, waveCascades, endTime, 'transparent', LineStyle.Solid, showWaveCascadeIdx)
      // tempLevelSeries2.push(wavesCascadesSeries)

      const waveCascadesHierarchy = lastStage.hierarchy
      if (showWavesHierarchy) {
        console.time('Time drawWavesHierarchy tempLevels');
        const waveCascadesSeries = drawWavesHierarchy(currentChart, waveCascadesHierarchy, 0, endTime, 'transparent', maxNestingToShowLabels, showWaveCascadeIdx, showCorrectionBox)
        tempLevelSeries2.push(...waveCascadesSeries);
        console.timeEnd('Time drawWavesHierarchy tempLevels');
      }

      if (waveCascadesHierarchy && waveCascadesHierarchy.length) {
        if (showWaveFiboRetracement && showWaveFiboTemp) {
          console.time('Time drawWavesFibo tempLevels');
          const lastWave = waveCascadesHierarchy[waveCascadesHierarchy.length - 1]
          const series = drawWavesFibo(currentChart, lastWave, 0, tempIntervalMinutes, maxWaveFiboNesting)
          tempLevelSeries2.push(...series);
          console.timeEnd('Time drawWavesFibo tempLevels');
          // for (let i = 0; i < waveCascadesHierarchy.length; i++) {
          //   const wave = waveCascadesHierarchy[i]
          //   const series = drawWavesFibo(currentChart, wave, 0, tempIntervalMinutes, maxWaveFiboNesting)
          //   tempLevelSeries2.push(...series);
          // }
        }
      }


    }

    console.timeEnd('Time allAboutLevels tempLevels');

    console.time('Time waveBacktest tempLevels');
    const waveBacktestTemp = waveBacktest({
      currentChart,
      tempLevelSeries,
      candles: tempSelectedCandles,
      intervalMinutes: tempIntervalMinutes,
      f1Percent: tempF1Percent,
    });
    // console.log('waveBacktestTemp', waveBacktestTemp);
    console.timeEnd('Time waveBacktest tempLevels');

    console.time('Time waveIndicatorsCorrelations tempLevels');
    const indicatorsCorrelations = waveIndicatorsCorrelations({
      currentChart,
      tempLevelSeries,
      candles: tempSelectedCandles,
      intervalMinutes: tempIntervalMinutes,
      waveBacktest: waveBacktestTemp,
    });
    // console.log('indicatorsCorrelations', indicatorsCorrelations);
    console.timeEnd('Time waveIndicatorsCorrelations tempLevels');

    if (showBacktestWaves) {
      console.time('Time drawWaveBacktest tempLevels');
      const waveBacktestSeries = drawBacktestWaves(currentChart, waveBacktestTemp, endTime, 'transparent', LineStyle.Solid)
      // const waveBacktestSeries = drawBacktestInverseWaves(currentChart, waveBacktestTemp, endTime, 'transparent', LineStyle.Solid)
      tempLevelSeries2.push(waveBacktestSeries)
      console.timeEnd('Time drawWaveBacktest tempLevels');
    }
  }

  // ***********************************************
  // Continue Handle Main Candles
  // ***********************************************
  if (showLevels) {
    console.time('Time drawImportantLevels mainLevels');
    const mainLevels = drawImportantLevels(currentChart, mainUnbeatLevels2, maxEndTime, latestClose, 0.66, LEVEL_HIDE_DISTANCE) // mainUnbeatLevels2
    tempLevelSeries.push(...mainLevels)
    console.timeEnd('Time drawImportantLevels mainLevels');
  }

  // ***********************************************
  // Draw Main Waves
  // ***********************************************

  // main waves 
  if (showHighLowChannels) {
    console.time('Time showHighLowChannels mainLevels');
    const zzSeriesMainLow = drawLevelsLine(currentChart, mainFilteredLevels1.lowLevels, endTime, 'green')
    tempLevelSeries.push(zzSeriesMainLow)
    const zzSeriesMainHigh = drawLevelsLine(currentChart, mainFilteredLevels1.highLevels, endTime, 'red')
    tempLevelSeries.push(zzSeriesMainHigh)
    console.timeEnd('Time showHighLowChannels mainLevels');
  }

  console.time('Time getWaves mainLevels');
  const wavesDataMain = getWaves(mainFilteredLevels1.lowLevels, mainFilteredLevels1.highLevels)
  console.log('wavesDataMain', wavesDataMain)
  console.timeEnd('Time getWaves mainLevels');
  if (showZigZag) {
    console.time('Time showZigZag mainLevels');
    const zzSeriesWaveMain = drawLevelsLine(currentChart, wavesDataMain.levels, endTime, 'white', LineStyle.Dashed)
    tempLevelSeries2.push(zzSeriesWaveMain)
    console.timeEnd('Time showZigZag mainLevels');
  }

  const wavesMain = wavesDataMain.waves
  console.log('wavesMain', wavesMain)
  if (showWaves) {
    console.time('Time drawWaves mainLevels');
    const wavesMainSeries = drawWaves(currentChart, wavesMain, endTime, 'transparent', LineStyle.Solid)
    tempLevelSeries2.push(wavesMainSeries)
    console.timeEnd('Time drawWaves mainLevels');
  }

  if (showWavesTrendLines) {
    console.time('Time drawWavesTrendLines mainLevels');
    const trendLinesData = getWavesTrendLines(wavesMain, endTime);
    const trendLinesSeries = drawWavesTrendLines(currentChart, trendLinesData)
    tempLevelSeries2.push(...trendLinesSeries)
    console.timeEnd('Time drawWavesTrendLines mainLevels');
  }


  // ***********************************************
  // STATS

  // if (wavesMain && wavesMain.length) {
  //   // Фильтрация волн по направлению
  //   let upWaves = wavesMain.filter(wave => wave.direction === "up");
  //   let downWaves = wavesMain.filter(wave => wave.direction === "down");

  //   // Функция для нормализации данных
  //   function normalize(data) {
  //     let mean = data.reduce((sum, wave) => sum + wave.deltaPercent, 0) / data.length;
  //     let std = Math.sqrt(data.reduce((sum, wave) => sum + Math.pow(wave.deltaPercent - mean, 2), 0) / data.length);
  //     return data.map(wave => [(wave.deltaPercent - mean) / std]);
  //   }

  //   // Нормализация данных
  //   let normalizedUpWaves = normalize(upWaves);
  //   let normalizedDownWaves = normalize(downWaves);

  //   function getInertias(data) {
  //     let inertias = [];
  //     for (let k = 1; k <= 20; k++) {
  //       let result = kmeans(data, k);
  //       let inertia = 0;
  //       for (let i = 0; i < data.length; i++) {
  //         const point = data[i];
  //         const centroid = result.centroids[result.clusters[i]];
  //         const distance = Math.pow(point[0] - centroid[0], 2);
  //         inertia += distance;
  //       }
  //       inertias.push(inertia);
  //     }
  //     return inertias
  //   }


  //   // Вывод значений инерции для разных количеств кластеров
  //   const inertiasUp = getInertias(normalizedUpWaves);
  //   console.log("InertiasUp:", inertiasUp);
  //   const inertiasDown = getInertias(normalizedDownWaves);
  //   console.log("InertiasDown:", inertiasDown);

  //   // Кластеризация
  //   let upClusters = kmeans(normalizedUpWaves, 10);
  //   let downClusters = kmeans(normalizedDownWaves, 10);

  //   function remapClusters(clusters) {
  //     // Создаем карту сортировки центроидов
  //     const sortedCentroidIndexes = clusters.centroids
  //       .map((value, index) => ({ index, value: value[0] })) // Используем value[0], так как центроиды у вас вложенные массивы
  //       .sort((a, b) => a.value - b.value)
  //       .map((centroid, newIndex) => ({ oldIndex: centroid.index, newIndex }));

  //     console.log("sortedCentroidIndexes:", sortedCentroidIndexes);
  //     // Создаем объект для ремапа
  //     const remap = sortedCentroidIndexes.reduce((acc, curr) => {
  //       acc[curr.oldIndex] = curr.newIndex;
  //       return acc;
  //     }, {});

  //     console.log("Map for remapping clusters:", remap, clusters);

  //     // Применение ремапа к вашим кластерам
  //     const remappedClusters = clusters.clusters.map(cluster => remap[cluster]);

  //     console.log("Remapped clusters:", remappedClusters);
  //     return remappedClusters
  //   }

  //   const remappedUpClusters = remapClusters(upClusters);
  //   const remappedDownClusters = remapClusters(downClusters);

  //   // Вывод результатов
  //   console.log("Кластеры для восходящих трендов:", remappedUpClusters);
  //   console.log("Кластеры для нисходящих трендов:", remappedDownClusters);

  //   let currentUpIndex = 0
  //   let currentDownIndex = 0
  //   for (const wave of wavesMain) {
  //     if (wave.direction === "up") {
  //       wave.cluster = remappedUpClusters[currentUpIndex]
  //       currentUpIndex++
  //     } else {
  //       wave.cluster = remappedDownClusters[currentDownIndex]
  //       currentDownIndex++
  //     }
  //   }

  //   const wc = wavesMain.map(wave => wave.cluster)
  //   console.log('wc', wc)
  // }

  console.time('Time getSimpleWaveStats wavesMain');
  const simpleWaveStatsMain = getSimpleWaveStats(wavesMain)
  console.timeEnd('Time getSimpleWaveStats wavesMain');
  console.log('simpleWaveStats wavesMain', simpleWaveStatsMain)

  // console.time('Time makeSimpleTests wavesMain');
  // const testsNumber = makeSimpleTests(wavesMain)
  // console.timeEnd('Time makeSimpleTests wavesMain')
  // console.log('makeSimpleTests wavesMain', testsNumber)

  // if (showWaves && testsNumber === 1) {
  //   console.log('predictedWaves', predictedWaves)
  //   // const predictedWavesSeries = drawWaves(currentChart, predictedWaves, endTime, 'yellow', LineStyle.Solid)
  //   const predictedWavesSeries = drawPredictedWaves(currentChart, predictedWaves, endTime, 'transparent', LineStyle.Solid)
  //   tempLevelSeries2.push(predictedWavesSeries)
  // }


  // ***********************************************
  // Hierarchy

  console.time('Time createWaveHierarchy mainLevels');
  const waveHierarchyMain = createWaveHierarchy(wavesMain, 'W');
  console.log('waveHierarchyMain', waveHierarchyMain);
  console.timeEnd('Time createWaveHierarchy mainLevels');
  if (showInitialWavesHierarchy) {
    console.time('Time drawWavesHierarchy mainLevels');
    const waveHierarchyMainSeries = drawWavesHierarchy(currentChart, waveHierarchyMain, 0, endTime, 'transparent', maxNestingToShowLabels, showWavesHierarchyIdx, showCorrectionBox)
    tempLevelSeries.push(...waveHierarchyMainSeries)
    console.timeEnd('Time drawWavesHierarchy mainLevels');
  }

  console.time('Time compressWaveCascades mainLevels');
  const cascadeStagesMain = compressWaveCascades(waveHierarchyMain)
  console.log('cascadeStagesMain', cascadeStagesMain)
  console.timeEnd('Time compressWaveCascades mainLevels');
  if (cascadeStagesMain.length) {
    if (showInitialWavesCascades) {
      console.time('Time drawWaves mainLevels');
      const waveCascades = cascadeStagesMain[0].cascades
      const wavesCascadesSeries = drawWaves(currentChart, waveCascades, endTime, 'transparent', LineStyle.Solid, showWaveCascadeIdx)
      tempLevelSeries2.push(wavesCascadesSeries)
      console.timeEnd('Time drawWaves mainLevels');
    }


    const waveCascadesHierarchy = cascadeStagesMain[cascadeStagesMain.length - 1].hierarchy
    if (showWavesHierarchy) {
      console.time('Time drawWavesHierarchy mainLevels');
      const waveCascadesSeries = drawWavesHierarchy(currentChart, waveCascadesHierarchy, 0, endTime, 'transparent', maxNestingToShowLabels, showWaveCascadeIdx, showCorrectionBox)
      tempLevelSeries2.push(...waveCascadesSeries);
      console.timeEnd('Time drawWavesHierarchy mainLevels');
    }

    // ***********************************************
    // fibo
    if (waveCascadesHierarchy && waveCascadesHierarchy.length) {
      if (showWaveFiboRetracement && showWaveFiboMain) {
        console.time('Time drawWavesFibo mainLevels');
        const lastWave = waveCascadesHierarchy[waveCascadesHierarchy.length - 1]
        const series = drawWavesFibo(currentChart, lastWave, 0, tempIntervalMinutes, maxWaveFiboNesting, endTime)
        tempLevelSeries2.push(...series);
        console.timeEnd('Time drawWavesFibo mainLevels');
        // for (let i = 0; i < waveCascadesHierarchy.length; i++) {
        //   const wave = waveCascadesHierarchy[i]
        //   const series = drawWavesFibo(currentChart, wave, 0, tempIntervalMinutes, maxWaveFiboNesting)
        //   tempLevelSeries2.push(...series);
        // }
      }

      // ***********************************************
      // Statistics
      // let avgWaveDuration = 0
      // let avgWaveDelta = 0
      // for (let i = 0; i < waveCascadesHierarchy.length; i++) {
      //   const wave = waveCascadesHierarchy[i]
      //   const duration = wave.end - wave.start
      //   avgWaveDuration += duration
      //   avgWaveDelta += wave.delta
      //   console.log('L0', 'wave', i, wave.direction, 'duration', duration / 1000 / 60, 'delta', wave.delta, 'segments', wave?.segments?.length || 0, 'waves', wave?.waves?.length || 0)
      // }
      // avgWaveDuration /= waveCascadesHierarchy.length
      // avgWaveDuration = avgWaveDuration / 1000 / 60 // in minutes
      // avgWaveDelta /= waveCascadesHierarchy.length
      // console.log('L0', 'avgWaveDuration', avgWaveDuration, 'avgWaveDelta', avgWaveDelta)

      console.time('Time collectWaveStatistics');
      const stats = collectWaveStatistics(waveCascadesHierarchy)
      console.log('collectWaveStatistics', stats)
      console.timeEnd('Time collectWaveStatistics');
    }
  }

  // ***********************************************
  // other
  // ***********************************************

  // const extrems = []
  // const hoursInCandles = 60 / mainIntervalMinutes
  // const dayInCandles = hoursInCandles * 24
  // const weekInCandles = dayInCandles * 7
  // const loadedWeeks = Math.floor(mainSelectedCandles.length / weekInCandles)

  // let fibos = []
  // for (let i = 1; i <= loadedWeeks; i++) {
  //   // const t = Date.now()
  //   const t = mainSelectedCandles[mainSelectedCandles.length - 1].time
  //   const weekStart = t / 1000 - i * 7 * 24 * 60 * 60
  //   const weekExtrems = getMinMaxLevelsAfterTime(mainUnbeatLevels, weekStart)
  //   if (weekExtrems.maxLevel && weekExtrems.minLevel) {
  //     extrems.push(weekExtrems)
  //     const weekFibo = getFibonacciLevels([weekExtrems.maxLevel, weekExtrems.minLevel])
  //     fibos = fibos.concat(weekFibo)
  //   }
  // }

  // const filteredExtrems = []
  // extrems.forEach(weekExtrems => {
  //   if (!filteredExtrems.includes(weekExtrems.maxLevel)) filteredExtrems.push(weekExtrems.maxLevel)
  //   if (!filteredExtrems.includes(weekExtrems.minLevel)) filteredExtrems.push(weekExtrems.minLevel)
  // })

  // console.log('weekExtrems', extrems)
  // console.log('filteredExtrems', filteredExtrems)
  // console.log('fibonacciLevels', fibos) // fibos
  // // ***********************************************

  // // const topLevels = unbeatLevels.slice(0, 2)
  // // const bottomLevels = unbeatLevels.slice(-2)
  // // const joinedLevels = topLevels.concat(bottomLevels)
  // // console.log('joinedLevels', joinedLevels)
  // // const fibonacciLevels = getFibonacciLevels(filteredExtrems)

  // // const fiboMerged = fibonacciLevels.filter(lvl => !!lvl.mergedLevels)
  // // console.log('fiboMerged', fiboMerged)

  // // tempLevelSeries2 = drawFiboLevels(currentChart, fibos, maxEndTime)
  console.timeEnd('Time allAboutLevels');
}

// ***********************************************
// Other
// ***********************************************

function predictNextWave(currentWave, index, stats, methodConfig, isPrediction = false) {
  const priceMultiplier = 1;

  const prediction = {
    start: currentWave.end,
  };

  // if (isPrediction) {
  //   prediction.start = currentWave.start
  // }

  let sectionTypeWeight = 1;
  let statsSection, statsSectionType;
  if (currentWave.type === 'pullback') {
    statsSection = stats.pullback;
    statsSectionType = stats.pullback;
    prediction.type = 'move';
    sectionTypeWeight = stats.move.count / stats.overall.count;
  } else {
    statsSection = stats.move;
    statsSectionType = stats.pullback;
    prediction.type = 'pullback';
    sectionTypeWeight = stats.pullback.count / stats.overall.count;
  }

  let sectionDirectionWeight = 1;
  if (currentWave.direction === 'up') {
    statsSection = statsSection.up;
    sectionDirectionWeight = statsSection.count / stats.overall.up.count;
    prediction.direction = 'down';
    prediction.high = currentWave.high;
  } else {
    statsSection = statsSection.down;
    sectionDirectionWeight = statsSection.count / stats.overall.down.count;
    prediction.direction = 'up';
    prediction.low = currentWave.low;
  }

  // Расчет предсказания по каждому методу с его весом
  prediction.duration = 0;
  prediction.delta = 0;
  prediction.deltaPercent = 0;
  methodConfig.methods.forEach((method, idx) => {
    const weight = methodConfig.weights[idx];
    prediction.duration += valueByMethod('duration', method, stats, statsSection, statsSectionType, sectionTypeWeight, sectionDirectionWeight, index) * weight;
    prediction.delta += valueByMethod('delta', method, stats, statsSection, statsSectionType, sectionTypeWeight, sectionDirectionWeight, index) * weight * priceMultiplier;
    prediction.deltaPercent += valueByMethod('deltaPercent', method, stats, statsSection, statsSectionType, sectionTypeWeight, sectionDirectionWeight, index) * weight * priceMultiplier;
  });

  prediction.duration = Math.round(prediction.duration / 60) * 60 // fix duration to minutes


  const volatilityMult = currentWave.deltaPercent / stats[currentWave.type][currentWave.direction].deltaPercent.median;
  if (prediction.type === 'pullback') {
    prediction.deltaPercent = prediction.deltaPercent * volatilityMult
    if (prediction.direction === 'down') {
      if (prediction.deltaPercent > currentWave.deltaPercent * 0.618) {
        prediction.deltaPercent = currentWave.deltaPercent * 0.618
      }
    }
  }
  if (prediction.type === 'move') {
    if (prediction.deltaPercent > currentWave.deltaPercent) {
      prediction.deltaPercent = prediction.deltaPercent * volatilityMult
    }
  }

  prediction.end = prediction.start + Math.round(prediction.duration * 60);

  if (prediction.direction === 'up') {
    prediction.high = currentWave.low + currentWave.low * prediction.deltaPercent;
  } else {
    prediction.low = currentWave.high - currentWave.high * prediction.deltaPercent;
  }

  return prediction;
}

function valueByMethod(prop, method, stats, statsSection, statsSectionType, sectionTypeWeight, sectionDirectionWeight, index) {
  switch (method) {
    case 'mean':
      return statsSection[prop].avg;
    case 'weightedMean':
      return statsSection[prop].avg * sectionDirectionWeight + stats.overall[prop].avg * (1 - sectionDirectionWeight);
    case 'overallMean':
      return stats.overall[prop].avg;
    case 'linearRegression':
      return statsSection[prop].intercept + statsSection[prop].slope * index;
    case 'median':
      return statsSection[prop].median;
    case 'weightedMedian':
      return statsSection[prop].median * sectionDirectionWeight + stats.overall[prop].median * (1 - sectionDirectionWeight);
    case 'overallMedian':
      return stats.overall[prop].median;
    case 'combinedWeightedMedian':
      return statsSectionType[prop].median * sectionTypeWeight + stats.overall[prop].median * (1 - sectionTypeWeight);
    case 'combinedWeightedMean':
      return statsSectionType[prop].avg * sectionTypeWeight + stats.overall[prop].avg * (1 - sectionTypeWeight);
    default:
      return statsSection[prop].median;  // Fallback to median if method is not defined
  }
}



function checkSimplePrediction(waves, method, percentiles) {
  const results = initResults(percentiles);
  predictedWaves = [];
  const startIndex = Math.round(waves.length / 4)
  let totalPredictions = 0;

  for (let i = startIndex; i < waves.length; i++) {
    const subset = waves.slice(0, i);
    const simpleStats = getSimpleWaveStats(subset);
    const lastWave = subset[subset.length - 2];
    const currentWave = subset[subset.length - 1];
    const nextWave = waves[i];

    // Симулируем продолжение текущей волны
    const simulatedCurrentWave = simulateContinuation(lastWave, currentWave, simpleStats);

    // console.log('test', i, currentWave, simulatedCurrentWave)
    const predicted = predictNextWave(simulatedCurrentWave, i + 1, simpleStats, method);
    // 
    // const predicted = simulateContinuation(lastWave, currentWave, simpleStats, true);
    // 
    // const predicted = predictNextWave(currentWave, i + 1, simpleStats, method, true);

    // console.log('real', i, lastWave, currentWave, nextWave)
    // console.log('sym', i, simulatedCurrentWave, predicted)

    // Обновляем результаты на основе симулированной волны
    updateCategoricalStats(results.direction, nextWave.direction, predicted.direction);
    updateCategoricalStats(results.type, nextWave.type, predicted.type);
    // Numeric
    updateNumericStats(results.duration, (nextWave.end - nextWave.start) / 60, predicted.duration, percentiles, simpleStats.overall.duration.stdDev);
    updateNumericStats(results.delta, nextWave.delta, predicted.delta, percentiles, simpleStats.overall.delta.stdDev);
    updateNumericStats(results.deltaPercent, nextWave.deltaPercent, predicted.deltaPercent, percentiles, simpleStats.overall.deltaPercent.stdDev);
    totalPredictions++

    predictedWaves.push(predicted)

    // if (totalPredictions > 5) break // reduce debug logs
  }

  const simpleStats = getSimpleWaveStats(waves);
  const predicted1 = predictNextWave(predictedWaves[predictedWaves.length - 1], waves.length + 1, simpleStats, method);
  predictedWaves.push(predicted1)
  const predicted2 = predictNextWave(predicted1, waves.length + 2, simpleStats, method);
  predictedWaves.push(predicted2)


  finalizeResults(results, totalPredictions);
  return results;
}

function simulateContinuation(currentWave, nextWave, simpleStats, isPrediction = false) {
  const priceMultiplier = 0.66;
  let randomTime = 0.99 + Math.random() * 0.01; // Случайный коэффициент времени в диапазоне от 0.1 до 0.5
  let simulatedTime = nextWave.start + (nextWave.end - nextWave.start) * randomTime; // Случайное время в пределах следующей волны
  let randomPrice = 0.02 + Math.random() * 0.01
  let randomPriceChange = (nextWave.high - nextWave.low) * randomPrice;

  let simulatedCurrentPrice;
  if (currentWave.direction === 'down') {
    simulatedCurrentPrice = currentWave.low + randomPriceChange;
  } else {
    simulatedCurrentPrice = currentWave.high - randomPriceChange;
  }

  // 
  let direction = currentWave.direction === 'up' ? 'down' : 'up'
  // if (isPrediction) {
  //   direction = currentWave.direction
  // }

  let type = 'pullback';
  let high = currentWave.high
  let low = currentWave.low

  if (currentWave.direction === 'up') {
    if (simulatedCurrentPrice > currentWave.high) {
      // currentWave not ended yet?
      // currentWave will be changed later
      direction = 'up'
      high = simulatedCurrentPrice
      console.log('currentWave not ended yet?')
    } else if (simulatedCurrentPrice < currentWave.high) {
      // possible reversal
      direction = 'down'

      // console.log('go down', currentWave, nextWave)
      if (simulatedCurrentPrice > currentWave.low) {
        // possible pullback
        type = 'pullback'
      } else if (simulatedCurrentPrice < currentWave.low) {
        // possible move
        type = 'move'
      }
      high = currentWave.high
      const predictedLow = high - high * simpleStats[type][direction].deltaPercent.median * priceMultiplier
      low = Math.min(simulatedCurrentPrice, predictedLow)
      // high = currentWave.high
      // const predictedHigh = low + low * simpleStats[type][direction].deltaPercent.median
      // high = Math.max(simulatedCurrentPrice, predictedHigh)
    }
  } else {
    if (simulatedCurrentPrice > currentWave.low) {
      // possible reversal
      direction = 'up'
      // console.log('go up', currentWave, nextWave)
      if (simulatedCurrentPrice > currentWave.high) {
        // possible move
        type = 'move'
      } else if (simulatedCurrentPrice < currentWave.high) {
        // possible pullback
        type = 'pullback'
      }
      low = currentWave.low
      const predictedHigh = low + low * simpleStats[type][direction].deltaPercent.median * priceMultiplier
      high = Math.max(simulatedCurrentPrice, predictedHigh)
      // low = currentWave.low
      // const predictedLow = high - high * simpleStats[type][direction].deltaPercent.median
      // low = Math.min(simulatedCurrentPrice, predictedLow)
    } else if (simulatedCurrentPrice < currentWave.low) {
      // currentWave not ended yet?
      // currentWave will be changed later
      direction = 'down'
      low = simulatedCurrentPrice
      console.log('currentWave not ended yet?')
    }
  }

  let currentDelta = Math.abs(high - low);
  let currentDeltaPercent = direction === 'up'
    ? currentDelta / currentWave.low
    : currentDelta / currentWave.high;


  let medianDuration = simpleStats.overall.duration.median; // Получение медианной длительности из статистики

  let start = currentWave.start
  let end = currentWave.end
  // if (isPrediction) {
  start = nextWave.start
  end = nextWave.end
  // }
  let simulatedWave = {
    direction: direction,
    type: type,
    delta: currentDelta,
    deltaPercent: currentDeltaPercent,
    high,
    low,
    duration: medianDuration,
    start,
    end,// nextWave.start + Math.max(simulatedTime, medianDuration) // Использование абсолютного времени окончания
  };

  return simulatedWave;
}


function waveBacktest(props) {
  console.log('waveBacktest start')
  try {
    const { currentChart, tempLevelSeries, candles: allCandles, intervalMinutes, f1Percent } = props;

    const waveWindows = [3, 5, 15, 30, 60]



    const atrMain = new ATR(ATR_MAIN_LENGTH);
    const atrMainPre = new ATR(ATR_MAIN_LENGTH);
    const atrMainPreCandles = allCandles.slice(0, ATR_MAIN_LENGTH + 1)
    let atrMainPreValue = 0
    for (let i = 0; i < atrMainPreCandles.length; i++) {
      const candle = atrMainPreCandles[i]
      atrMainPreValue = atrMainPre.nextValue(candle.open, candle.close, candle.high, candle.low)
    }

    let indicators = {}
    initIndicators(indicators, allCandles)
    calculateIndicators(indicators, atrMainPreCandles)

    const timeMult = 60
    let selectedWindow = 15
    let minLevelTime = 5 * timeMult
    let optimalWaves
    let optimalStats
    let hasNewWave = false
    let isLastWaveUpdated = false
    let isExtremsUpdated = false
    let totalWaveChanges = 0
    let totalNewWaves = 0
    let totalLastWaveUpdates = 0
    let totalExtremUpdates = 0
    let lastWave = { start: 0, end: 0 }
    let lastWaveUpdated = { start: 0, end: 0 }

    let state = {
      balance: initialBalance,
      balanceLong: initialBalance,
      balanceShort: initialBalance,
      pnl: 0,
      feeTotal: 0,
      feeLong: 0,
      feeShort: 0,
      inLong: false,
      inShort: false,
    }

    let stateInverse = {
      balance: initialBalance,
      balanceLong: initialBalance,
      balanceShort: initialBalance,
      pnl: 0,
      feeTotal: 0,
      feeLong: 0,
      feeShort: 0,
      inLong: false,
      inShort: false,
    }

    let wavesChanges = []
    let wavesNew = []
    let wavesLastWaveUpdate = []
    let wavesExteremUpdate = []
    let shared = {}
    let sharedInverse = {}



    // const { time, open, high, low, close, volume } = candle
    // const avg4 = (open + high + low + close) / 4

    // const indicatorParams = {
    //   high,
    //   low,
    //   close,
    //   open,
    //   volume,
    //   avg4,
    // }

    const backtestTradesSeries = getBacktestTradesSeries(currentChart)
    tempLevelSeries.push(...backtestTradesSeries.series)

    let correctPredictions = 0
    let totalPredictions = 0

    for (let i = ATR_MAIN_LENGTH; i < allCandles.length; i++) {
      const candles = allCandles.slice(0, i)
      const lastCandle = candles[candles.length - 1]
      hasNewWave = false
      isLastWaveUpdated = false
      isExtremsUpdated = false

      calculateIndicators(indicators, [lastCandle])
      

      const mainKeyLevels = findKeyLevels(candles, intervalMinutes, atrMain, atrMainPreValue)

      if (i === ATR_MAIN_LENGTH || i % 100 === 0) {
        const waveWindowsInitOptions = []
        for (const window of waveWindows) {
          const keyLevels = filterKeyLevels(mainKeyLevels, window, f1Percent)
          const wavesData = getWaves(keyLevels.lowLevels, keyLevels.highLevels)
          const stats = getSimpleWaveStats(wavesData.waves)
          const duration = stats.pullback.duration.median
          const delta = stats.pullback.deltaPercent.median
          waveWindowsInitOptions.push({
            window,
            duration,
            delta,
            keyLevels,
            wavesData,
            stats,
          })
        }
        const optimalWaveWindows = findOptimalWaveWindow(waveWindowsInitOptions, 5, 5)
        const optimal = optimalWaveWindows.optimal

        selectedWindow = optimal.window
        optimalWaves = optimal.wavesData.waves
        optimalStats = optimal.stats

        if (!optimal.wavesData.waves.length) continue
      } else {
        const keyLevels = filterKeyLevels(mainKeyLevels, selectedWindow, f1Percent)
        const wavesData = getWaves(keyLevels.lowLevels, keyLevels.highLevels)
        const stats = getSimpleWaveStats(wavesData.waves)
        optimalWaves = wavesData.waves
        optimalStats = stats
      }

      if (!optimalWaves.length) continue

      const optimalSubset = optimalWaves.slice(-4)
      const noLastSubset = optimalWaves.slice(-4, -1)

      const waveContinuation = getWaveContinuation(optimalWaves, candles)
      if (waveContinuation.waveUpdated) {
        totalExtremUpdates++
        isExtremsUpdated = true

        // optimalWaves[optimalWaves.length - 1].end = waveContinuation.end
        // optimalWaves[optimalWaves.length - 1].high = waveContinuation.high
        // optimalWaves[optimalWaves.length - 1].low = waveContinuation.low

        lastWaveUpdated = Object.assign({}, lastWave)
        lastWaveUpdated.end = waveContinuation.end
        lastWaveUpdated.high = waveContinuation.high
        lastWaveUpdated.low = waveContinuation.low
        lastWaveUpdated.type = waveContinuation.type ? waveContinuation.type : lastWave.type

        optimalSubset.splice(-1, 1, lastWaveUpdated)
      }

      // console.log('waveContinuation iteration', i, 'of', allCandles.length, waveContinuation)

      // ***********************************************
      // waves
      if (lastWave.start === optimalWaves[optimalWaves.length - 1].start) {
        if (lastWave.end === optimalWaves[optimalWaves.length - 1].end) {
          // no new wave, no update
        } else if (!isExtremsUpdated) {
          totalLastWaveUpdates++
          isLastWaveUpdated = true
        }
      } else {
        totalNewWaves++
        hasNewWave = true
      }

      const hasChanges = hasNewWave || isLastWaveUpdated || isExtremsUpdated

      if (hasChanges) {
        shared = {}
        sharedInverse = {}

        const waveLevels = getWaveLevels(optimalWaves)
        const highLevels = waveLevels?.highs || []
        const lowLevels = waveLevels?.lows || []
        // const closestHighs = highLevels
        const closestHighsFiltered = highLevels.filter(level => {
          return true // level.start + minLevelTime < lastCandle.time
        })
        shared.closestHighs = closestHighsFiltered.slice(-1)
        shared.closestHighsPrices = shared.closestHighs.map(level => level.high)

        // const closestLows = lowLevels
        const closestLowsFiltered = lowLevels.filter(level => {
          return true // level.start + minLevelTime < lastCandle.time
        })
        shared.closestLows = closestLowsFiltered.slice(-1)
        shared.closestLowsPrices = shared.closestLows.map(level => level.low)

        const waveExtremStats = getWaveExtremStats(optimalWaves)

        const bounceEnterLong = getBounceEnter(waveExtremStats, 'up', 5, 50)
        shared.bounceEnterLong = bounceEnterLong
        const bounceEnterShort = getBounceEnter(waveExtremStats, 'down', 5, 50)
        shared.bounceEnterShort = bounceEnterShort

        const bounceSLPercentLong = getBounceSL(waveExtremStats, 'up', 5, 20)
        shared.bounceSLPercentLong = bounceSLPercentLong
        const bounceSLPercentShort = getBounceSL(waveExtremStats, 'down', 5, 8)
        shared.bounceSLPercentShort = bounceSLPercentShort

        const dp = optimalStats?.pullback?.deltaPercent?.ema || optimalStats?.pullback?.deltaPercent
        const avg1 = dp?.avg
        const median1 = dp?.median
        // const stdDev1 = dp?.stdDev
        // if (avg1 - stdDev1 < minimalVolatilityFilter) return null
        const waveTargetPercent = Math.min(avg1, median1)
        shared.waveTargetPercent = waveTargetPercent

        const sequenceStats = getSequenceStats(optimalWaves)
        shared.sequenceStats = sequenceStats
        const predictedSequence = predictSequence(optimalSubset, sequenceStats);
        shared.predictedSequence = predictedSequence
        shared.predictedWave = predictedSequence?.[1]?.prediction[0]
        const ratioType = shared.predictedWave ? shared.predictedWave.type : 'pullback'

        const predictedCurrentWave = predictSequence(noLastSubset, sequenceStats);
        const predictedCurrentWaveData = predictedCurrentWave?.[1]?.prediction[0]

        // lastWave?.type !== lastWaveUpdated?.type
        // || 
        // || lastWaveUpdated?.type !== predictedCurrentWaveData?.type
        if (!hasNewWave && !isLastWaveUpdated && (lastWave?.type !== predictedCurrentWaveData?.type)) {
          totalPredictions++
          // console.log('predictedCurrentWave', correctPredictions, totalPredictions,  predictedCurrentWaveData, lastWave, lastWaveUpdated)
        } else {
          correctPredictions++
          totalPredictions++
        }

        const waveRatioStats = getWaveRatioStats(optimalWaves)
        // const currentCategory = `${lastWaveUpdated.type || lastWave.type || 'none'}${capitalizeFirstLetter(lastWaveUpdated.direction  || lastWave.direction || 'none')}`
        const currentCategory = `${lastWave.type || 'none'}${capitalizeFirstLetter(lastWave.direction || 'none')}`
        shared.currentCategory = currentCategory
        const pullbackRatio = waveRatioStats?.[ratioType][currentCategory]
        shared.pullbackRatio = pullbackRatio

        const pullbackRatioAvg = pullbackRatio?.stats?.avg
        const pullbackRatioMedian = pullbackRatio?.stats?.median

        const levelUp = shared.closestHighs[shared.closestHighs.length - 1]
        const lastWaveDeltaUp = levelUp?.high - levelUp?.low
        const waveTargetDeltaUp = lastWaveDeltaUp * Math.min(pullbackRatioAvg, pullbackRatioMedian)
        shared.waveTargetDeltaUp = waveTargetDeltaUp

        const levelDown = shared.closestLows[shared.closestLows.length - 1]
        const lastWaveDeltaDown = levelDown?.high - levelDown?.low
        const waveTargetDeltaDown = lastWaveDeltaDown * Math.min(pullbackRatioAvg, pullbackRatioMedian)
        shared.waveTargetDeltaDown = waveTargetDeltaDown

        const clusterTargetsUp = []
        const clusterTargetsDown = []
        const pullbackRatioClusters = pullbackRatio?.clusters?.stats || []
        for (const cluster of pullbackRatioClusters) {
          const targetUp = lastWaveDeltaUp * cluster?.min
          clusterTargetsUp.push(targetUp)
          const targetDown = lastWaveDeltaDown * cluster?.min
          clusterTargetsDown.push(targetDown)
        }
        shared.clusterTargetsUp = clusterTargetsUp
        shared.clusterTargetsDown = clusterTargetsDown


        sharedInverse = { ...shared }
        // const oppositeLevelPrice = closestLowsPrices[closestLowsPrices.length - 1]
        // const virtualLevel = oppositeLevelPrice + waveTargetDeltaDown
        // sharedInverse.closestHighs = shared.closestLows.map(level => {
        //   return {
        //     ...level,
        //     high: level.low + waveTargetDeltaDown,
        //     low: level.low + waveTargetDeltaDown
        //   }
        // })

        // sharedInverse.closestLows = shared.closestHighs.map(level => {
        //   return {
        //     ...level,
        //     high: level.high - waveTargetDeltaUp,
        //     low: level.high - waveTargetDeltaUp
        //   }
        // })

        sharedInverse.closestHighsPrices = shared.closestLowsPrices.map(level => level + waveTargetDeltaDown)
        sharedInverse.closestLowsPrices = shared.closestHighsPrices.map(level => level - waveTargetDeltaUp)

      }

      if (hasNewWave) {
        const up = { ...optimalWaves[optimalWaves.length - 1], newWave: true, ...shared }
        wavesChanges.push(up)
        wavesNew.push(up)
      } else if (isLastWaveUpdated) {
        const up = { ...optimalWaves[optimalWaves.length - 1], lastUp: true, ...shared }
        wavesChanges.push(up)
        wavesLastWaveUpdate.push(up)
      } else if (isExtremsUpdated) {
        const up = { ...lastWaveUpdated, extremUp: true, ...shared }
        wavesChanges.push(up)
        wavesExteremUpdate.push(up)
      }

      if (hasChanges) {
        totalWaveChanges++
        // console.log('waveBacktest iteration', i, 'of', allCandles.length, 'window', selectedWindow, 'waves', optimalWaves.length, hasNewWave, isLastWaveUpdated, isExtremsUpdated, totalWaveChanges, totalNewWaves, totalLastWaveUpdates, totalExtremUpdates, lastCandle, shared)
      }


      // const exitPointsShort = checkExitShort(lastCandle, shared, state)
      // const exitPointsLong = checkExitLong(lastCandle, shared, state)
      // const entryPoints = checkEnter(lastCandle, shared, state)
      // if (showBacktestTrades) {
      //   const tradesSeries1 = drawBacktestTrades(currentChart,
      //     {
      //       entryPoints,
      //       exitPointsShort,
      //       exitPointsLong
      //     }, backtestTradesSeries)
      //   if (tradesSeries1) tempLevelSeries.push(...tradesSeries1.series)
      // }


      const exitPointsInverseShort = checkExitShort(lastCandle, sharedInverse, stateInverse, indicators)
      const exitPointsInverseLong = checkExitLong(lastCandle, sharedInverse, stateInverse, indicators)
      const entryPointsInverse = checkEnter(lastCandle, sharedInverse, stateInverse, indicators)

      if (showBacktestTrades) {
        const tradesSeries2 = drawBacktestTrades(currentChart, {
          entryPoints: entryPointsInverse,
          exitPointsShort: exitPointsInverseShort,
          exitPointsLong: exitPointsInverseLong
        }, backtestTradesSeries)
        if (tradesSeries2) tempLevelSeries.push(...tradesSeries2.series)
      }

      lastWave = optimalWaves[optimalWaves.length - 1]
    }

    console.log('predictions current', correctPredictions, totalPredictions)

    console.log('STATE', state)
    console.log('STATE INVERSE', stateInverse)
    // const {
    //   buySeriesLong,
    //   sellSeriesLong,
    //   buySeriesShort,
    //   sellSeriesShort,
    //   buySeriesDataLong,
    //   sellSeriesDataLong,
    //   buySeriesDataShort,
    //   sellSeriesDataShort,
    // } = backtestTradesSeries

    // console.log('backtestTradesSeries', backtestTradesSeries)

    // buySeriesDataLong.sort((a, b) => a.time - b.time)
    // sellSeriesDataLong.sort((a, b) => a.time - b.time)
    // buySeriesDataShort.sort((a, b) => a.time - b.time)
    // sellSeriesDataShort.sort((a, b) => a.time - b.time)

    // try {
    //   buySeriesLong.setData(buySeriesDataLong);
    // } catch (error) {
    //   console.log('buySeriesLong.setData(buySeriesDataLong); error', error)
    // }
    // try {
    //   sellSeriesLong.setData(sellSeriesDataLong);
    // } catch (error) {
    //   console.log('sellSeriesLong.setData(sellSeriesDataLong); error', error)
    // }
    // try {
    //   buySeriesShort.setData(buySeriesDataShort);
    // } catch (error) {
    //   console.log('buySeriesShort.setData(buySeriesDataShort); error', error)
    // }
    // try {
    //   sellSeriesShort.setData(sellSeriesDataShort);
    // } catch (error) {
    //   console.log('sellSeriesShort.setData(sellSeriesDataShort); error', error)
    // }

    return { wavesChanges, wavesNew, wavesLastWaveUpdate, wavesExteremUpdate }
  } catch (error) {
    console.log('waveBacktest error', error)
  }
}


function initResults(percentiles) {
  const initDeviationObject = () => ({ accuracy: 0, correct: 0, deviations: percentiles.reduce((acc, p) => ({ ...acc, [p]: 0 }), {}) });
  return {
    direction: initDeviationObject(),
    type: initDeviationObject(),
    duration: initDeviationObject(),
    delta: initDeviationObject(),
    deltaPercent: initDeviationObject()
  };
}

function updateNumericStats(stats, actualValue, predictedValue, percentiles, stdDev) {
  const deviation = Math.abs(actualValue - predictedValue) / actualValue;
  percentiles.forEach(p => {
    if (deviation <= p) stats.deviations[p]++;
  });
  if (deviation <= stdDev / actualValue) stats.correct++;
}

function updateCategoricalStats(stats, actualValue, predictedValue) {
  if (actualValue === predictedValue) {
    stats.correct++;
  }
}

function finalizeResults(results, totalPredictions) {
  Object.values(results).forEach(category => {
    category.accuracy = category.correct / totalPredictions;
    category.total = totalPredictions;
    Object.keys(category.deviations).forEach(key => {
      category.deviations[key] /= totalPredictions;
    });
  });
}


function binaryPrevWave(arr, targetTime) {
  let left = 0;
  let right = arr.length - 1;
  let result = null;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (arr[mid].end <= targetTime) {
      result = arr[mid]; // Находим кандидат
      left = mid + 1;    // Ищем дальше вправо
    } else {
      right = mid - 1;   // Ищем в левой части
    }
  }

  return result;
}
function binaryNextWave(arr, targetTime) {
  let left = 0;
  let right = arr.length - 1;
  let result = null;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (arr[mid].start >= targetTime) {
      result = arr[mid]; // Сохраняем кандидат
      right = mid - 1;   // Ищем дальше влево
    } else {
      left = mid + 1;    // Ищем справа
    }
  }

  return result;
}

function waveIndicatorsCorrelations(props) {
  try {
    const indicators = {}
    const candleIndicators = []
    const { currentChart, tempLevelSeries, candles, intervalMinutes, waveBacktest } = props;
    const { wavesChanges, wavesNew, wavesLastWaveUpdate, wavesExteremUpdate } = waveBacktest
    initIndicators(indicators, candles)

    for (const candle of candles) {
      // const { o, h, l, c, v } = candle
      // const avg4 = (o + h + l + c) / 4
      // const indicatorParams = {
      //   high: h,
      //   low: l,
      //   close: c,
      //   open: o,
      //   volume: v,
      //   avg4,
      // }

      const { time, open, high, low, close, volume } = candle
      const avg4 = (open + high + low + close) / 4

      const indicatorParams = {
        high,
        low,
        close,
        open,
        volume,
        avg4,
      }



      const combined = {
        candle,
        waves: {
          prevNew: binaryPrevWave(wavesNew, time),
          prevLastUpdate: binaryPrevWave(wavesLastWaveUpdate, time),
          prevExtremUpdate: binaryPrevWave(wavesExteremUpdate, time),
          nextNew: binaryNextWave(wavesNew, time),
          nextLastUpdate: binaryNextWave(wavesLastWaveUpdate, time),
          nextExtremUpdate: binaryNextWave(wavesExteremUpdate, time),
        }
      }

      for (const params of indicatorsList) {
        try {
          const { key, indicator, period, argsValue, argsMoment } = params

          const paramsValue = argsValue.map(arg => indicatorParams[arg])
          indicators[key].value = indicators[key]._.nextValue(...paramsValue)

          const paramsMoment = argsMoment.map(arg => indicatorParams[arg])
          indicators[key].moment = indicators[key]._.momentValue(...paramsMoment)

          combined[key] = {
            value: indicators[key].value,
            moment: indicators[key].moment,
          }
        } catch (error) {
          console.log('waveIndicatorsCorrelations error', params, error)
        }
      }

      candleIndicators.push(combined)
    }

    // find correlations and covariances

    // prepare arrays
    const waveKeys = ['prevNew', 'prevLastUpdate', 'prevExtremUpdate', 'nextNew', 'nextLastUpdate', 'nextExtremUpdate']
    const prepared = {
      prevNew: [],
      prevLastUpdate: [],
      prevExtremUpdate: [],
      nextNew: [],
      nextLastUpdate: [],
      nextExtremUpdate: [],
    }
    for (const params of indicatorsList) {
      const { key, resultSubKeys } = params
      if (resultSubKeys) {
        for (const subKey of resultSubKeys) {
          const fullKey = `${key}_${subKey}`
          prepared[fullKey] = []
        }
      } else {
        prepared[key] = []
      }
    }

    for (let i = candleIndicators.length; i > 0; i--) {
      try {
        if (!candleIndicators[i]?.waves?.nextNew) continue
        if (!candleIndicators[i]?.waves?.prevNew) continue

        let isValid = true
        for (const params of indicatorsList) {
          const { key, resultKey, resultSubKeys } = params
          // if (!isFinite(candleIndicators[i][key]?.value)) continue
          // if (isNaN(candleIndicators[i][key]?.value)) continue
          // if (candleIndicators[i][key]?.value === undefined) continue
          // if (candleIndicators[i][key]?.value === null) continue
          let check
          if (resultSubKeys) {
            for (const subKey of resultSubKeys) {
              check = !isValidNumber(candleIndicators[i][key]?.[resultKey]?.[subKey])
            }
          } else {
            check = !isValidNumber(candleIndicators[i][key]?.[resultKey])
          }
          if (check) {
            isValid = false
            break
          }

        }
        if (!isValid) continue

        const cw = candleIndicators[i].waves
        for (const waveKey in cw) {
          const dp = cw[waveKey]?.direction === 'up' ? cw[waveKey]?.deltaPercent : -cw[waveKey]?.deltaPercent
          prepared[waveKey].push(dp)
        }

        for (const params of indicatorsList) {
          const { key, resultKey, resultSubKeys } = params
          if (resultSubKeys) {
            for (const subKey of resultSubKeys) {
              const fullKey = `${key}_${subKey}`
              prepared[fullKey].push(candleIndicators[i][key][resultKey][subKey])
            }
          } else {
            prepared[key].push(candleIndicators[i][key][resultKey])
          }
        }
      } catch (error) {
        console.log('waveIndicatorsCorrelations error', error)
      }

    }

    // find correlations
    const correlations = {
      solo: {},
      pairs: {},
      triplets: {},
      soloRanks: {},
      pairsRanks: {},
      tripletsRanks: {},
    }
    for (const waveKey of waveKeys) {
      correlations.solo[waveKey] = {}
      correlations.pairs[waveKey] = {}
      correlations.triplets[waveKey] = {}
      correlations.soloRanks[waveKey] = []
      correlations.pairsRanks[waveKey] = []
      correlations.tripletsRanks[waveKey] = []

      for (let i = 0; i < indicatorsList.length; i++) {
        const params = indicatorsList[i]
        const { key, group, resultSubKeys } = params
        try {
          if (resultSubKeys) {
            for (const subKey of resultSubKeys) {
              const fullKey = `${key}_${subKey}`
              const pearson = pearsonCorrelation(prepared[waveKey], prepared[fullKey])
              if (Math.abs(pearson) > minimalSoloCorrelation) {
                correlations.solo[waveKey][fullKey] = {
                  pearson
                }
                correlations.soloRanks[waveKey].push({
                  key: fullKey,
                  pearson,
                  indicators: [group],
                })
              }
            }
          } else {
            const pearson = pearsonCorrelation(prepared[waveKey], prepared[key])
            if (Math.abs(pearson) > minimalSoloCorrelation) {
              correlations.solo[waveKey][key] = {
                pearson,
              }
              correlations.soloRanks[waveKey].push({
                key,
                pearson,
                indicators: [group],
              })
            }
          }
          correlations.soloRanks[waveKey].sort((a, b) => Math.abs(b.pearson) - Math.abs(a.pearson))
        } catch (error) {

        }


        // Indicator pairs correlations
        for (let j = i + 1; j < indicatorsList.length; j++) {
          const params2 = indicatorsList[j]
          const { key: key2, group: group2, resultSubKeys: rsk2 } = params2

          if (group === group2) continue

          const comboKey = `${key}_${key2}`

          // console.log('handle pair', key, key2)
          try {
            if (resultSubKeys) {
              if (rsk2) {
                for (const subKey of resultSubKeys) {
                  for (const subKey2 of rsk2) {
                    const fullKey1 = `${key}_${subKey}`
                    const fullKey2 = `${key2}_${subKey2}`
                    const multiplied = prepared[fullKey1].map((value, index) => value * prepared[fullKey2][index])

                    const pearson = pearsonCorrelation(prepared[waveKey], multiplied)
                    if (Math.abs(pearson) > minimalPairsCorrelation) {
                      correlations.pairs[waveKey][comboKey] = {
                        pearson,
                      }
                      correlations.pairsRanks[waveKey].push({
                        key: comboKey,
                        pearson,
                        indicators: [group, group2],
                      })
                    }
                  }

                }
              } else {
                for (const subKey of resultSubKeys) {
                  const fullKey1 = `${key}_${subKey}`
                  const multiplied = prepared[fullKey1].map((value, index) => value * prepared[key2][index])

                  const pearson = pearsonCorrelation(prepared[waveKey], multiplied)
                  if (Math.abs(pearson) > minimalPairsCorrelation) {
                    correlations.pairs[waveKey][comboKey] = {
                      pearson,
                    }
                    correlations.pairsRanks[waveKey].push({
                      key: comboKey,
                      pearson,
                      indicators: [group, group2],
                    })
                  }
                }
              }
            } else {
              if (rsk2) {
                for (const subKey of rsk2) {
                  const fullKey2 = `${key2}_${subKey}`
                  const multiplied = prepared[key].map((value, index) => value * prepared[fullKey2][index])

                  const pearson = pearsonCorrelation(prepared[waveKey], multiplied)
                  if (Math.abs(pearson) > minimalPairsCorrelation) {
                    correlations.pairs[waveKey][comboKey] = {
                      pearson,
                    }
                    correlations.pairsRanks[waveKey].push({
                      key: comboKey,
                      pearson,
                      indicators: [group, group2],
                    })
                  }
                }

              } else {
                const multiplied = prepared[key].map((value, index) => value * prepared[key2][index])
                const pearson = pearsonCorrelation(prepared[waveKey], multiplied)
                
                if (Math.abs(pearson) > minimalPairsCorrelation) {
                  correlations.pairs[waveKey][comboKey] = {
                    pearson,
                  }
                  correlations.pairsRanks[waveKey].push({
                    key: comboKey,
                    pearson,
                    indicators: [group, group2],
                  })
                }
              }
            }

            correlations.pairsRanks[waveKey].sort((a, b) => Math.abs(b.pearson) - Math.abs(a.pearson))

            // Indicators triplets correlations
            // for (let k = j + 1; k < indicatorsList.length; k++) {
            //   const params3 = indicatorsList[k]
            //   const { key: key3, group: group3, resultSubKeys: rsk3 } = params3

            //   if (group === group3 || group2 === group3) continue

            //   const comboKey2 = `${comboKey}_${key3}`
            //   try {
            //     if (resultSubKeys) {
            //       if (rsk3) {

            //       } else {

            //       }
            //     } else {
            //       if (rsk3) {
            //         // for (const subKey of rsk3) {
            //         //   const fullKey = `${key3}_${subKey}`
            //         //   const multiplied = prepared[key].map((value, index) => value * prepared[fullKey][index])
            //         //   const pearson = pearsonCorrelation(prepared[waveKey], multiplied)
            //         //   if (Math.abs(pearson) > minimalPairsCorrelation) {
            //         //     correlations.triplets[waveKey][comboKey2] = {
            //         //       pearson,
            //         //     }
            //         //     correlations.tripletsRanks[waveKey].push({
            //         //       key: comboKey2,
            //         //       pearson,
            //         //       indicators: [group, group2, group3],
            //         //     })
            //         //   }
            //         // }
            //       } else {
            //         const multiplied = prepared[key].map((value, index) => value * prepared[key3][index])
            //         const pearson = pearsonCorrelation(prepared[waveKey], multiplied)
            //         if (Math.abs(pearson) > minimalTripletsCorrelation) {
            //           correlations.triplets[waveKey][comboKey2] = {
            //             pearson,
            //           }
            //           correlations.tripletsRanks[waveKey].push({
            //             key: comboKey2,
            //             pearson,
            //             indicators: [group, group2, group3],
            //           })
            //         }
            //       }
            //     }
            //   } catch (error) {

            //   }
            // }
            // correlations.tripletsRanks[waveKey].sort((a, b) => Math.abs(b.pearson) - Math.abs(a.pearson))

          } catch (error) {

          }
        }




      }
    }

    console.log('waveIndicatorsCorrelations start', candles, intervalMinutes, waveBacktest, indicators, candleIndicators)
    console.log('waveIndicatorsCorrelations prepared', prepared)
    console.log('waveIndicatorsCorrelations correlations', correlations)
  } catch (error) {
    console.log('waveIndicatorsCorrelations error', error)
  }
}

function calculateIndicators(indicators, candles) {
  for (const candle of candles) {
    // const { o, h, l, c, v } = candle
    // const avg4 = (o + h + l + c) / 4
    // const indicatorParams = {
    //   high: h,
    //   low: l,
    //   close: c,
    //   open: o,
    //   volume: v,
    //   avg4,
    // }

    const { time, open, high, low, close, volume } = candle
    const avg4 = (open + high + low + close) / 4
    const hlc3 = (high + low + close) / 3

    const indicatorParams = {
      high,
      low,
      close,
      open,
      volume,
      avg4,
      hlc3,
    }



    const combined = {
      candle,
    }

    for (const params of indicatorsList) {
      try {
        const { key, indicator, period, argsValue, argsMoment } = params

        const paramsValue = argsValue.map(arg => indicatorParams[arg])
        indicators[key].value = indicators[key]._.nextValue(...paramsValue)

        const paramsMoment = argsMoment.map(arg => indicatorParams[arg])
        indicators[key].moment = indicators[key]._.momentValue(...paramsMoment)

        combined[key] = {
          value: indicators[key].value,
          moment: indicators[key].moment,
        }
      } catch (error) {
        console.log('calculateIndicators error', params, error)
      }
    }

    return indicators
  }
}

function movingWindowCorrelation(x, y, windowSize) {
  if (x.length !== y.length) {
    throw new Error("Длины массивов должны совпадать");
  }

  const correlations = [];
  for (let i = 0; i <= x.length - windowSize; i++) {
    const subX = x.slice(i, i + windowSize);
    const subY = y.slice(i, i + windowSize);
    correlations.push(pearsonCorrelation(subX, subY));
  }
  return correlations;
}

function mean(arr) {
  return arr.reduce((sum, value) => sum + value, 0) / arr.length;
}

// Функция для расчёта корреляции Пирсона
function pearsonCorrelation(x, y) {
  if (x.length !== y.length) {
    throw new Error("Длины массивов должны совпадать");
  }

  const n = x.length;
  const meanX = mean(x);
  const meanY = mean(y);

  const numerator = x.reduce((sum, xi, i) => sum + (xi - meanX) * (y[i] - meanY), 0);
  const denominator = Math.sqrt(
    x.reduce((sum, xi) => sum + Math.pow(xi - meanX, 2), 0) *
    y.reduce((sum, yi) => sum + Math.pow(yi - meanY, 2), 0)
  );

  return denominator === 0 ? 0 : numerator / denominator;
}
function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
}

function initIndicators(indicators, buffer) {
  for (const params of indicatorsList) {
    try {
      const { key, indicator, indicatorOpts, argsValue, argsMoment } = params
      if (!indicators[key]) {
        const instance = new indicator(...indicatorOpts)
        indicators[key] = indicators[key] || {
          _: instance,
          value: null,
          moment: null,
        }

        // const candleBuffer = buffer.slice(0, (period + 1))
        // for (const candle of candleBuffer) {
        //   const { o, h, l, c, v } = candle
        //   const avg4 = (o + h + l + c) / 4

        //   const indicatorParams = {
        //     high: h,
        //     low: l,
        //     close: c,
        //     open: o,
        //     volume: v,
        //     avg4,
        //   }

        //   const paramsValue = argsValue.map(arg => indicatorParams[arg])
        //   indicators[key].value = indicators[key]._.nextValue(...paramsValue)

        //   const paramsMoment = argsMoment.map(arg => indicatorParams[arg])
        //   indicators[key].moment = indicators[key]._.momentValue(...paramsMoment)
        // }
      }
    } catch (error) {
      console.log('initIndicators error', params, error)
    }
  }

  indicators.inititated = true
}



function checkEnter(candle, props, state, indicators) {
  const {
    closestHighs, closestLows,
    closestHighsPrices, closestLowsPrices,
    bounceEnterLong, bounceEnterShort,
    bounceSLPercentLong, bounceSLPercentShort,
    clusterTargetsUp, clusterTargetsDown,
    waveTargetDeltaUp, waveTargetDeltaDown,
    waveTargetPercent,
    pullbackRatio,
    currentCategory,
  } = props


  const enableLong = true
  const enableShort = true
  const filtersDefaultState = false

  const drawPoints = {
    bounce: {
      long: [],
      short: [],
    },
  }

  const enterShort = bounceEnterShort?.optimal?.limit
  const slPercentShort = bounceSLPercentShort?.optimal?.limit
  const hasAllParamsShort = enterShort && slPercentShort && waveTargetDeltaUp

  state.enteredShort = state.enteredShort || []


  let shortFilterAllow = filtersDefaultState
  const shortFilter = true
    && indicators["Stochastic_42_144"]?.value?.k > 80
    // && indicators["RSI_avg4_42"]?.value < 40
  
  if (shortFilter) {
    shortFilterAllow = true
  }


  const shortPositionCheck = !state.inShort || (state.inShort && state.enteredShort.length)
  if (enableShort && shortFilterAllow && shortPositionCheck && hasAllParamsShort && closestHighsPrices.length && closestLowsPrices.length) {
    // const level = closestHighs[closestHighs.length - 1]
    // const realLevelPrice = level.high
    const level = closestHighsPrices[closestHighsPrices.length - 1]
    // const enter = realLevelPrice - realLevelPrice * enterShort


    const levelPrice = level

    const entryPoints = getBounceEnters({
      direction: 'short',
      percentToLvl: enterShort,
      percentToStop: slPercentShort,
      lvlPrice: levelPrice,
    })

    state.enteredShort = state.inShort ? state.enteredShort || [] : []
    state.entryPointsShort = state.inShort ? state.entryPointsShort || [] : entryPoints || []

    // console.log('enter long check', candle.high > enter, candle.high, enter, level, inLong, enterLong, closestHighs.length)
    for (let i = 0; i < state.entryPointsShort.length; i++) {
      const { price, weight } = state.entryPointsShort[i]

      if (candle.low <= price && candle.high >= price) {
        if (!state.inShort) {
          state.shortEntersNumber = state.shortEntersNumber ? state.shortEntersNumber + 1 : 1
        }
        state.inShort = true
        state.shortLvlPrice = levelPrice

        state.shortEnterPrice = price

        const slPercentShort = bounceSLPercentShort?.optimal?.limit
        const sl = levelPrice + levelPrice * slPercentShort
        state.slShort = sl

        state.tpTargetShort = levelPrice - levelPrice * waveTargetPercent
        state.tpShort = levelPrice - waveTargetDeltaUp
        state.tpsShort = clusterTargetsDown.map(target => levelPrice - target)

        state.positionSize = state.balance * positionPercent * weight
        const fee = state.positionSize * limitFee

        const deltaBalance = -state.positionSize
        const fullDelta = deltaBalance - fee + fee * cashbackRate

        state.balance += fullDelta
        state.balanceShort += fullDelta

        state.feeTotal += fee
        state.feeShort += fee

        try {
          // if (!state?.enteredShort?.length) {
          //   console.log('enter short invalid enteredShort')
          // }
          state.enteredShort.push(state.entryPointsShort[i])
          drawPoints.bounce.short.push({
            time: candle.time,
            value: price,
            isFirstPoint: !state.inShort,
          })
          state.entryPointsShort.splice(i, 1)
        } catch (error) {
          console.log('enter short invalid enteredShort', state.enteredShort)
          break
        }
        // if (!state?.enteredShort?.length) {
        //   console.log('enter short invalid enteredShort')
        // }
        // state.enteredShort.push(state.entryPointsShort[i])
        // state.entryPointsShort.splice(i, 1)
        const stateClone = JSON.parse(JSON.stringify(state))
        console.log('enter short', price, stateClone)
      }
    }
  }

  const enterLong = bounceEnterLong?.optimal?.limit
  const slPercentLong = bounceSLPercentLong?.optimal?.limit
  const hasAllParamsLong = enterLong && slPercentLong && waveTargetDeltaDown

  state.enteredLong = state.enteredLong || []

  let longFilterAllow = filtersDefaultState
  const longFilter = true
    && indicators["Stochastic_42_144"]?.value?.k < 20
    // && indicators["RSI_avg4_42"]?.value > 60

  if (longFilter) {
    longFilterAllow = true
  }

  // console.log('enter long check1', state.inLong, state.entryPointsLong, state.enteredLong, )

  const longPositionCheck = !state.inLong || (state.inLong && state.enteredLong.length)
  if (enableLong && longFilterAllow && longPositionCheck && hasAllParamsLong && closestLowsPrices.length && closestHighsPrices.length) {
    const level = closestLowsPrices[closestLowsPrices.length - 1]
    // const enter = level.low - level.low * enterLong
    const levelPrice = level

    const entryPoints = getBounceEnters({
      direction: 'long',
      percentToLvl: enterLong,
      percentToStop: slPercentLong,
      lvlPrice: levelPrice,
    })

    state.enteredLong = state.inLong ? state.enteredLong || [] : []
    state.entryPointsLong = state.inLong ? state.entryPointsLong || [] : entryPoints || []

    // console.log('enter long check2', state.inLong, state.entryPointsLong, state.enteredLong, entryPoints)
    // console.log('enter long check', candle.high > enter, candle.high, enter, level, inLong, enterLong, closestHighs.length)
    for (let i = 0; i < state.entryPointsLong.length; i++) {
      const { price, weight } = state.entryPointsLong[i]

      if (candle.low <= price && candle.high >= price) {
        if (!state.inLong) {
          state.longEntersNumber = state.longEntersNumber ? state.longEntersNumber + 1 : 1
        }
        state.inLong = true
        state.longLvlPrice = levelPrice

        state.longEnterPrice = price

        const slPercentLong = bounceSLPercentLong?.optimal?.limit
        const sl = levelPrice - levelPrice * slPercentLong
        state.slLong = sl

        state.tpTargetLong = levelPrice + levelPrice * waveTargetPercent
        state.tpLong = levelPrice + waveTargetDeltaDown
        state.tpsLong = clusterTargetsUp.map(target => levelPrice + target)

        state.positionSize = state.balance * positionPercent * weight
        const fee = state.positionSize * limitFee

        const deltaBalance = -state.positionSize
        const fullDelta = deltaBalance - fee + fee * cashbackRate

        state.balance += fullDelta
        state.balanceLong += fullDelta

        state.feeTotal += fee
        state.feeLong += fee

        try {
          // if (!state?.enteredLong?.length) {
          //   console.log('enter long invalid enteredLong')
          // }
          state.enteredLong.push(state.entryPointsLong[i])
          drawPoints.bounce.long.push({
            time: candle.time,
            value: price,
            isFirstPoint: !state.inLong,
          })
          state.entryPointsLong.splice(i, 1)
        } catch (error) {
          console.log('enter long invalid enteredLong', state.enteredLong, error)
          break
        }
        // if (!state?.enteredLong?.length) {
        //   console.log('enter long invalid enteredLong')
        // }
        // state.enteredLong.push(state.entryPointsLong[i])
        // state.entryPointsLong.splice(i, 1)
        const stateClone = JSON.parse(JSON.stringify(state))
        console.log('enter long', fullDelta, price, stateClone, candle)
      }
    }
  }

  return drawPoints

}

function getBounceEnters(opts) {
  const {
    direction,
    percentToLvl,
    percentToStop,
    lvlPrice,
  } = opts


  let bouncePreEnterPoints = bouncePreLvlEnterPoints
  let bouncePostEnterPoints = bouncePostLvlEnterPoints

  const totalPoints = bouncePreEnterPoints + bouncePostEnterPoints

  const preShift = Math.min(percentToLvl, percentToStop)
  const bouncePreStepSize = preShift / bouncePreEnterPoints
  const bouncePostStepSize = percentToStop / (bouncePostEnterPoints + 1)

  // const bouncePriceWeights = getPriceWeights(bouncePreEnterPoints + bouncePostEnterPoints)

  const bounceEnters = []
  let bounceStartPrice = direction === 'long'
    ? lvlPrice - lvlPrice * bouncePreStepSize
    : lvlPrice + lvlPrice * bouncePreStepSize

  for (let i = 0; i < bouncePreEnterPoints; i++) {
    const price = direction === 'long'
      ? bounceStartPrice - lvlPrice * bouncePreStepSize * i
      : bounceStartPrice + lvlPrice * bouncePreStepSize * i

    const priceFixed = price // fixPrice(price, ss.symbolInfo, bounceRoundType)
    bounceEnters.push({
      price: parseFloat(priceFixed),
      weight: 1 / totalPoints // bouncePriceWeights[bouncePreEnterPoints - i - 1]
    })
  }
  bounceEnters.reverse()

  for (let i = 1; i <= bouncePostEnterPoints; i++) {
    const price = direction === 'long'
      ? bounceStartPrice + lvlPrice * bouncePostStepSize * i
      : bounceStartPrice - lvlPrice * bouncePostStepSize * i

    const priceFixed = price // fixPrice(price, ss.symbolInfo, bounceRoundType)
    bounceEnters.push({
      price: parseFloat(priceFixed),
      weight: 1 / totalPoints // bouncePriceWeights[bouncePreEnterPoints + i - 1]
    })
  }

  if (direction !== 'long') {
    bounceEnters.reverse()
  }

  return bounceEnters
}

function checkExitShort(candle, props, state, indicators) {
  const {
    closestHighs, closestLows,
    bounceEnterLong, bounceEnterShort,
    bounceSLPercentLong, bounceSLPercentShort,
    clusterTargetsUp, clusterTargetsDown,
    waveTargetDeltaUp, waveTargetDeltaDown,
    waveTargetPercent,
    pullbackRatio,
    currentCategory,
  } = props

  let { inLong, inShort, shortEnterPrice, slShort, tpsShort } = state

  // const takePercent = 0.2

  const drawPoints = []

  if (state.inShort) {
    const avgEntrySumm = state.enteredShort.reduce((acc, entry) => acc + entry.price * entry.weight, 0)
    const totalWeight = state.enteredShort.reduce((acc, entry) => acc + entry.weight, 0)
    const avgEntryPrice = avgEntrySumm / totalWeight

    if (slShort && state.inShort) {
      // console.log('exit long check', candle.high > enter, candle.high, enter, level, inLong, enterLong, closestHighs.length)
      if (candle.high >= slShort) {
        const deltaPercent = (slShort - avgEntryPrice) / avgEntryPrice
        const fee = state.positionSize * marketFee
        state.balance += state.positionSize * (1 + deltaPercent)
        state.balance -= fee
        state.balance += fee * cashbackRate

        state.balanceShort += state.positionSize * (1 + deltaPercent)
        state.balanceShort -= fee
        state.balanceShort += fee * cashbackRate

        state.inShort = false
        state.shortExitNumber = state.shortExitNumber ? state.shortExitNumber + 1 : 1
        state.enteredShort = []
        state.entryPointsShort = []

        console.log('exit short SL', slShort, avgEntryPrice, state.shortLvlPrice, state.balance)
        drawPoints.push({
          time: candle.time,
          value: slShort,
          closed: true,
          type: 'SL',
        })
        return null
      }
    }

    if (tpsShort && state.inShort) {
      // console.log('exit long check', candle.high > enter, candle.high, enter, level, inLong, enterLong, closestHighs.length)
      for (let i = 0; i < tpsShort.length; i++) {
        if (candle.low < tpsShort[i]) {
          const priceTP = tpsShort.shift()
          const price = Math.min(priceTP, candle.low) // TODO: remove
          const deltaPercent = (avgEntryPrice - price) / avgEntryPrice
          const fee = state.positionSize * takePercent * limitFee

          state.balance += state.positionSize * takePercent * (1 + deltaPercent)
          state.balance -= fee
          state.balance += fee * cashbackRate

          state.balanceShort += state.positionSize * takePercent * (1 + deltaPercent)
          state.balanceShort -= fee
          state.balanceShort += fee * cashbackRate

          state.positionSize *= 1 - takePercent

          const deltaLevel = state.shortLvlPrice - price
          state.slShort -= deltaLevel

          const point = {
            time: candle.time,
            value: price,
            closed: false,
            type: 'TP',
          }

          if (!tpsShort.length) {
            point.closed = true
            state.inShort = false
            state.shortExitNumber = state.shortExitNumber ? state.shortExitNumber + 1 : 1
            state.enteredShort = []
            state.entryPointsShort = []
          }

          drawPoints.push(point)
          console.log('exit short TP', price, avgEntryPrice, state.shortLvlPrice, state.inShort, tpsShort.length, state.balance)
        }
      }
    }

  }

  return drawPoints
}

function checkExitLong(candle, props, state, indicators) {
  const {
    closestHighs, closestLows,
    bounceEnterLong, bounceEnterShort,
    bounceSLPercentLong, bounceSLPercentShort,
    clusterTargetsUp, clusterTargetsDown,
    waveTargetDeltaUp, waveTargetDeltaDown,
    waveTargetPercent,
    pullbackRatio,
    currentCategory,
  } = props

  let { inLong, inShort, longEnterPrice, shortEnterPrice, slLong, tpsLong, slShort, tpsShort } = state

  // const takePercent = 0.2

  const drawPoints = []

  if (state.inLong) {
    const avgEntrySumm = state.enteredLong.reduce((acc, entry) => acc + entry.price * entry.weight, 0)
    const totalWeight = state.enteredLong.reduce((acc, entry) => acc + entry.weight, 0)
    const avgEntryPrice = avgEntrySumm / totalWeight

    const pnl = (candle.high - avgEntryPrice) * state.positionSize
    state.pnl = pnl

    if (slLong && state.inLong) {
      if (candle.low <= slLong) {
        // console.log('exit long check', candle.high > enter, candle.high, enter, level, inLong, enterLong, closestHighs.length)
        const deltaPercent = (slLong - avgEntryPrice) / avgEntryPrice
        const fee = state.positionSize * marketFee

        const deltaBalance = state.positionSize * (1 + deltaPercent)
        const fullDelta = deltaBalance - fee + fee * cashbackRate

        state.balance += fullDelta
        state.balanceLong += fullDelta

        state.inLong = false
        state.longExitNumber = state.longExitNumber ? state.longExitNumber + 1 : 1
        state.enteredLong = []
        state.entryPointsLong = []

        console.log('exit long SL', fullDelta, slLong, avgEntryPrice, state.longLvlPrice, state.balance, candle)
        drawPoints.push({
          time: candle.time,
          value: slLong,
          closed: true,
          type: 'SL',
        })
        return null
      }
    }

    if (tpsLong && state.inLong) {
      // console.log('exit long check', candle.high > enter, candle.high, enter, level, inLong, enterLong, closestHighs.length)
      for (let i = 0; i < tpsLong.length; i++) {
        if (candle.high >= tpsLong[i]) {
          const priceTP = tpsLong.shift()
          const price = Math.max(priceTP, candle.high) // TODO: remove
          const deltaPercent = (price - avgEntryPrice) / avgEntryPrice
          const fee = state.positionSize * takePercent * limitFee

          const deltaBalance = state.positionSize * takePercent * (1 + deltaPercent)
          const fullDelta = deltaBalance - fee + fee * cashbackRate

          state.balance += fullDelta
          state.balanceLong += fullDelta

          state.positionSize *= 1 - takePercent

          const deltaLevel = state.shortLvlPrice - price
          state.slLong -= deltaLevel

          const point = {
            time: candle.time,
            value: price,
            closed: false,
            type: 'TP',
          }

          if (!tpsLong.length) {
            point.closed = true
            state.inLong = false
            state.longExitNumber = state.longExitNumber ? state.longExitNumber + 1 : 1
            state.enteredLong = []
            state.entryPointsLong = []
          }

          drawPoints.push(point)
          console.log('exit long TP', fullDelta, price, avgEntryPrice, state.longLvlPrice, state.inLong, tpsLong.length, state.balance, candle)
        }
      }
    }

  }
  else {
    state.pnl = 0
  }

  return drawPoints
}



function makeSimpleTests(waves) {
  const percentiles = [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00];
  const methods = [
    // // Чистые методы
    // { methods: ['mean'], weights: [1] },
    // { methods: ['weightedMean'], weights: [1] },
    // { methods: ['overallMean'], weights: [1] },
    { methods: ['combinedWeightedMean'], weights: [1] },
    // // 
    // { methods: ['median'], weights: [1] },
    // { methods: ['weightedMedian'], weights: [1] },
    // { methods: ['overallMedian'], weights: [1] },
    // { methods: ['combinedWeightedMedian'], weights: [1] },

    // // Комбинации по два метода
    // { methods: ['mean', 'weightedMean'], weights: [0.5, 0.5] },
    // { methods: ['mean', 'overallMean'], weights: [0.5, 0.5] },
    // { methods: ['mean', 'combinedWeightedMean'], weights: [0.5, 0.5] },
    // // 
    // { methods: ['median', 'weightedMedian'], weights: [0.5, 0.5] },
    // { methods: ['median', 'overallMedian'], weights: [0.5, 0.5] },
    // { methods: ['median', 'combinedWeightedMedian'], weights: [0.5, 0.5] },
    // // // 
    // { methods: ['mean', 'median'], weights: [0.5, 0.5] }
  ];

  methods.forEach(method => {
    try {
      const results = checkSimplePrediction(waves, method, percentiles);
      const score = calculateMethodScore(results, percentiles);
      console.log(`Score for ${method.methods}: ${score.toFixed(2)}`, results);
    } catch (error) {
      console.error(`Error for ${method.methods}: ${error.message}`);
    }
  });
  return methods.length
}


function calculateMethodScore(results, percentiles, weightAccuracy = 0.1, weightDeviation = 0.9) {
  const accuracyAverage = (results.direction.accuracy + results.type.accuracy + results.duration.accuracy + results.deltaPercent.accuracy) / 4;

  let totalDeviationScore = 0;
  let totalWeight = 0;

  // Применяем взвешивание к результатам для каждого персентиля
  percentiles.forEach((percentile, index) => {
    const weight = 1 / (index + 1);  // Например, даем больший вес меньшим персентилям
    totalWeight += weight;
    // totalDeviationScore += results.duration.deviations[percentile] * weight;
    // totalDeviationScore += results.delta.deviations[percentile] * weight;
    totalDeviationScore += results.deltaPercent.deviations[percentile] * weight;
  });

  const deviationScore = totalDeviationScore / totalWeight;  // Нормализуем взвешенный счет

  return (weightAccuracy * accuracyAverage) + (weightDeviation * deviationScore);
}






function extractWaveStatistics(waves, isSequence = false) {
  if (isSequence) {
    const stats = {
      wavesNumber: waves.length,
      durations: waves.map(wave => (wave.end - wave.start) / 60), // Продолжительность в минутах
      deltas: waves.map(wave => wave.delta),
      deltaPercents: waves.map(wave => wave.deltaPercent),
      highsDelta: waves.slice(1).map((wave, i) => wave.high - waves[i].high),
      highsDeltaPercent: waves.slice(1).map((wave, i) => (wave.high - waves[i].high) / waves[i].high),
      lowsDelta: waves.slice(1).map((wave, i) => wave.low - waves[i].low),
      lowsDeltaPercent: waves.slice(1).map((wave, i) => (wave.low - waves[i].low) / waves[i].low),
    }

    return {
      wavesNumber: stats.wavesNumber,
      stats,
      delta: calculateStatistics(stats.deltas),
      deltaPercent: calculateStatistics(stats.deltaPercents),
      duration: calculateStatistics(stats.durations),
      highsDelta: calculateStatistics(stats.highsDelta),
      highsDeltaPercent: calculateStatistics(stats.highsDeltaPercent),
      lowsDelta: calculateStatistics(stats.lowsDelta),
      lowsDeltaPercent: calculateStatistics(stats.lowsDeltaPercent)
    };
  }

  const stats = {
    wavesNumber: waves.length,
    durations: waves.map(wave => (wave.end - wave.start) / 60), // Продолжительность в минутах
    deltas: waves.map(wave => wave.delta),
    deltaPercents: waves.map(wave => wave.deltaPercent),
  };


  return {
    wavesNumber: stats.wavesNumber,
    stats,
    delta: calculateStatistics(stats.deltas),
    deltaPercent: calculateStatistics(stats.deltaPercents),
    duration: calculateStatistics(stats.durations),
  };
}


function processWaves(waves, level = 0, previousWave = null) {
  let stats = {
    data: [],
    upWaves: [],
    downWaves: [],
    innerWaves: [],
    corrections: []
  };

  waves.forEach((wave, index) => {
    const currentStats = { ...wave };

    if (wave.segments && wave.segments.length > 0) {
      const result = processWaves(wave.segments, level + 1, wave);
      currentStats.segmentsStats = result.statistics
      currentStats.segments = result.data
      stats.innerWaves.push(...result.data);
    }

    if (wave.corrections && wave.corrections.length > 0) {
      const result = processWaves(wave.corrections, level + 1, wave);
      currentStats.wavesStats = result.statistics
      currentStats.corrections = result.data;
      stats.corrections.push(...result.data);
    }

    stats.data.push(currentStats);
    if (wave.direction === 'up') {
      stats.upWaves.push(currentStats);
    } else {
      stats.downWaves.push(currentStats);
    }
  });

  const overallStats = extractWaveStatistics(waves, true);
  // const upStats = extractWaveStatistics(stats.upWaves);
  // const downStats = extractWaveStatistics(stats.downWaves);
  const innerStats = extractWaveStatistics(stats.innerWaves.map(wave => wave));
  const correctionStats = extractWaveStatistics(stats.corrections.map(wave => wave));

  return {
    level: level,
    statistics: {
      overall: overallStats,
      // upWaves: upStats,
      // downWaves: downStats,
      innerWaves: innerStats,
      corrections: correctionStats
    },
    data: stats.data,
  };
}

function collectWaveStatistics(waves) {
  const result = processWaves(waves);
  return result;
}


function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);

  if (absNum >= 1000) {
    let scale = 1000;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum /= 1000;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (Math.round(scaledNum) + suffix);
    } else if (scaledNum >= 10) {
      return (scaledNum.toFixed(1) + suffix);
    } else {
      return (scaledNum.toFixed(2) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {
    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return Math.round(num).toString();
    } else if (num >= 10) {
      return num.toFixed(1);
    } else {
      return num.toFixed(2);
    }
  }
}

function KAMA(candles, src = 'close', periodEfficiency, periodFast, periodSlow) {
  let kama = [];
  let er, sc;

  for (let i = periodEfficiency; i < candles.length; i++) {
    let change = Math.abs(candles[i][src] - candles[i - periodEfficiency][src]);
    let volatility = 0;

    for (let j = 0; j < periodEfficiency; j++) {
      volatility += Math.abs(candles[i - j][src] - candles[i - j - 1][src]);
    }

    er = change / volatility;
    sc = Math.pow(er * (2 / (periodFast + 1) - 2 / (periodSlow + 1)) + 2 / (periodSlow + 1), 2);

    if (kama.length === 0) {
      //kama.push(candles[i][src]);
      const value = candles[i][src]
      kama.push({ time: candles[i].time, value });
    } else {
      let prevKAMA = kama[kama.length - 1];
      //kama.push(prevKAMA + sc * (candles[i][src] - prevKAMA));
      const prevValue = prevKAMA.value || 0
      const value = prevValue + sc * (candles[i][src] - prevValue)
      kama.push({ time: candles[i].time, value });
    }
  }

  return kama;
}


function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// const dims = []
// for (let i = 0; i < 1000; i++) {
//   const p = i / 10000
//   const r = diminishingUtility(p * 10, 5)
//   dims.push({ p, r })
// }
// console.log('dims', dims);