import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { Resizable } from 're-resizable';
import { Link, useLocation } from "react-router-dom";
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  replayState,
} from '#state/data';

import { SymbolSelect } from './SymbolSelect';
import { StartUnixTime } from './StartUnixTime';
import { LoadCandles } from './LoadCandles';
import { ClustersLevelsRoundFactor } from './ClustersLevelsRoundFactor';
import { ClustersLevelsShowed } from './ClustersLevelsShowed';
import { BookShowLevels } from './BookShowLevels';
import { BookResample } from './BookResample';
import { ResampleOnServer } from './ResampleOnServer';
import { ClusterCandleIntervalSelect } from './ClusterCandleIntervalSelect';

import { MainCandleInterval } from './CandleLevels/MainCandleInterval';
import { MainWindowSize } from './CandleLevels/MainWindowSize';
import { MainFilter2Duration } from './CandleLevels/MainFilter2Duration';
import { MainF1Percent } from './CandleLevels/MainF1Percent';
import { MainF2Percent } from './CandleLevels/MainF2Percent';
import { TempCandleInterval } from './CandleLevels/TempCandleInterval';
import { TempWindowSize } from './CandleLevels/TempWindowSize';
import { TempFilter2Duration } from './CandleLevels/TempFilter2Duration';
import { TempF1Percent } from './CandleLevels/TempF1Percent';
import { TempF2Percent } from './CandleLevels/TempF2Percent';

import { LevelsPercentile } from './LevelsPercentile';
import { LevelsMinimumVolume } from './LevelsMinimumVolume';
import { LevelsMinimumVolumeSpot } from './LevelsMinimumVolumeSpot';
import { UpdateRateInterval } from './UpdateRateInterval';
import { ZoomEnabled } from './ZoomEnabled';
import { BookScale } from './BookScale';

export function Controls(props) {
  const location = useLocation();
  const replay = useRecoilValue(replayState);
  const isReplay = location.pathname.includes('/replays/') && location.pathname.split('/').length >= 3;
  const locPaths = location.pathname.split('/')
  const replayId = isReplay ? locPaths.pop() : 'foo'
  const mode = isReplay ? locPaths.pop() : 'move'

  return (<>
    <Box sx={{
      mt: 1,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    }}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <SymbolSelect />
        {/* <StartUnixTime /> */}
        <ZoomEnabled />
        <UpdateRateInterval />
      </Box>

      <Box sx={{
        display: { xs: 'none', lg: 'flex' },
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <MainCandleInterval />
        <MainWindowSize />
        <MainF1Percent />
        <MainFilter2Duration />
        <MainF2Percent />
      </Box>

      <Box sx={{
        display: { xs: 'none', lg: 'flex' },
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <TempCandleInterval />
        <TempWindowSize />
        <TempF1Percent />
        <TempFilter2Duration />
        <TempF2Percent />
      </Box>

    </Box>


    <Box sx={{
      mt: 2,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    }}>
      <Box sx={{
        pl: 1,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <Tabs value={location.pathname}>
          <Tab label="NOW"
            component={Link}
            sx={{ minWidth: 20, px: 1 }}
            to="/"
            value="/" />
          <Tab label="Stats"
            component={Link}
            sx={{ minWidth: 30, px: 1 }}
            to="/stats"
            value="/stats" />
          <Tab label="State"
            component={Link}
            sx={{ minWidth: 30, px: 1 }}
            to="/state"
            value="/state" />
          <Tab label="Screen"
            component={Link}
            sx={{ minWidth: 30, px: 1 }}
            to="/screen"
            value="/screen" />
          <Tab label="Replays"
            component={Link}
            sx={{ minWidth: 30, px: 1 }}
            to="/replays"
            value="/replays" />
          <Tab label="🔬"
            disabled={!!replay}
            component={Link}
            sx={{ minWidth: 5, px: 1 }}
            to={replay ? `/replays/${mode}/${replayId}` : "/replays"}
            value={`/replays/${mode}/${replayId}`} />
          <Tab label="⚙️"
            component={Link}
            sx={{ minWidth: 5, px: 1 }}
            to={'/settings'}
            value={`/settings`} />
        </Tabs>
      </Box>

      <Box sx={{
        px: 1,
        display: { xs: 'none', lg: 'flex' },
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <LoadCandles />
        <ClusterCandleIntervalSelect />
        <ClustersLevelsRoundFactor />
        {/* <ClustersLevelsShowed /> */}
      </Box>

      <Box sx={{
        px: 1,
        display: { xs: 'none', lg: 'flex' },
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <BookScale />
        <LevelsPercentile />
        <LevelsMinimumVolume />
        <LevelsMinimumVolumeSpot />
      </Box>

      {/* <Box sx={{
        px: 1,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
        <BookShowLevels />
        <BookResample />
        <ResampleOnServer />
      </Box> */}

    </Box>
  </>)
}

export default Controls
