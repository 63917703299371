
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { kmeans } from 'ml-kmeans';
import { EMA, ATR } from '@debut/indicators';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  candlesState,
  candlesSelector,
  tempCandlesSelector,
  candlesH1Selector,
  mainCandlesSelector,
  candleUpdatesSelector,
  lastCandleUpdateState,
  lastCandleUpdateSelector,
  clustersState,
  clustersSelector,
  bookState,
  bookSelector,
  bookUpdatesState,
  bookUpdatesSelector,
  resampledUpdatesCache,
  timeMachineCandleTimeState,
} from '#state/data';
import {
  currentSymbolState,
  mainCandleIntervalState,
  mainWindowSizeState,
  mainFilter2DurationState,
  mainF1PercentState,
  mainF2PercentState,
  tempCandleIntervalState,
  tempWindowSizeState,
  tempFilter2DurationState,
  tempF1PercentState,
  tempF2PercentState,
} from '#state';

import {
  candleChartHeightState,
  candleChartWidthState,
} from '#state/gui';

import { convertCandles, intervalToMinutes } from '#src/utils'

import {
  // levels
  findKeyLevels,
  filterKeyLevels,
  filterKeyLevels2,
  filterImportantLevels,
  findSimilarLevels,
  mergeLevels,
  // waves
  getWaves,
  createWaveHierarchy,
  compressWaveCascades,
  // trend lines
  getWavesTrendLines,
} from './charts/CandleUtils';

//
// view config
//
const ATR_LENGTH = 14
const ATR_MAIN_LENGTH = 14

const fiboHL = [0, 1]
const fiboCorrectionLevels = [0.236, 0.382, 0.5, 0.618, 0.786] // 0.236, 0.786, 1.618, 2, 2.618, 4.236]
const fiboUpExtension = [1.618, -0.236, -0.382, -0.5, -0.618, -0.786, -1, -1.236, -1.618]
const fiboDownExtension = [-0.618, 1.236, 1.382, 1.5, 1.618, 1.786, 2, 2.618]

const BREAKOUT_TOLERANCE = 0.001; // 0.5% tolerance for breakouts
const REBOUND_TOLERANCE = 0.002; // 0.2% tolerance for rebounds
const FALSE_BREAKOUT_TOLERANCE = 0.003; // 0.3% tolerance for false breakouts
let comboId = 1

const initialBalance = 100

export const Backtest = props => {
  const { replay, replayData } = props;

  const currentSymbol = useRecoilValue(currentSymbolState);
  const timeMachineCandleTimeRef = useRef(null);

  const tempCandleInterval = useRecoilValue(tempCandleIntervalState);
  const tempCandlesData = useRecoilValue(tempCandlesSelector);
  const tempCandlesRef = useRef(null);
  // 
  const mainCandleInterval = useRecoilValue(mainCandleIntervalState);
  const mainCandlesData = useRecoilValue(mainCandlesSelector);
  const mainCandlesRef = useRef(null);

  const mainWindowSize = useRecoilValue(mainWindowSizeState);
  const mainFilter2Duration = useRecoilValue(mainFilter2DurationState);
  const mainF1Percent = useRecoilValue(mainF1PercentState);
  const mainF2Percent = useRecoilValue(mainF2PercentState);

  const tempWindowSize = useRecoilValue(tempWindowSizeState);
  const tempFilter2Duration = useRecoilValue(tempFilter2DurationState);
  const tempF1Percent = useRecoilValue(tempF1PercentState);
  const tempF2Percent = useRecoilValue(tempF2PercentState);

  const [resultBalance, setResultBalance] = useState(initialBalance);
  const [progress, setProgress] = useState(0);
  const [restart, setRestart] = useState(0);

  console.log('Backtest rerender')

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const updateResults = useCallback(async({ mainCandles, tempCandles }) => {
    let globalCandles = mainCandles
    let localCandles = tempCandles

    setResultBalance(initialBalance)
    setProgress(0)

    handleCandles({
      mainCandleInterval,
      mainCandles: globalCandles,
      mainWindowSize,
      mainFilter2Duration,
      mainF1Percent,
      mainF2Percent,
      tempCandleInterval,
      tempCandles: localCandles,
      tempWindowSize,
      tempFilter2Duration,
      tempF1Percent,
      tempF2Percent,
      timeMachineCandleTime: timeMachineCandleTimeRef.current,
      setResultBalance,
      setProgress,
    })
    setProgress(100)
  }, [
    mainCandleInterval,
    mainWindowSize,
    mainFilter2Duration,
    mainF1Percent,
    mainF2Percent,
    tempCandleInterval,
    tempWindowSize,
    tempFilter2Duration,
    tempF1Percent,
    tempF2Percent,
    timeMachineCandleTimeRef,
    restart
  ]);


  useEffect(() => {
    const mainCandles = JSON.parse(JSON.stringify(mainCandlesData));
    mainCandlesRef.current = mainCandles
    const tempCandles = JSON.parse(JSON.stringify(tempCandlesData));
    tempCandlesRef.current = tempCandles

    if (mainCandles && mainCandles.length
      && tempCandles && tempCandles.length
      && mainCandles[mainCandles.length - 1].time === tempCandles[0].time) {
      tempCandles.shift()
    }

    const updateIndicatorsOpts = { mainCandles, tempCandles }
    updateResults(updateIndicatorsOpts)

  }, [
    updateResults,
    currentSymbol,
    // 
    mainCandleInterval,
    mainCandlesData,
    mainWindowSize,
    mainFilter2Duration,
    mainF1Percent,
    mainF2Percent,
    // 
    tempCandleInterval,
    tempCandlesData,
    tempWindowSize,
    tempFilter2Duration,
    tempF1Percent,
    tempF2Percent,
    replay,
    replayData,
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
      Backtest{progress < 100 ? ` progress: ${progress}% equity: ` : ' result equity: '}
      <Box
        sx={{
          display: 'inline-block',
          color: resultBalance >= initialBalance ? 'green' : 'red'
        }}>
        {resultBalance.toFixed(0)}%
      </Box>
      <Button onClick={() => { setRestart((p) => p + 1) }}>Restart</Button>
    </Box>

  );
};

function handleCandles(props) {
  const {
    mainCandleInterval,
    mainCandles,
    mainWindowSize,
    mainFilter2Duration,
    mainF1Percent,
    mainF2Percent,
    tempCandleInterval,
    tempCandles,
    tempWindowSize,
    tempFilter2Duration,
    tempF1Percent,
    tempF2Percent,
    timeMachineCandleTime,
    setResultBalance,
    setProgress,
  } = props

  const mainIntervalMinutes = intervalToMinutes(mainCandleInterval)
  const mainHourInCandles = 60 / mainIntervalMinutes
  const mainWindowLength = mainHourInCandles * mainWindowSize
  const mainMinDuration = mainHourInCandles * mainFilter2Duration

  const tempIntervalMinutes = intervalToMinutes(tempCandleInterval)
  const tempHourInCandles = 60 / tempIntervalMinutes
  const tempWindowLength = tempHourInCandles * tempWindowSize
  const tempMinDuration = tempHourInCandles * tempFilter2Duration

  let filteredMainCandles = mainCandles
  let filteredTempCandles = tempCandles
  if (timeMachineCandleTime) {
    filteredMainCandles = mainCandles.filter(c => c.time <= timeMachineCandleTime)
    filteredTempCandles = tempCandles.filter(c => c.time <= timeMachineCandleTime)
  }

  const isMainCandlesValidInterval = filteredMainCandles.length >= mainWindowLength
  const isTempCandlesValidInterval = filteredTempCandles.length >= tempWindowLength
  let isCandlesValid = isMainCandlesValidInterval && isTempCandlesValidInterval
  if (mainIntervalMinutes === tempIntervalMinutes) {
    isCandlesValid = isMainCandlesValidInterval
  }

  if (isCandlesValid) {
    backtest({
      mainIntervalMinutes,
      mainCandles: filteredMainCandles,
      mainWindowLength,
      mainMinDuration,
      mainF1Percent,
      mainF2Percent,
      // temp
      tempIntervalMinutes,
      tempCandles: filteredTempCandles,
      tempWindowLength,
      tempMinDuration,
      tempF1Percent,
      tempF2Percent,
      // result
      setResultBalance,
      setProgress,
    })
  } else {
    console.log('not enough candles to calculate levels', mainCandles.length, mainWindowLength, tempCandles.length, tempWindowLength)
  }
}

function backtest(opts) {
  const {
    mainCandles,
    mainIntervalMinutes,
    mainWindowLength,
    mainMinDuration,
    mainF1Percent,
    mainF2Percent,
    tempCandles,
    tempIntervalMinutes,
    tempWindowLength,
    tempMinDuration,
    tempF1Percent,
    tempF2Percent,
    setResultBalance,
    setProgress,
  } = opts

  let mainSelectedCandles = mainCandles

  const tempHourInCandles = 60 / tempIntervalMinutes
  const tempSelectedCandles = tempCandles.slice(-tempHourInCandles * 24 * 8)

  const statDays = 21
  const testDays = 14
  const testFutureCandles = 24

  if (tempIntervalMinutes === mainIntervalMinutes) {
    mainSelectedCandles = mainCandles.concat(tempSelectedCandles)
    const startLimit = Math.ceil((Date.now() - 1000 * 60 * 60 * 24 * (statDays + testDays)) / 1000)
    const endLimit = Math.ceil((Date.now() - 1000 * 60 * 60 * 24 * testDays) / 1000)
    mainSelectedCandles = mainSelectedCandles.filter(candle => candle.time >= startLimit && candle.time <= endLimit)
  }

  const tempMaxIdx = tempSelectedCandles.length - 1
  const mainMaxIdx = mainSelectedCandles.length - 1


  const atrMain = new ATR(ATR_MAIN_LENGTH);
  const atrMainPre = new ATR(ATR_MAIN_LENGTH);
  const atrMainPreCandles = mainCandles.slice(0, ATR_MAIN_LENGTH + 1)
  let atrMainPreValue = 0
  for (let i = 0; i < atrMainPreCandles.length; i++) {
    const candle = atrMainPreCandles[i]
    atrMainPreValue = atrMainPre.nextValue(candle.open, candle.close, candle.high, candle.low)
  }
  const atrTemp = new ATR(ATR_MAIN_LENGTH);
  const atrTempnPre = new ATR(ATR_MAIN_LENGTH);
  const atrTempPreCandles = mainCandles.slice(0, ATR_MAIN_LENGTH + 1)
  let atrTempPreValue = 0
  for (let i = 0; i < atrTempPreCandles.length; i++) {
    const candle = atrTempPreCandles[i]
    atrTempPreValue = atrTempnPre.nextValue(candle.open, candle.close, candle.high, candle.low)
  }



  // let selectedCandles = mainSelectedCandles
  // const atr = atrMain
  // const intervalMinutes = mainIntervalMinutes
  // const windowLength = mainWindowLength
  // const atrPreValue = atrMainPreValue
  // const minDuration = mainMinDuration
  // const f1Percent = mainF1Percent
  // const f2Percent = mainF2Percent
  // const maxIdx = mainMaxIdx

  const selectedCandles = tempCandles
  const atr = atrTemp
  const intervalMinutes = tempIntervalMinutes
  const windowLength = tempWindowLength
  const atrPreValue = atrTempPreValue
  const minDuration = tempMinDuration
  const f1Percent = tempF1Percent
  const f2Percent = tempF2Percent
  const maxIdx = tempMaxIdx


  const stats = {
    unbeatLevels: {},
    fiboLevels: {},
    trendLines: {},
    candles: {},
    results: [],
    combos: {},
  };

  const temp = {
    breakout: {},
    falseBreakout: {},
    bounce: {},
    candles: {},
    waves: {},
    reactions: [],
    combos: {},
  }

  let prevWavesLength = 0
  let prevWaveId = null
  for (let i = ATR_MAIN_LENGTH; i < selectedCandles.length; i++) {
    const candles = selectedCandles.slice(0, i)
    //   {
    //     "time": 1714388400,
    //     "open": 0.04371,
    //     "high": 0.04427,
    //     "low": 0.04361,
    //     "close": 0.044,
    //     "volume": 152984921,
    //     "atr": 0.0006785380108221129
    // }
    const nextCandles = selectedCandles.slice(i, i + 10)

    const mainKeyLevels = findKeyLevels(candles, intervalMinutes, atr, atrPreValue)
    const mainFilteredLevels1 = filterKeyLevels(mainKeyLevels, windowLength, f1Percent)
    const mainImportantLevels = filterKeyLevels2(mainFilteredLevels1, minDuration, windowLength, f1Percent, f2Percent, maxIdx)
    // const mainUnbeatLevels = mainImportantLevels.filter(lvl => lvl.unbeat && lvl.duration > minDuration)
    //   {
    //     "level": 0.02043,
    //     "duration": 2314,
    //     "minutesInCandle": 60,
    //     "startTime": 1706018400,
    //     "idx": 758,
    //     "atr": 0.00039336028931004715,
    //     "unbeat": true,
    //     "touches": 1,
    //     "type": "support",
    //     "lowerBound": 0.020233319855344975,
    //     "upperBound": 0.020626680144655025
    // }
    const wavesDataMain = getWaves(mainFilteredLevels1.lowLevels, mainFilteredLevels1.highLevels)
    const wavesMain = wavesDataMain.waves

    // const waveHierarchyMain = createWaveHierarchy(wavesMain, 'W');
    // const cascadeStagesMain = compressWaveCascades(waveHierarchyMain)

    let isTestReady = wavesMain.length// mainUnbeatLevels.length && wavesMain.length // && cascadeStagesMain.length
    if (!isTestReady) {
      continue 
    }


    // const waveCascadesHierarchy = cascadeStagesMain[cascadeStagesMain.length - 1].hierarchy
    // if (waveCascadesHierarchy && !waveCascadesHierarchy.length) {
    //   continue
    // }

    const waveIndex = wavesMain.length - 1
    const lastWave = wavesMain[wavesMain.length - 1]
    const newWaveCandles = candles.filter(c => c.time > lastWave.end)
    const lastCandle = newWaveCandles[newWaveCandles.length - 1]
    const endTime = lastCandle.time
    const prevCandle = candles[candles.length - 2]
    const currentCandle = candles[candles.length - 1]

    const waveId = `${lastWave.highLevelIdx}:${lastWave.lowLevelIdx}`
    if (wavesMain.length === prevWavesLength && waveId !== prevWaveId) {
      // console.log('wave updated', i,  wavesMain.length, prevWaveId, waveId)
    } else {
      // console.log('new wave date', i, wavesMain.length)
    }

    prevWavesLength = wavesMain.length
    prevWaveId = waveId


    // const waveH = waveCascadesHierarchy[waveCascadesHierarchy.length - 1] || null
    // const lastSegment = (waveH?.segments && waveH?.segments[waveH?.segments?.length - 1]) || null
    // const correction = (waveH?.corrections && waveH?.corrections[waveH?.corrections?.length - 1]) || null
    // const subCorrection = (correction?.corrections && correction?.corrections[correction?.corrections?.length - 1]) || null

    // const mainFibo = getFiboLevels(waveH, 'mainFibo')
    // const segmentsFibo = getFiboLevels(lastSegment, 'segmentsFibo')
    // const correctionsFibo = getFiboLevels(correction, 'correctionsFibo')
    // const subCorrectionsFibo = getFiboLevels(subCorrection, 'subCorrectionsFibo')
    // // { level: 123, fibo: 0.618 }

    // const joinedLevels = [
    //   ...mainImportantLevels,
    //   ...mainFibo,
    //   ...segmentsFibo,
    //   ...correctionsFibo,
    //   ...subCorrectionsFibo,
    // ]

    // const trendLinesData = getWavesTrendLines(wavesMain, endTime);
    // const { high: highTL, low: lowTL } = trendLinesData
    // const highTrendLinePrice = highTL?.end?.price || null
    // const lowTrendLinePrice = lowTL?.end?.price || null
    // if (highTrendLinePrice) {
    //   const htl = { level: highTrendLinePrice, type: 'highTrendLine' }
    //   joinedLevels.push(htl)
    // }
    // if (lowTrendLinePrice) {
    //   const ltl = { level: lowTrendLinePrice, type: 'lowTrendLine' }
    //   joinedLevels.push(ltl)
    // }

    // for (const level of joinedLevels) {
    //   analyzeLevelReaction(i, waveIndex, level, stats, prevCandle, currentCandle, temp)
    // }

    // updateReactionDetails(i, waveIndex, stats, nextCandles, temp)

    // if (i > 100) break
    setProgress((i / selectedCandles.length) * 100)
  }

  console.log('backtest stats', stats, temp)
  // const statsResults = getStatistics(stats)


  // if (tempIntervalMinutes === mainIntervalMinutes) {
  //   mainSelectedCandles = mainCandles.concat(tempSelectedCandles)
  //   const startLimit = Math.ceil((Date.now() - 1000 * 60 * 60 * 24 * testDays) / 1000)
  //   const endLimit = Math.ceil((Date.now() - 1000 * 60 * 60 * 24 * 0) / 1000)
  //   mainSelectedCandles = mainSelectedCandles.filter(candle => candle.time >= startLimit && candle.time <= endLimit)
  //   //endTime = mainSelectedCandles[mainSelectedCandles.length - 1].time + mainIntervalMinutes * 60
  //   selectedCandles = mainSelectedCandles
  // } else {
  //   //endTime = tempSelectedCandles[tempSelectedCandles.length - 1].time + tempIntervalMinutes * 60
  // }
  // const testResults = {
  //   simple: []
  // }

  // for (let i = ATR_MAIN_LENGTH; i < selectedCandles.length; i++) {
  //   const candles = selectedCandles.slice(0, i)
  //   const nextCandles = selectedCandles.slice(i, i + testFutureCandles)

  //   const mainKeyLevels = findKeyLevels(candles, intervalMinutes, atr, atrPreValue)
  //   const mainFilteredLevels1 = filterKeyLevels(mainKeyLevels, windowLength, f1Percent)
  //   const mainImportantLevels = filterKeyLevels2(mainFilteredLevels1, minDuration, windowLength, f1Percent, f2Percent, maxIdx)
  //   const mainUnbeatLevels = mainImportantLevels.filter(lvl => lvl.unbeat && lvl.duration > minDuration)
  //   const wavesDataMain = getWaves(mainFilteredLevels1.lowLevels, mainFilteredLevels1.highLevels)
  //   const wavesMain = wavesDataMain.waves
  //   const waveHierarchyMain = createWaveHierarchy(wavesMain, 'W');
  //   const cascadeStagesMain = compressWaveCascades(waveHierarchyMain)

  //   let isTestReady = mainUnbeatLevels.length && wavesMain.length && cascadeStagesMain.length
  //   if (!isTestReady) {
  //     continue
  //   }

  //   const waveCascadesHierarchy = cascadeStagesMain[cascadeStagesMain.length - 1].hierarchy
  //   if (waveCascadesHierarchy && !waveCascadesHierarchy.length) {
  //     continue
  //   }

  //   const waveIndex = wavesMain.length - 1
  //   const lastWave = wavesMain[wavesMain.length - 1]
  //   const newWaveCandles = candles.filter(c => c.time > lastWave.end)
  //   const lastCandle = newWaveCandles[newWaveCandles.length - 1]
  //   const endTime = lastCandle?.time
  //   const prevCandle = candles[candles.length - 2]
  //   const currentCandle = candles[candles.length - 1]



  //   const waveH = waveCascadesHierarchy[waveCascadesHierarchy.length - 1] || null
  //   const lastSegment = (waveH?.segments && waveH?.segments[waveH?.segments?.length - 1]) || null
  //   const correction = (waveH?.corrections && waveH?.corrections[waveH?.corrections?.length - 1]) || null
  //   const subCorrection = (correction?.corrections && correction?.corrections[correction?.corrections?.length - 1]) || null

  //   const mainFibo = getFiboLevels(waveH, 'mainFibo')
  //   const segmentsFibo = getFiboLevels(lastSegment, 'segmentsFibo')
  //   const correctionsFibo = getFiboLevels(correction, 'correctionsFibo')
  //   const subCorrectionsFibo = getFiboLevels(subCorrection, 'subCorrectionsFibo')
  //   // { level: 123, fibo: 0.618 }

  //   const joinedLevels = [
  //     ...mainImportantLevels,
  //     ...mainFibo,
  //     ...segmentsFibo,
  //     ...correctionsFibo,
  //     ...subCorrectionsFibo,
  //   ]

  //   const trendLinesData = getWavesTrendLines(wavesMain, endTime);
  //   const { high: highTL, low: lowTL } = trendLinesData
  //   const highTrendLinePrice = highTL?.end?.price || null
  //   const lowTrendLinePrice = lowTL?.end?.price || null
  //   if (highTrendLinePrice) {
  //     const htl = { level: highTrendLinePrice, type: 'highTrendLine' }
  //     joinedLevels.push(htl)
  //   }
  //   if (lowTrendLinePrice) {
  //     const ltl = { level: lowTrendLinePrice, type: 'lowTrendLine' }
  //     joinedLevels.push(ltl)
  //   }

  //   let signals = []
  //   for (const level of joinedLevels) {
  //     const sig = checkEnter(level, stats, prevCandle, currentCandle, temp, statsResults)

  //     signals = signals.concat(sig)
  //   }

  //   checkExit(signals, nextCandles, testResults)
  // }
  // console.log('testResults', testResults)
  let balance = initialBalance
  // for (const trade of testResults.simple) {
  //   balance += balance * trade.result
  // }
  // console.log('balance', balance)
  setResultBalance(balance)
  setProgress(100)
}


function checkEnter(levelData, stats, prevCandle, currentCandle, temp, statsResults) {
  const { statistics, statsCategories } = statsResults
  const { level, type, fibo } = levelData;

  const positions = [];
  const prevCandleDelta = prevCandle.high - prevCandle.low;
  const bounceLowerBound = level - prevCandleDelta;
  const bounceUpperBound = level + prevCandleDelta;

  const isBreakoutUp = prevCandle.high <= level && currentCandle.high >= level;
  const isBreakoutDown = prevCandle.low >= level && currentCandle.low <= level;

  const isBounceUp = prevCandle.low >= level && prevCandle.low < bounceUpperBound && currentCandle.low >= prevCandle.low;
  const isBounceDown = prevCandle.high <= level && prevCandle.high > bounceLowerBound && currentCandle.high <= prevCandle.high;

  function calculatePosition(typeKey, dirKey, enterPrice, maxDeltaPercentUp, maxDeltaPercentDown) {
    const dup = enterPrice * (Math.abs(maxDeltaPercentUp.avg));
    const ddown = enterPrice * (Math.abs(maxDeltaPercentDown.avg));
    //const dup = enterPrice * (Math.abs(maxDeltaPercentUp.median) + Math.abs(maxDeltaPercentUp.stdDev))
    //const ddown = enterPrice * (Math.abs(maxDeltaPercentDown.median) + Math.abs(maxDeltaPercentDown.stdDev))

    const sdup = enterPrice * (Math.abs(maxDeltaPercentUp.avg))
    const sddown = enterPrice * (Math.abs(maxDeltaPercentDown.avg))
    // const duptake = enterPrice * (Math.abs(maxDeltaPercentUp.avg) + Math.abs(maxDeltaPercentUp.stdDev)) 
    // const ddowntake = enterPrice * (Math.abs(maxDeltaPercentDown.avg) + Math.abs(maxDeltaPercentDown.stdDev))
    const stopMult = 0.5;
    const stop = dirKey === 'up'
      ? enterPrice - ddown * stopMult
      : enterPrice + dup * stopMult

    const take = dirKey === 'up'
      ? enterPrice + dup
      : enterPrice - ddown;

    const trailing = dirKey === 'up'
      ? dup * stopMult
      : ddown * stopMult

    return { stop, take, trailing };
  }

  function processPositions(typeKey, dirKey, enterPrice) {
    const lTypeKey = type;
    const keys = [
      dirKey,
      typeKey,
      `${typeKey}:${dirKey}`,
      `${typeKey}:${lTypeKey}`
    ];
    if (fibo !== undefined) {
      keys.push(`${typeKey}:${dirKey}:${lTypeKey}:${fibo}`);
    }

    for (const catInfo of statsCategories.all) {
      const match = keys.find(c => c === catInfo.category);
      if (match) {
        const { maxDeltaPercentUp, maxDeltaPercentDown } = catInfo.stats; // statistics[`${typeKey}:${dirKey}`]
        const { stop, take, trailing } = calculatePosition(typeKey, dirKey, enterPrice, maxDeltaPercentUp, maxDeltaPercentDown);

        positions.push({
          type: typeKey,
          direction: dirKey,
          category: catInfo.category,
          price: enterPrice,
          stop,
          take,
          trailing,
          stats: match.stats,
        });
      }
    }
  }

  if (isBreakoutUp || isBreakoutDown) {
    const dirKey = isBreakoutUp ? 'up' : 'down';
    processPositions('breakout', dirKey, level);
  }

  if (isBounceUp || isBounceDown) {
    const dirKey = isBounceUp ? 'up' : 'down';
    processPositions('bounce', dirKey, currentCandle.close);
  }

  return positions;
}

function checkExit(signals, nextCandles, testResults) {
  if (!signals?.length) return null;

  const positions = {};
  if (signals.length > 1) {
    console.log('merge signals', signals)
    for (const signal of signals) {
      const { direction } = signal;
      if (positions[direction]) {
        // merge signals?
      } else {
        positions[direction] = signal;
      }
    }
  } else {
    positions[signals[0].direction] = signals[0];
  }

  for (const key in positions) {
    const position = positions[key];
    const { type, direction, price, stop, take, trailing, stats } = position;
    let closed = false;
    let result = null;
    let closePrice = nextCandles[nextCandles.length - 1].close;
    let delta = 0;
    let trailingStop = stop;

    for (const candle of nextCandles) {
      if (direction === 'up') {
        if (candle.low <= trailingStop) {
          closed = 'stop';
          closePrice = trailingStop;
          delta = closePrice - price;
          result = delta / price;
          break;
        }
        if (candle.high >= take) {
          closed = 'take';
          closePrice = take;
          delta = closePrice - price;
          result = delta / price;
          break;
        }
        if (trailing && candle.high > price) {
          trailingStop = Math.max(trailingStop, candle.high - trailing);
        }
      } else {
        if (candle.high >= trailingStop) {
          closed = 'stop';
          closePrice = trailingStop;
          delta = price - closePrice;
          result = delta / price;
          break;
        }
        if (candle.low <= take) {
          closed = 'take';
          closePrice = take;
          delta = price - closePrice;
          result = delta / price;
          break;
        }
        if (trailing && candle.low < price) {
          trailingStop = Math.min(trailingStop, candle.low + trailing);
        }
      }
    }

    if (!closed) {
      closed = 'end';
      closePrice = nextCandles[nextCandles.length - 1].close;
      if (direction === 'up') {
        delta = closePrice - price;
        result = delta / price;
      } else {
        delta = price - closePrice;
        result = delta / price;
      }
    }

    testResults.simple.push({
      closed,
      direction,
      price,
      closePrice,
      result: result - 0.001, // 0.00065 cashbacked fee // 0.001
    });
  }
}


function getStatistics(stats) {

  function hadleReaction(element, key, opts) {
    const { reaction, details } = element

    if (!categories[key]) {
      categories[key] = {
        direction: reaction.direction,
        type: reaction.type,
        ltype: reaction.levelData.type,
        data: []
      }
    }

    categories[key].data.push({ reaction, details })
  }

  const categories = {}
  stats.results.forEach((element) => {
    const { reaction } = element

    hadleReaction(element, `${reaction.direction}`)
    hadleReaction(element, `${reaction.type}`)
    hadleReaction(element, `${reaction.type}:${reaction.direction}`)
    hadleReaction(element, `${reaction.type}:${reaction.levelData.type}`)
    hadleReaction(element, `${reaction.direction}:${reaction.levelData.type}`)
    hadleReaction(element, `${reaction.type}:${reaction.direction}:${reaction.levelData.type}`)

    if (reaction.levelData.fibo !== undefined) {
      //hadleReaction(element, `${reaction.type}:${reaction.direction}:${reaction.levelData.fibo}`)
      hadleReaction(element, `${reaction.type}:${reaction.direction}:${reaction.levelData.type}:${reaction.levelData.fibo}`)
    } else {
      // hadleReaction(element, `${reaction.type}:${reaction.direction}:${reaction.levelData.type}`)
    }

  })
  console.log('categories', categories)

  const statistics = {}

  for (const [category, info] of Object.entries(categories)) {
    const results = info.data
    statistics[category] = {
      count: results.length,
      direction: info.direction,
      maxDeltaPercentUp: calculateStatistics(results.map(({ details }) => details.maxDeltaPercentUp)),
      maxDeltaPercentDown: calculateStatistics(results.map(({ details }) => details.maxDeltaPercentDown)),
      maxDeltaCloseUp: calculateStatistics(results.map(({ details }) => details.maxDeltaCloseUp)),
      maxDeltaCloseDown: calculateStatistics(results.map(({ details }) => details.maxDeltaCloseDown)),
      afterPercentUp: calculateStatistics(results.map(({ details }) => {
        // console.log('afterPercentUp', details.maxDeltaPercentUp, details.initDeltaPercentUp) 
        return details.maxDeltaPercentUp - details.initDeltaPercentUp
      })),
      afterPercentDown: calculateStatistics(results.map(({ details }) => {
        return details.maxDeltaPercentDown - details.initDeltaPercentDown
      })),
    }
    const cat = statistics[category]
    cat.maxDeltaRatio = Math.abs(cat.maxDeltaPercentUp.avg / cat.maxDeltaPercentDown.avg)
    cat.afterRatio = Math.abs(cat.afterPercentUp.avg / cat.afterPercentDown.avg)
    cat.closeRatio = Math.abs(cat.maxDeltaCloseUp.avg / (cat.maxDeltaCloseDown.avg || cat.maxDeltaCloseUp.avg / 1000))
  }
  console.log('statistics', statistics)

  const statsCategories = {
    all: []
  }


  for (const [category, stats] of Object.entries(statistics)) {
    if (stats.count < 2) continue
    const isUp = statistics[category].direction === 'up'

    const deltaRatio = isUp
      ? statistics[category].maxDeltaRatio
      : 1 / statistics[category].maxDeltaRatio

    const afterRatio = isUp
      ? statistics[category].afterRatio
      : 1 / statistics[category].afterRatio

    const closeRatio = isUp
      ? statistics[category].closeRatio
      : 1 / statistics[category].closeRatio

    const minDelta = 0.004

    const deltaAvgDelta = isUp
      ? Math.abs(stats.maxDeltaPercentUp.avg) - Math.abs(stats.maxDeltaPercentDown.avg)
      : Math.abs(stats.maxDeltaPercentDown.avg) - Math.abs(stats.maxDeltaPercentUp.avg)

    statsCategories.all.push({ deltaRatio, afterRatio, closeRatio, category, stats })

    if (!statsCategories.DeltaRatio)
      statsCategories.DeltaRatio = []
    if (deltaRatio > 1 && deltaAvgDelta > minDelta)
      statsCategories.DeltaRatio.push({ deltaRatio, afterRatio, closeRatio, category, stats })

    const deltaAvgAfter = isUp
      ? Math.abs(stats.afterPercentUp.avg) - Math.abs(stats.afterPercentDown.avg)
      : Math.abs(stats.afterPercentDown.avg) - Math.abs(stats.afterPercentUp.avg)

    if (!statsCategories.AfterRatio)
      statsCategories.AfterRatio = []
    if (afterRatio > 1 && deltaAvgAfter > minDelta)
      statsCategories.AfterRatio.push({ deltaRatio, afterRatio, closeRatio, category, stats })

    const deltaAvgClose = isUp
      ? Math.abs(stats.maxDeltaCloseUp.avg) - Math.abs(stats.maxDeltaCloseDown.avg)
      : Math.abs(stats.maxDeltaCloseDown.avg) - Math.abs(stats.maxDeltaCloseUp.avg)

    if (!statsCategories.CloseRatio)
      statsCategories.CloseRatio = []
    if (closeRatio > 1 && deltaAvgClose > minDelta)
      statsCategories.CloseRatio.push({ deltaRatio, afterRatio, closeRatio, category, stats })


  }

  if (statsCategories.DeltaRatio)
    statsCategories.DeltaRatio.sort((a, b) => b.deltaRatio - a.deltaRatio)
  if (statsCategories.AfterRatio)
    statsCategories.AfterRatio.sort((a, b) => b.afterRatio - a.afterRatio)
  if (statsCategories.CloseRatio)
    statsCategories.CloseRatio.sort((a, b) => b.closeRatio - a.closeRatio)

  const percentileTop = 95

  const deltaRatios = statsCategories.all.map(a => a.deltaRatio)
  const deltaRatiosTop = percentile(deltaRatios, percentileTop)
  console.log('deltaRatiosTop', deltaRatiosTop)

  const afterRatios = statsCategories.all.map(a => a.afterRatio)
  const afterRatiosTop = percentile(afterRatios, percentileTop)
  console.log('afterRatiosTop', afterRatiosTop)

  const closeRatios = statsCategories.all.map(a => a.closeRatio)
  const closeRatiosTop = percentile(closeRatios, percentileTop)
  console.log('closeRatiosTop', closeRatiosTop)

  statsCategories.all = statsCategories.all
    .sort((a, b) => b.closeRatio - a.closeRatio)
    .filter(a => a.closeRatio >= closeRatiosTop)
  //.filter(a => a.deltaRatio >= deltaRatiosTop)
  //.filter(a => a.afterRatio >= afterRatiosTop)

  console.log('statsCategories', statsCategories)

  return {
    statistics,
    statsCategories,
  }
}


function updateReactionDetails(index, waveIndex, stats, nextCandles, temp) {
  const reactions = temp.reactions
  if (!reactions?.length) return null
  if (reactions.length > 1) {
    console.log('updateReaction multiple:', index, waveIndex, reactions)
    temp.combos[comboId] = []
    for (const reaction of reactions) {
      reaction.comboId = comboId
    }
    comboId++
  }



  for (const reaction of reactions) {
    const { key, type: rType, levelData, candle: candleIndex } = reaction
    const { level, type, fibo } = levelData

    const details = temp[rType][key]

    for (const candle of nextCandles) {
      // console.log('updateReactionDetails', index, waveIndex, details, nextCandles)
      const initPrice = details.initPrice

      if (reaction.direction === 'up') {
        details.hh = Math.max(details.hh, candle.high)
        details.ll = Math.min(details.ll, candle.low)
        details.hc = Math.max(details.close, candle.close)
        details.lc = Math.min(details.close, candle.close)

        const maxDeltaUp = Math.max(details.maxDeltaUp, candle.high - initPrice)
        const deltaDown = candle.low - initPrice
        details.maxDeltaUp = maxDeltaUp
        details.maxDeltaPercentUp = maxDeltaUp / initPrice
        details.maxDeltaDown = Math.min(details.maxDeltaDown, deltaDown)
        details.maxDeltaPercentDown = details.maxDeltaDown / initPrice

        details.maxDeltaCloseUp = details.hc / details.close - 1
        details.maxDeltaCloseDown = details.lc / details.close - 1
      } else {
        details.hh = Math.max(details.hh, candle.high)
        details.ll = Math.min(details.ll, candle.low)
        details.hc = Math.max(details.close, candle.close)
        details.lc = Math.min(details.close, candle.close)

        const maxDeltaDown = Math.max(details.maxDeltaDown, initPrice - candle.low)
        const deltaUp = initPrice - candle.high
        details.maxDeltaDown = maxDeltaDown
        details.maxDeltaPercentDown = maxDeltaDown / initPrice
        details.maxDeltaUp = Math.min(details.maxDeltaUp, deltaUp)
        details.maxDeltaPercentUp = details.maxDeltaUp / initPrice

        details.maxDeltaCloseUp = details.hc / details.close - 1
        details.maxDeltaCloseDown = details.lc / details.close - 1
      }

      //console.log('updateReactionDetails', index, waveIndex, candle, details)
    }

    stats.results.push({
      reaction,
      details,
    })

    if (reaction.comboId) {
      temp.combos[reaction.comboId].push({
        reaction,
        details,
      })
    }

  }

  temp.reactions = []
}


function analyzeLevelReaction(index, waveIndex, levelData, stats, prevCandle, currentCandle, temp) {
  const { level, type, fibo } = levelData

  const prevCandleDelta = prevCandle.high - prevCandle.low

  // const bounceLowerBound = level - level * REBOUND_TOLERANCE
  // const bounceUpperBound = level + level * REBOUND_TOLERANCE
  const bounceLowerBound = level - prevCandleDelta
  const bounceUpperBound = level + prevCandleDelta

  const key = `${waveIndex}:${level}`

  const isBreakoutUp = prevCandle.high <= level && currentCandle.high > level
  const isBreakoutDown = prevCandle.low >= level && currentCandle.low < level

  const isBounceUp = prevCandle.low >= level && prevCandle.low < bounceUpperBound && currentCandle.low >= prevCandle.low
  const isBounceDown = prevCandle.high <= level && prevCandle.high > bounceLowerBound && currentCandle.high <= prevCandle.high


  if (isBreakoutUp) {
    // breakout or false breakout
    const maxDeltaUp = currentCandle.high - level
    const maxDeltaDown = currentCandle.close - level
    const maxDeltaPercentUp = maxDeltaUp / level
    const maxDeltaPercentDown = maxDeltaDown / level

    temp.breakout[key] = {
      up: isBreakoutUp,
      hh: currentCandle.high,
      ll: currentCandle.close,
      close: currentCandle.close,
      initPrice: level,
      maxDeltaUp,
      initDeltaUp: maxDeltaUp,
      maxDeltaPercentUp,
      initDeltaPercentUp: maxDeltaPercentUp,
      maxDeltaDown,
      initDeltaDown: maxDeltaDown,
      maxDeltaPercentDown,
      initDeltaPercentDown: maxDeltaPercentDown,
    }
    // console.log('breakout? up', waveIndex, index, levelData, currentCandle)
    temp.reactions.push({
      key,
      type: 'breakout',
      direction: 'up',
      level,
      lType: type,
      fibo,
      levelData,
      candle: index,
      currentCandle,
    })
  }

  if (isBreakoutDown) {
    // breakout or false breakout
    const maxDeltaUp = level - currentCandle.close
    const maxDeltaDown = level - currentCandle.low
    const maxDeltaPercentUp = maxDeltaUp / level
    const maxDeltaPercentDown = maxDeltaDown / level

    temp.breakout[key] = {
      down: isBreakoutDown,
      hh: currentCandle.close,
      ll: currentCandle.low,
      close: currentCandle.close,
      initPrice: level,
      maxDeltaUp,
      initDeltaUp: maxDeltaUp,
      maxDeltaPercentUp,
      initDeltaPercentUp: maxDeltaPercentUp,
      maxDeltaDown,
      initDeltaDown: maxDeltaDown,
      maxDeltaPercentDown,
      initDeltaPercentDown: maxDeltaPercentDown,
    }

    // console.log('breakout? down', waveIndex, index, levelData, isBreakoutUp, isBreakoutDown)
    temp.reactions.push({
      key,
      type: 'breakout',
      direction: 'down',
      level,
      lType: type,
      fibo,
      levelData,
      candle: index,
      currentCandle,
    })
  }

  if (isBounceUp) {
    // possible bounce

    const maxDeltaUp = currentCandle.close - level
    const maxDeltaDown = currentCandle.low - level
    const maxDeltaPercentUp = maxDeltaUp / level
    const maxDeltaPercentDown = maxDeltaDown / level

    temp.bounce[key] = {
      up: isBounceUp,
      hh: currentCandle.close,
      ll: currentCandle.low,
      close: currentCandle.close,
      initPrice: currentCandle.low,
      maxDeltaUp,
      initDeltaUp: maxDeltaUp,
      maxDeltaDown,
      initDeltaDown: maxDeltaDown,
      maxDeltaPercentUp,
      initDeltaPercentUp: maxDeltaPercentUp,
      maxDeltaPercentDown,
      initDeltaPercentDown: maxDeltaPercentDown,
    }

    // console.log('bounce? up ', waveIndex, index, levelData, isBounceUp, isBounceDown)
    temp.reactions.push({
      key,
      type: 'bounce',
      direction: 'up',
      level,
      lType: type,
      fibo,
      levelData,
      candle: index,
      currentCandle,
    })
  }

  if (isBounceDown) {
    // possible bounce
    const maxDeltaUp = currentCandle.high - level
    const maxDeltaDown = currentCandle.close - level
    const maxDeltaPercentUp = maxDeltaUp / level
    const maxDeltaPercentDown = maxDeltaDown / level

    temp.bounce[key] = {
      up: isBounceUp,
      hh: currentCandle.high,
      ll: currentCandle.low,
      close: currentCandle.close,
      initPrice: currentCandle.high,
      maxDeltaUp,
      initDeltaUp: maxDeltaUp,
      maxDeltaDown,
      initDeltaDown: maxDeltaDown,
      maxDeltaPercentUp,
      initDeltaPercentUp: maxDeltaPercentUp,
      maxDeltaPercentDown,
      initDeltaPercentDown: maxDeltaPercentDown,
    }

    // console.log('bounce? down', waveIndex, index, levelData, isBounceUp, isBounceDown)
    temp.reactions.push({
      key,
      type: 'bounce',
      direction: 'down',
      up: isBounceUp,
      down: isBounceDown,
      levelData,
      candle: index,
      currentCandle,
    })
  }

}


function getFiboLevels(wave, type) {
  if (!wave) {
    return []
  }

  const { direction, high, delta } = wave

  const ext = direction === 'up' ? fiboUpExtension : fiboDownExtension
  const fiboLevels = [...fiboCorrectionLevels, ...fiboHL, ...ext]

  const levels = []
  for (let j = 0; j < fiboLevels.length; j++) {
    const mult = fiboLevels[j]
    const level = { level: high - delta * mult, fibo: mult, type }
    levels.push(level)
  }

  return levels
}


function calculateStatistics(v) {
  if (!v.length) return { avg: 0, median: 0, min: 0, max: 0, stdDev: 0, slope: 0, intercept: 0 };

  const values = v.map(val => val || 0);

  const sum = values.reduce((acc, val) => acc + val, 0);
  const avg = sum / values.length;

  // Линейная регрессия
  const xBar = (values.length - 1) / 2; // Среднее значение индексов
  let num = 0;
  let den = 0;
  values.forEach((y, x) => {
    num += (x - xBar) * (y - avg); // числитель
    den += (x - xBar) * (x - xBar); // знаменатель
  });

  const slope = num / den;
  const intercept = avg - slope * xBar;

  // Выполним сортировку values после расчёта регрессии
  values.sort((a, b) => a - b);
  const median = values.length % 2 === 0 ? (values[values.length / 2 - 1] + values[values.length / 2]) / 2 : values[Math.floor(values.length / 2)];
  const min = values[0];
  const max = values[values.length - 1];
  const variance = values.reduce((acc, val) => acc + Math.pow(val - avg, 2), 0) / values.length;
  const stdDev = Math.sqrt(variance);

  return { avg, median, min, max, stdDev, slope, intercept };
}

function percentile(values, p) {
  values.sort((a, b) => a - b);
  const index = (p / 100) * (values.length - 1);

  if (Math.floor(index) === index) {
    return values[index];
  } else {
    const i = Math.floor(index);
    return values[i] + (values[i + 1] - values[i]) * (index - i);
  }
}